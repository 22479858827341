import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import MainNav from 'components/MainNav';
import { ReactComponent as LogoIcon } from 'assets/images/logo_icon.svg';

const Header = () => (
  <Navbar id="navigation" bg="dark" expand="lg" variant="dark header-nav">
    <div className="container-fluid p-0" style={{ maxWidth: '1352px' }}>
      <Navbar.Brand href="/">
        <LogoIcon />
        key<span>PAY</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <MainNav />
    </div>
  </Navbar>
);
export default Header;
