import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconComponent from 'components/IconComponent';
import './SwitcherButtons.scss';

const SwitcherButtons = ({
  buttons,
  isDisabled,
  onChange,
  active,
  variant = 'btn-light',
  activeVariant = 'btn-info',
  switcherClasses = 'btn-group-sm',
}) => {
  const [activeButton, setActiveButton] = useState(null);

  const setActive = (value) => {
    setActiveButton(value);
    onChange(value);
  };

  useEffect(() => {
    setActiveButton(active);
  }, [active]);

  return (
    <div className={classNames('btn-group btn-group-switcher', switcherClasses)}>
      {!!buttons.length &&
        buttons.map((button) => (
          <button
            key={button.value}
            type="button"
            className={classNames(
              'btn',
              activeButton === button.value ? `${activeVariant} switched` : variant,
            )}
            onClick={() => setActive(button.value)}
            aria-disabled={isDisabled}>
            {!!button.icon && <IconComponent className="mr-1" name={button.icon} />}
            <span>{button.label}</span>
          </button>
        ))}
      {!buttons.length && null}
    </div>
  );
};

SwitcherButtons.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.string,
  variant: PropTypes.string,
  activeVariant: PropTypes.string,
  switcherClasses: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ),
};

export default SwitcherButtons;
