import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import PaymentLine from './PaymentLine';

const PaymentAmount = ({ remove, replace, push, lines }) => {
  return (
    <div>
      <div className="row mb-3">
        <div className="col">
          <button
            type="button"
            className="btn btn-info btn-sm"
            onClick={() => push({ keyId: uuidv4(), amount: '0', description: '' })}>
            +<span> ADD PAYMENT</span>
          </button>
        </div>
      </div>
      <div>
        {!!lines.length &&
          lines.map((line, index) => (
            <PaymentLine
              key={line.keyId}
              {...line}
              index={index}
              remove={remove}
              replace={replace}
            />
          ))}
      </div>
    </div>
  );
};

PaymentAmount.propTypes = {
  remove: PropTypes.func,
  replace: PropTypes.func,
  push: PropTypes.func,
  lines: PropTypes.array,
};

export default PaymentAmount;
