const AUTH = 'AUTH';
export const REGISTER_SUCCESS = `${AUTH}__REGISTER_SUCCESS`;
export const REGISTER_FAIL = `${AUTH}__REGISTER_FAIL`;
export const LOGIN_SUCCESS = `${AUTH}__LOGIN_SUCCESS`;
export const LOGIN_TFA = `${AUTH}__LOGIN_TFA`;
export const LOGIN_FAIL = `${AUTH}__LOGIN_FAIL`;
export const LOGOUT = `${AUTH}__LOGOUT`;
export const UPDATE_USER = `${AUTH}__'LOGOUT`;

export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

const PARSED_INVOICE = 'PARSED_INVOICE';
export const ADD_INVOICE_DATA = `${PARSED_INVOICE}__ADD_INVOICE_DATA`;
export const CLEAR_INVOICE_DATA = `${PARSED_INVOICE}__CLEAR_INVOICE_DATA`;
export const SELECT_RECIPIENT = `${PARSED_INVOICE}__SELECT_RECIPIENT`;
export const SET_PARSED_ERROR = `${PARSED_INVOICE}__SET_PARSED_ERROR`;

const LOADING_OVERLAY = 'LOADING_OVERLAY';
export const SHOW_LOADING_OVERLAY = `${LOADING_OVERLAY}__SHOW`;
export const HIDE_LOADING_OVERLAY = `${LOADING_OVERLAY}__HIDE`;

const NOTIFICATIONS = 'NOTIFICATIONS';
export const SET_NOTIFICATIONS = `${NOTIFICATIONS}__SET_DATA`;
export const CLEAR_NOTIFICATIONS = `${NOTIFICATIONS}__CLEAR_DATA`;

const COMPANY_SETTINGS = 'COMPANY_SETTINGS';
export const SHOW_COMPANY_INFO_BANNER = `${COMPANY_SETTINGS}__SHOW_COMPANY_INFO_BANNER`;
export const HIDE_COMPANY_INFO_BANNER = `${COMPANY_SETTINGS}__HIDE_COMPANY_INFO_BANNER`;
