import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import organisationService from '../../../_services/organisationService';
import { showLoadingOverlay, hideLoadingOverlay } from '../../../store/actions/loadingOverlay';
import Form from './Form';
import './GeneralSettings.scss';

const GeneralSettings = () => {
  const dispatch = useDispatch();
  const [organisation, setOrganisation] = useState({});
  const initialValues = {
    receiveEmails: true,
    emails: [''],
  };

  const validate = (values) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const errors = values.emails.map((email, index) => {
      if (email && !re.test(email)) {
        return [`emails.${index}`, 'Email is invalid'];
      }

      return null;
    });

    try {
      return Object.fromEntries(errors.filter((error) => !!error));
    } catch (e) {
      return {};
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    dispatch(showLoadingOverlay());

    organisationService
      .updateOrganisationDetails({
        ...values,
        paymentEmails: values.emails.filter((email) => !!email),
      })
      .then(() => {
        toast.success('Your changes were saved successfully!');
        actions.setSubmitting(false);
        dispatch(hideLoadingOverlay());
      })
      .catch((e) => {
        console.log(e);
        toast.error('Something went wrong!');
        actions.setSubmitting(false);
        dispatch(hideLoadingOverlay());
      });
  };

  useEffect(() => {
    organisationService.getOrganisationDetails().then((result) => {
      setOrganisation(result);
    });
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {(props) => <Form {...props} organisation={organisation} />}
      </Formik>
      <ToastContainer />
    </>
  );
};

export default GeneralSettings;
