import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from 'store/actions/auth';
import { getUserNotifications } from 'store/actions/notifications';
import history from '_helpers/history';
import organisationService from '_services/organisationService';

const SelectCompanyPage = () => {
  const dispatch = useDispatch();
  const [organisationList, setOrganisationList] = useState([]);
  const getOrganization = () => {
    organisationService.getOrganizationList().then((results) => {
      setOrganisationList(results);
    });
  };
  const switchOrganization = (organization) => {
    organisationService.switchOrganization(organization.id).then(() => {
      dispatch(updateUser());
      dispatch(getUserNotifications(true));
      history.push('/');
    });
  };

  useEffect(() => {
    getOrganization();
  }, []);

  return (
    <div className="container-fluid pt-3 pb-3" id="content">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th className="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                {!organisationList && !organisationList.length && (
                  <tr>
                    <td colSpan={4}>No data</td>
                  </tr>
                )}
                {!!organisationList &&
                  !!organisationList.length &&
                  organisationList.map((organisation) => (
                    <tr key={organisation.id}>
                      <td>{organisation.id}</td>
                      <td>{organisation.title}</td>
                      <td>{organisation.email}</td>
                      <td align="right">
                        <button
                          type="button"
                          className="btn btn-info btn-sm"
                          disabled={organisation.active}
                          onClick={() => switchOrganization(organisation)}>
                          select{organisation.active && 'ed'}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectCompanyPage;
