import React from 'react';
import PropTypes from 'prop-types';
import './Steps.scss';
import IconComponent from 'components/IconComponent';

const Steps = (props) => {
  const { steps } = props;
  return (
    <div className="steps">
      {!!steps.length &&
        steps.map((step, index) => (
          <div
            key={step.title}
            className={`${step.completed ? 'completed' : ''} step mr-3 ${
              step.active ? 'active' : ''
            }`}>
            <div className={`${index === 0 ? 'd-none' : ''} arrow mr-3`}>
              <IconComponent name="arrowRightLong" />
            </div>
            <span className="index mr-2">
              <span className="text">{index + 1}</span>
              <span className="completed">
                <IconComponent name="checkSmall" />
              </span>
            </span>
            <span className="title">{step.title}</span>
          </div>
        ))}
    </div>
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      completed: PropTypes.bool.isRequired,
      active: PropTypes.bool.isRequired,
    }),
  ),
};

export default Steps;
