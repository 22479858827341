import React from 'react';
import PropTypes from 'prop-types';
import TemplateActions from './TemplateActions';

const TemplateInfoBody = ({
  name,
  body,
  isPaused,
  type,
  time,
  handleDeleteTemplate,
  handlePreviewTemplate,
  handlePauseTemplate,
  handlePlayTemplate,
  handleEditTemplate,
  handleCopyTemplate,
}) => {
  /* eslint-disable react/no-danger */
  return (
    <div className="card">
      <div className="card-body">
        {name && <h3>{name}</h3>}
        <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
      <div className="card-footer">
        <TemplateActions
          isPaused={isPaused}
          type={type}
          time={time}
          handleDelete={handleDeleteTemplate}
          handlePreview={handlePreviewTemplate}
          handlePause={handlePauseTemplate}
          handlePlay={handlePlayTemplate}
          handleEdit={handleEditTemplate}
          handleCopy={handleCopyTemplate}
        />
      </div>
    </div>
  );
};

TemplateInfoBody.propTypes = {
  name: PropTypes.string,
  body: PropTypes.string,
  isPaused: PropTypes.bool,
  type: PropTypes.string,
  time: PropTypes.string,
  handleDeleteTemplate: PropTypes.func,
  handlePreviewTemplate: PropTypes.func,
  handlePauseTemplate: PropTypes.func,
  handlePlayTemplate: PropTypes.func,
  handleEditTemplate: PropTypes.func,
  handleCopyTemplate: PropTypes.func,
};

TemplateInfoBody.defaultProps = {
  name: '',
  body: '',
  isPaused: false,
  type: '',
  time: '',
  handleDeleteTemplate: () => {},
  handlePreviewTemplate: () => {},
  handlePauseTemplate: () => {},
  handlePlayTemplate: () => {},
  handleEditTemplate: () => {},
  handleCopyTemplate: () => {},
};

export default TemplateInfoBody;
