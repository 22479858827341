import axios from 'axios';
import env from 'env';

const API_URL = env.API_URL;

const createPayment = (key, token, expiry) => {
  const url = `${API_URL}/api/payments/${key}/`;
  return axios.post(url, { token, expiry }).then((response) => response.data);
};

const getPaymentInfo = (key) => {
  const url = `${API_URL}/api/payments/${key}/`;
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

const payStripe = (key, data) => {
  const url = `${API_URL}/api/stripe/${key}/`;
  return axios.post(url, data).then((response) => response.data);
};

const getStripeKey = () => {
  const url = `${API_URL}/api/stripe_key/`;
  return axios.get(url).then((response) => response.data);
};

const setOutsidePayment = (uuid, formData) => {
  return axios
    .post(`${API_URL}/api/payments/outside/${uuid}/`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default {
  createPayment,
  getPaymentInfo,
  payStripe,
  getStripeKey,
  setOutsidePayment,
};
