import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import NotificationsList from './NotificationsList';
import SingleNotification from './SingleNotification';

const Notifications = () => {
  const { path } = useRouteMatch();
  return (
    <div className="container-fluid">
      <Switch>
        <Route exact path={`${path}`} component={NotificationsList} />
        <Route exact path={`${path}/create`} component={SingleNotification} />
        <Route exact path={`${path}/:templateId`} component={SingleNotification} />
      </Switch>
    </div>
  );
};

export default Notifications;
