import FileService from '_services/fileService';
import { ADD_INVOICE_DATA, CLEAR_INVOICE_DATA, SELECT_RECIPIENT, SET_PARSED_ERROR } from './types';

const fileService = new FileService();

export const addInvoiceData = (data, isParsed) => ({
  type: ADD_INVOICE_DATA,
  data,
  isParsed,
});

export const clearInvoiceData = () => ({
  type: CLEAR_INVOICE_DATA,
});

export const setParsedError = () => ({
  type: SET_PARSED_ERROR,
});

export const selectRecipient = () => ({
  type: SELECT_RECIPIENT,
});

export const getParsedInvoice = (file) => (dispatch) =>
  fileService
    .parseFile(file)
    .then(({ data }) => {
      const { data: parsedData, recipients } = data;
      if (!!parsedData && Array.isArray(recipients)) {
        dispatch(addInvoiceData(data, true));
        return;
      }
      dispatch(setParsedError());
    })
    .catch((error) => {
      dispatch(setParsedError());
      console.error(error);
    });
