import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import NavItem from 'components/NavItem';
import IconComponent from 'components/IconComponent';
import './AsideNav.scss';

const AsideNav = () => {
  const { url } = useRouteMatch();
  return (
    <Nav variant="pills" className="flex-column aside-nav" as="ul">
      <NavItem itemClass="nav-item" itemLinkClass="nav-link" to={`${url}/company`}>
        <span className="icon">
          <IconComponent name="house" />
        </span>
        Company
      </NavItem>
      <NavItem itemClass="nav-item" itemLinkClass="nav-link" to={`${url}/payment-details`}>
        <span className="icon">
          <IconComponent name="creditCard" />
        </span>
        Payment details
      </NavItem>
      <NavItem itemClass="nav-item" itemLinkClass="nav-link" to={`${url}/notifications`}>
        <span className="icon">
          <IconComponent name="envelope" />
        </span>
        Notifications
      </NavItem>
      <NavItem itemClass="nav-item" itemLinkClass="nav-link" to={`${url}/team-manager`} disabled>
        <span className="icon">
          <IconComponent name="team" />
        </span>
        Team Manager
      </NavItem>
      <NavItem itemClass="nav-item" itemLinkClass="nav-link" to={`${url}/account`}>
        <span className="icon">
          <IconComponent name="profile" />
        </span>
        My account
      </NavItem>
      <NavItem itemClass="nav-item" itemLinkClass="nav-link" to={`${url}/general-settings`}>
        <span className="icon">
          <IconComponent width="22" height="22" name="settings" className="icon-wrench" />
        </span>
        General settings
      </NavItem>
    </Nav>
  );
};

export default AsideNav;
