import React from 'react';
import PropTypes from 'prop-types';

const CustomForm = (props) => {
  const { formType, labelText, id, name, handleChangeCheck, checked = false } = props;

  return (
    <div
      aria-hidden="true"
      className={`custom-control custom-${formType}`}
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}>
      <input
        checked={checked}
        className="custom-control-input"
        type={formType}
        onChange={(e) => handleChangeCheck(e)}
        id={id}
        name={name}
      />
      {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
      <label htmlFor={id} className="custom-control-label">
        {labelText}
      </label>
    </div>
  );
};

CustomForm.propTypes = {
  formType: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  handleChangeCheck: PropTypes.func,
  checked: PropTypes.bool,
};

export default CustomForm;
