import axios from 'axios';
import env from 'env';

const API_URL = env.API_URL;

export default class RecipientService {
  createGetRecipientsQuery = (params) => {
    const queryObj = {};
    if (params.filters) {
      Object.assign(queryObj, params.filters);
    }

    if (params.searchQuery) {
      queryObj.search = params.searchQuery;
    }

    if (params.sortingFields) {
      const ordering = [];
      Object.entries(params.sortingFields).forEach(([key, value]) => {
        if (value.order !== 'default') {
          ordering.push(value.order === 'up' ? `${key}` : `-${key}`);
        }
      });
      if (ordering.length) {
        queryObj.ordering = ordering.join(',');
      }
    }

    if (params.pagerItemsPerPage) {
      queryObj.pageSize = params.pagerItemsPerPage;
    }

    if (params.pagerActivePage) {
      queryObj.page = params.pagerActivePage;
    }

    return queryObj;
  };

  getRecipients = (params) => {
    const url = `${API_URL}/api/recipients/`;
    return axios
      .get(url, { params: { ...this.createGetRecipientsQuery(params) } })
      .then((response) => response.data);
  };

  getRecipientsByURL = (link) => {
    const url = `${API_URL}${link}`;
    return axios.get(url).then((response) => response.data);
  };

  getRecipient = (pk) => {
    const url = `${API_URL}/api/recipients/${pk}/`;
    return axios.get(url).then((response) => response.data);
  };

  deleteRecipient = (pk) => {
    const url = `${API_URL}/api/recipient/${pk}/`;
    return axios.delete(url);
  };

  deleteMultipleRecipient = (recipient) => {
    const url = `${API_URL}/api/recipient/deletes/`;
    return axios.post(url, { ids: recipient }).then((response) => response.data);
  };

  createRecipient = (data) => {
    const url = `${API_URL}/api/recipients/`;
    return axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  updateRecipient = (recipientId, data) => {
    const url = `${API_URL}/api/recipients/${recipientId}/`;
    return axios.put(url, data);
  };

  batchCreateRecipient = (data) => {
    const url = `${API_URL}/api/recipient/creates/`;
    return axios.post(url, data);
  };
}
