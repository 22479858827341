import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Wildcards from 'components/Wildcards';

const WildcardsInputField = (props) => {
  const { wildcards, onWildcardChanged } = props;
  const inputField = useRef(null);

  const handleChange = (e) => {
    const value = e.target.value;
    onWildcardChanged(value);
  };

  const insertWildcard = (wildcard) => {
    if (document.selection) {
      inputField.current.focus();
      const sel = document.selection.createRange();
      sel.text = wildcard;
    } else if (inputField.current.selectionStart || inputField.current.selectionStart === '0') {
      const startPos = inputField.current.selectionStart;
      const endPos = inputField.current.selectionEnd;
      inputField.current.value =
        inputField.current.value.substring(0, startPos) +
        wildcard +
        inputField.current.value.substring(endPos, inputField.current.value.length);
      inputField.current.selectionStart = startPos + wildcard.length;
      inputField.current.selectionEnd = startPos + wildcard.length;
    } else {
      inputField.current.value += wildcard;
    }
    onWildcardChanged(inputField.current.value);
    inputField.current.focus();
  };

  return (
    <>
      <Form.Control ref={inputField} {...props} onChange={handleChange} />
      {!!wildcards && !!wildcards.length && (
        <Wildcards wildcards={wildcards} onChange={(wildcard) => insertWildcard(wildcard)} />
      )}
    </>
  );
};

WildcardsInputField.propTypes = {
  wildcards: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  onWildcardChanged: PropTypes.func,
};

WildcardsInputField.defaultProps = {
  wildcards: [],
  onWildcardChanged: () => {},
};

function PropsAreEqual(prevState, nextState) {
  return prevState.value === nextState.value && prevState.isInvalid === nextState.isInvalid;
}

export default React.memo(WildcardsInputField, PropsAreEqual);
