import axios from 'axios';
import env from 'env';

const API_URL = `${env.API_URL}/api/`;

const updateOrganisationDetails = (data, useFormData = false, internal = false) => {
  const url = `${API_URL}organisation/${internal ? '?internal=true' : ''}`;
  const formData = new FormData();
  let config = {};

  if (useFormData) {
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
  }

  return axios
    .post(url, useFormData ? formData : data, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('updateOrganisationDetails', error);
      throw error;
    });
};

const sendClintInvite = (formData) => {
  return axios
    .post(`${API_URL}organisation/invite/`, formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getOrganisationDetails = () => {
  return axios.get(`${API_URL}organisation/`).then((response) => response.data);
};

const getOrganisationByUuid = (uuid) => {
  return axios.get(`${API_URL}organisation/${uuid}/`).then((response) => response.data);
};

const clearOrganization = () => {
  return axios.get(`${API_URL}organisation/?clean=front_info`).then((response) => response.data);
};

const getOrganizationList = () => {
  return axios.get(`${API_URL}organisation/switch/`).then((response) => response.data);
};

const getLocations = (page, itemsPerPage) => {
  const params = new URLSearchParams();

  if (page) {
    params.append('page', page);
  }

  if (itemsPerPage) {
    params.append('pageSize', itemsPerPage);
  }

  return axios.get(`${API_URL}locations/?${params.toString()}`).then((response) => response.data);
};

const createLocation = (data) => {
  return axios
    .post(`${API_URL}locations/`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const deleteLocation = (id) => {
  return axios.delete(`${API_URL}locations/${id}/`).catch((error) => {
    console.log(error);
    throw error;
  });
};

const updateLocation = (id, data) => {
  return axios
    .patch(`${API_URL}locations/${id}/`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const switchOrganization = (id) => {
  return axios
    .post(`${API_URL}organisation/switch/`, { id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export default {
  updateOrganisationDetails,
  getOrganisationDetails,
  clearOrganization,
  sendClintInvite,
  getOrganizationList,
  switchOrganization,
  getOrganisationByUuid,
  createLocation,
  getLocations,
  deleteLocation,
  updateLocation,
};
