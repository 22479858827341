import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconSettings } from 'assets/images/icon_settings.svg';
import { ReactComponent as IconHouse } from 'assets/images/icon_house.svg';
import { ReactComponent as IconCreditCard } from 'assets/images/icon_credit_card.svg';
import { ReactComponent as IconEnvelope } from 'assets/images/icon_envelope.svg';
import { ReactComponent as IconTeam } from 'assets/images/icon_team.svg';
import { ReactComponent as IconAddNote } from 'assets/images/icon_add_note.svg';
import { ReactComponent as IconClose } from 'assets/images/icon_close.svg';
import { ReactComponent as IconInfo } from 'assets/images/icon_info.svg';
import { ReactComponent as IconPayments } from 'assets/images/icon_payments.svg';
import { ReactComponent as IconArrows } from 'assets/images/icon_arrows.svg';
import { ReactComponent as IconArrowRight } from 'assets/images/icon_arrow_right.svg';
import { ReactComponent as IconArrowBottom } from 'assets/images/icon_arrow_bottom.svg';
import { ReactComponent as IconNotificationsEmpty } from 'assets/images/icon_notifications_empty.svg';
import { ReactComponent as IconNotifications } from 'assets/images/icon_notifications.svg';
import { ReactComponent as IconInfoCircle } from 'assets/images/icon_info_circle.svg';
import { ReactComponent as IconProfile } from 'assets/images/icon_profile.svg';
import { ReactComponent as IconUser } from 'assets/images/icon_user.svg';
import { ReactComponent as IconNote } from 'assets/images/icon_note.svg';
import { ReactComponent as IconSwap } from 'assets/images/icon_swap.svg';
import { ReactComponent as IconCheck } from 'assets/images/icon_check.svg';
import { ReactComponent as IconCheckSmall } from 'assets/images/icon_check_small.svg';
import { ReactComponent as IconCancelled } from 'assets/images/icon_cancelled.svg';
import { ReactComponent as IconFailed } from 'assets/images/icon_failed.svg';
import { ReactComponent as IconGooglePay } from 'assets/images/icon_google_pay.svg';
import { ReactComponent as IconApplePay } from 'assets/images/icon_apple_pay.svg';
import { ReactComponent as IconMastercardBadge } from 'assets/images/icon_mastercard_badge.svg';
import { ReactComponent as IconVisaBadge } from 'assets/images/icon_visa_badge.svg';
import { ReactComponent as IconApplePayBadge } from 'assets/images/icon_applepay_badge.svg';
import { ReactComponent as IconGooglePayBadge } from 'assets/images/icon_google_pay_badge.svg';
import { ReactComponent as IconCopy } from 'assets/images/icon_copy.svg';
import { ReactComponent as IconPlus } from 'assets/images/icon_plus.svg';
import { ReactComponent as IconRemove } from 'assets/images/icon_remove.svg';
import { ReactComponent as IconWeb } from 'assets/images/icon_web.svg';
import { ReactComponent as IconArrowRightLong } from 'assets/images/icon_arrow_right_long.svg';
import { ReactComponent as IconLock } from 'assets/images/icon_lock.svg';
import { ReactComponent as IconTablet } from 'assets/images/icon_tablet.svg';
import { ReactComponent as IconMessage } from 'assets/images/icon_message.svg';
import { ReactComponent as IconActionToggle } from 'assets/images/icon_action_toggle.svg';
import { ReactComponent as IconWallet } from 'assets/images/icon_wallet.svg';
import { ReactComponent as IconArrowInCircle } from 'assets/images/icon_arrow_in_circle.svg';
import { ReactComponent as IconPlusInCircle } from 'assets/images/icon_plus_in_circle.svg';
import { ReactComponent as IconAmexBadge } from 'assets/images/icon_amex_badge.svg';
import { ReactComponent as IconImport } from 'assets/images/icon_import.svg';
import { ReactComponent as IconTime } from 'assets/images/icon_time.svg';
import { ReactComponent as IconCalendar } from 'assets/images/icon_calendar.svg';
import { ReactComponent as IconPrev } from 'assets/images/icon_prev.svg';
import { ReactComponent as IconNext } from 'assets/images/icon_next.svg';
import { ReactComponent as IconSearch } from 'assets/images/icon_search.svg';
import { ReactComponent as IconPdf } from 'assets/images/icon_pdf.svg';
import { ReactComponent as IconCsv } from 'assets/images/icon_csv.svg';
import { ReactComponent as IconPreview } from 'assets/images/icon_preview.svg';
import { ReactComponent as IconEdit } from 'assets/images/icon_edit.svg';
import { ReactComponent as IconPause } from 'assets/images/icon_pause.svg';
import { ReactComponent as IconTrash } from 'assets/images/icon_trash.svg';
import { ReactComponent as IconPlay } from 'assets/images/icon_play.svg';
import { ReactComponent as IconArrowLeft } from 'assets/images/icon_arrow_left.svg';
import { ReactComponent as IconUpload } from 'assets/images/icon_upload.svg';
import { ReactComponent as IconLocation } from 'assets/images/icon_location.svg';

const iconTypes = {
  actionToggle: IconActionToggle,
  addNote: IconAddNote,
  amexBadge: IconAmexBadge,
  applePay: IconApplePay,
  applePayBadge: IconApplePayBadge,
  arrows: IconArrows,
  arrowBottom: IconArrowBottom,
  arrowInCircle: IconArrowInCircle,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  arrowRightLong: IconArrowRightLong,
  calendar: IconCalendar,
  cancelled: IconCancelled,
  check: IconCheck,
  checkSmall: IconCheckSmall,
  close: IconClose,
  copy: IconCopy,
  creditCard: IconCreditCard,
  csv: IconCsv,
  edit: IconEdit,
  envelope: IconEnvelope,
  failed: IconFailed,
  googlePay: IconGooglePay,
  googlePayBadge: IconGooglePayBadge,
  house: IconHouse,
  import: IconImport,
  info: IconInfo,
  infoCircle: IconInfoCircle,
  lock: IconLock,
  mastercardBadge: IconMastercardBadge,
  message: IconMessage,
  next: IconNext,
  note: IconNote,
  notifications: IconNotifications,
  notificationsEmpty: IconNotificationsEmpty,
  pause: IconPause,
  payments: IconPayments,
  pdf: IconPdf,
  play: IconPlay,
  plus: IconPlus,
  plusInCircle: IconPlusInCircle,
  prev: IconPrev,
  preview: IconPreview,
  profile: IconProfile,
  remove: IconRemove,
  search: IconSearch,
  settings: IconSettings,
  swap: IconSwap,
  tablet: IconTablet,
  team: IconTeam,
  time: IconTime,
  trash: IconTrash,
  user: IconUser,
  visaBadge: IconVisaBadge,
  wallet: IconWallet,
  web: IconWeb,
  upload: IconUpload,
  location: IconLocation,
};

// const sort = Object.keys(iconTypes).sort((a, b) => {
//   if (a < b) {
//     return -1;
//   }
//   if (a > b) {
//     return 1;
//   }
//   return 0;
// });
//
// console.log('sort========>', sort);

const IconComponent = ({ name, ...props }) => {
  const Icon = iconTypes[name];
  return Icon ? <Icon {...props} /> : null;
};

IconComponent.propTypes = {
  name: PropTypes.string,
};
IconComponent.defaultProps = {
  name: '',
};

export default IconComponent;
