import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';
import DropdownCustomToggle from 'components/DropdownCustomToggle';
import styles from './ActionDropdown.module.scss';

const ActionDropdown = (props) => {
  const { children, align = 'left', className = '' } = props;

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle className={styles.opener} as={DropdownCustomToggle}>
        <IconComponent name="actionToggle" />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropDownMenu} align={align}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ActionDropdown.propTypes = {
  children: PropTypes.node,
  align: PropTypes.string,
  className: PropTypes.string,
};

export default ActionDropdown;
