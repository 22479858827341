import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Button, Form as FormEl, InputGroup } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import history from '_helpers/history';
import { logout } from 'store/actions/auth';
import AuthService from '_services/authService';
import LogoVertical from 'assets/images/logo_vertical.svg';

const SetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter your password')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: Yup.string()
    .required('Please confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const SetPasswordPage = () => {
  const { key } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const handleValidationErrors = (error, actions) => {
    const errorsData = error.data;
    if (errorsData) {
      actions.setErrors(errorsData);
      if (errorsData.nonFieldErrors) {
        const errorText = errorsData.nonFieldErrors.join('');
        toast.error(errorText);
      }
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    return AuthService.setPassword(key, values.password)
      .then(() => {
        toast.success('Password is successfully created');
        setTimeout(() => {
          history.push('/');
        }, 1500);
      })
      .catch((error) => {
        handleValidationErrors(error, actions);
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    if (user) {
      dispatch(logout());
    }
  }, []);

  return (
    <div className="d-flex align-items-center login-wrap justify-content-center">
      <div className="form-wrap d-flex align-items-center justify-content-center">
        <div className="form-holder w-100 text-center">
          <div className="logo">
            <img src={LogoVertical} alt="KeyPay" />
          </div>
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={SetPasswordSchema}
            onSubmit={handleSubmit}
            validateOnChange>
            {({ values, touched, handleChange, errors, isSubmitting }) => (
              <Form>
                <InputGroup className="mb-5">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.password && !!errors.password ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="password-input">
                        password
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormEl.Control
                    id="password-input"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                    aria-label="email"
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.password}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={
                        touched.confirmPassword && !!errors.confirmPassword ? 'is-invalid' : ''
                      }>
                      <FormEl.Label className="m-0" htmlFor="password-input">
                        confirm password
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormEl.Control
                    id="confirmPassword-input"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                    aria-label="email"
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.confirmPassword}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <Button variant="info rounded-pill" disabled={errors && isSubmitting} type="submit">
                  Set password
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default SetPasswordPage;
