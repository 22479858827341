import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import DropdownCustomToggle from 'components/DropdownCustomToggle';
import NavItem from 'components/NavItem';
import { logout } from 'store/actions/auth';
import Role from '_helpers/role';
import IconComponent from 'components/IconComponent';
import './UserNav.scss';

const UserNav = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isAdmin = currentUser.role === Role.Admin;

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div className="user-nav d-md-flex justify-content-between align-items-center">
      <Dropdown className="userNavDropdown">
        <Dropdown.Toggle className="toggle" as={DropdownCustomToggle}>
          <IconComponent name="settings" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="right">
          <Dropdown.Item className="has-link" as="div">
            <NavItem to="/settings/company">
              <IconComponent name="settings" />
              <span className="item-text">Company settings</span>
            </NavItem>
          </Dropdown.Item>
          {isAdmin && (
            <>
              <Dropdown.Item as="div" className="has-link">
                <NavItem itemClass="has-link" to="/company-fee">
                  <IconComponent name="wallet" />
                  <span className="item-text">Company fee</span>
                </NavItem>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item as="div" className="has-link">
                <NavItem itemClass="has-link" to="/select-company">
                  <IconComponent name="arrowInCircle" />
                  <span className="item-text">Switch company</span>
                </NavItem>
              </Dropdown.Item>
              <Dropdown.Item as="div" className="has-link">
                <NavItem itemClass="has-link" to="/invite-client">
                  <IconComponent name="plusInCircle" />
                  <span className="item-text">Create new company</span>
                </NavItem>
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item as="div" onClick={logOut} eventKey="2">
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <div className="ml-md-5">
        {currentUser.organization && (
          <span className="userOrganization">{currentUser.organization}</span>
        )}
        <span className="userEmail">{currentUser.email}</span>
      </div>
    </div>
  );
};

export default UserNav;
