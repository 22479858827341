import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import GeneralDetails from './GeneralDetails';
import Locations from './Locations';

const Company = () => {
  return (
    <Tabs defaultActiveKey="general" id="company" className="company-tabs">
      <Tab eventKey="general" title="General Details">
        <GeneralDetails />
      </Tab>
      <Tab eventKey="locations" title="Locations">
        <Locations />
      </Tab>
    </Tabs>
  );
};

export default Company;
