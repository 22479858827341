import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import Icon from 'components/IconComponent';
import './Dropzone.scss';

const Dropzone = ({ accept, isInvalid, multiple, maxFiles, onChange, defaultValue, ...props }) => {
  const [files, setFiles] = useState(defaultValue ? [defaultValue] : []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      onChange(acceptedFiles);
    },
    [onChange],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
    maxFiles,
  });

  const handleKeyDown = (e) => (file) => {
    if (e.keyCode === 13) {
      removeFile(file);
    }
  };

  const removeFile = () => (file) => {
    const updatedFiles = files.filter((file_) => file_ === file);
    setFiles(updatedFiles);
    onChange(updatedFiles);
  };

  useEffect(() => {
    if (defaultValue && files.length === 0) {
      setFiles([defaultValue]);
    }
  }, [defaultValue]);

  const wrapperClass = classNames({
    'form-control': true,
    'dropzone-wrapper': true,
    active: isDragActive,
    'is-invalid': isInvalid,
  });

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <div className="d-inline-flex flex-column mb-4">
        <img src={file.preview} alt="preview" />
        <span
          className="text-danger cursor-pointer"
          role="button"
          tabIndex="-1"
          onKeyDown={handleKeyDown(file)}
          onClick={removeFile(file)}>
          Remove
        </span>
      </div>
    </div>
  ));

  if (files.length > 0) {
    return <div className="preview-container">{thumbs}</div>;
  }

  return (
    <div className={wrapperClass} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="d-flex align-items-center">
        <Icon name="upload" />
        Upload
      </div>
    </div>
  );
};

Dropzone.defaultProps = {
  onChange: () => {},
  defaultValue: null,
};

Dropzone.propTypes = {
  isInvalid: PropTypes.bool,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  defaultValue: PropTypes.object,
  maxFiles: PropTypes.number,
  onChange: PropTypes.func,
};

export default Dropzone;
