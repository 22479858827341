import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const FilterTemplates = ({ enabled, onFilterChanged }) => {
  const initialFilters = [
    {
      title: 'show all',
      type: '',
    },
    {
      title: 'email',
      type: 'email',
    },
    {
      title: 'sms',
      type: 'sms',
    },
  ];
  const [activeFilter, setActiveFilter] = useState('');

  const handleFilterChanged = (type) => {
    setActiveFilter(type);
    onFilterChanged(type);
  };

  return (
    <div className="d-flex templates-filter mb-3">
      <div className="title">filter by:</div>
      <ol className="breadcrumb ">
        {initialFilters.map((item) => (
          <li
            key={item.type}
            className={classNames('breadcrumb-item', activeFilter === item.type ? 'active' : '')}>
            <button
              type="button"
              className="btn btn-link link"
              disabled={!enabled}
              onClick={() => handleFilterChanged(item.type)}>
              {item.title}
            </button>
          </li>
        ))}
      </ol>
    </div>
  );
};

FilterTemplates.propTypes = {
  enabled: PropTypes.bool,
  onFilterChanged: PropTypes.func,
};

FilterTemplates.defaultProps = {
  enabled: true,
  onFilterChanged: () => {},
};

export default FilterTemplates;
