import { combineReducers } from 'redux';
import auth from './auth';
import message from './message';
import parsedInvoice from './parsedInvoice';
import loadingOverlay from './loadingOverlay';
import notifications from './notifications';
import companyInfoBanner from './companyInfoBanner';

export default combineReducers({
  auth,
  message,
  parsedInvoice,
  loadingOverlay,
  notifications,
  companyInfoBanner,
});
