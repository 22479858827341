import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import { ToastContainer, toast } from 'react-toastify';
import { clearMessage } from 'store/actions/message';
import Role from '_helpers/role';
import history from '_helpers/history';
import CheckUserActivity from 'components/CheckUserActivity';
import Header from 'components/Header';
import AuthRoute from 'components/AuthRoute';
import LoginPage from 'pages/Login';
import SetPasswordPage from 'pages/SetPassword';
import ForgotPasswordPage from 'pages/ForgotPassword';
import InvoicesPage from 'pages/Invoices';
import RecipientsPage from 'pages/Recipients';
import PaymentPage from 'pages/Payment';
import InviteClientPage from 'pages/InviteClient';
import NotFoundPage from 'pages/NotFound';
import SettingsPage from 'pages/Settings';
import SelectCompanyPage from 'pages/SelectCompany';
import PaymentOutsidePage from 'pages/PaymentOutside';
import CompanyFeePage from 'pages/CompanyFee';
import Spinner from 'components/Spinner';
import RootRoute from 'components/RootRoute';
import './App.scss';

const App = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { show: loadingIsActive, message: loadingMessage } = useSelector(
    ({ loadingOverlay }) => loadingOverlay,
  );
  const { messageType, message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message && Array.isArray(message) && message.length) {
      message.forEach((textError) => {
        toast[messageType](textError);
      });
    } else if (message) {
      toast[messageType](message.toString());
    }
  }, [message, messageType]);

  useEffect(() => {
    history.listen(() => {
      dispatch(clearMessage()); // clear message when changing location
    });
  }, [dispatch]);

  return (
    <Router history={history}>
      <LoadingOverlay active={loadingIsActive} text={loadingMessage} spinner={<Spinner />}>
        <div className="d-flex w-100">
          <div className="d-flex flex-column w-100" id="wrapper">
            <CheckUserActivity />
            {isLoggedIn && <Header />}
            <div className="main-container">
              <Switch>
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/set-password/:key" component={SetPasswordPage} />
                <Route exact path="/forgot-password" component={ForgotPasswordPage} />
                <AuthRoute
                  exact
                  path="/select-company"
                  component={SelectCompanyPage}
                  requiredRoles={[Role.Admin]}
                />
                <RootRoute
                  exact
                  path="/"
                  component={InvoicesPage}
                  requiredRoles={[Role.Admin, Role.User]}
                />
                <AuthRoute
                  path="/recipients"
                  component={RecipientsPage}
                  requiredRoles={[Role.Admin, Role.User]}
                />
                <AuthRoute
                  path="/settings"
                  component={SettingsPage}
                  requiredRoles={[Role.Admin, Role.User]}
                />
                <Route path="/payment/:key" component={PaymentPage} />
                <Route path="/payment-form/:key" component={PaymentOutsidePage} />
                <AuthRoute
                  path="/invite-client"
                  component={InviteClientPage}
                  requiredRoles={[Role.Admin]}
                />
                <AuthRoute
                  path="/company-fee"
                  component={CompanyFeePage}
                  requiredRoles={[Role.Admin]}
                />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
        </div>
        <ToastContainer />
      </LoadingOverlay>
    </Router>
  );
};

export default App;
