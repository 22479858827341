import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import IconComponent from 'components/IconComponent';

const InvoicePaymentDetails = ({ amountToPay, activeRecipient, editedInvoice }) => {
  const [hasPending, setHasPending] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  const countAmountToTay = (linesArr) => {
    if (!linesArr.length) {
      return 0;
    }
    let computedTotal = 0;
    linesArr.forEach((line) => {
      if (line.amount) {
        computedTotal += parseFloat(line.amount);
      }
    });
    return computedTotal.toFixed(2);
  };

  const setPayValue = (value) => {
    const payValue = value ? value.toFixed(2) : 0;
    setFieldValue('toPay', payValue);
  };

  useEffect(() => {
    if (editedInvoice) {
      /* eslint-disable */
      const { recipient, message, file, filename, lines, sendEmail, sendText } = editedInvoice;
      const { name: editedName, email, phone, address } = recipient;
      setFieldValue('name', { value: editedName, label: editedName });
      setFieldValue('email', { value: email, label: email });
      setFieldValue('phone', { value: phone, label: phone });
      setFieldValue('address', { value: address, label: address });
      setFieldValue('message', message);
      setFieldValue('file', file);
      setFieldValue('filename', filename);
      setFieldValue('lines', lines);
      setFieldValue('sendEmail', sendEmail);
      setFieldValue('sendText', sendText);
    }
  }, [editedInvoice]);

  useEffect(() => {
    setHasPending(!!Number(activeRecipient?.invoiceTotal));
  }, [JSON.stringify(activeRecipient)]);

  useEffect(() => {
    const linesToTay = countAmountToTay(values.lines);
    setFieldValue('amountToTay', linesToTay);
  }, [JSON.stringify(values.lines)]);

  return (
    <Card style={{ borderColor: '#e5e9f2', fontSize: '0.75rem', fontWeight: 500 }} className="mb-4">
      <Card.Body className="py-3">
        <div className="text-uppercase form-label mb-3">payment details:</div>
        {values.changedRecipient !== 'create' && hasPending && (
          <>
            <div className="row mb-1">
              <div className="col-7">Amount to pay</div>
              <div className="col-5">
                <NumberFormat
                  value={amountToPay}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  prefix="$ "
                  decimalScale={2}
                  renderText={(value) => <span>{value}</span>}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-7">
                Pending payment
                <sup style={{ position: 'relative' }}>
                  <a data-for="policy-tooltip" data-tip="">
                    {' '}
                    <IconComponent name="info" />
                  </a>
                </sup>
                <ReactTooltip
                  id="policy-tooltip"
                  globalEventOff="click"
                  getContent={() => (
                    <div>
                      <p className="m-0">
                        {activeRecipient.name} has another payment pending invoice.
                      </p>
                      <p className="m-0">
                        The amount from the previous one will be added to current, <br />
                        and the previous one will be deactivated.
                      </p>
                    </div>
                  )}
                />
              </div>
              <div className="col-5">
                <NumberFormat
                  value={activeRecipient.invoiceTotal}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  prefix="$ "
                  decimalScale={2}
                  renderText={(value) => <span>{value}</span>}
                />
              </div>
            </div>
          </>
        )}
        <div className="row text-uppercase" style={{ fontSize: '1.2rem' }}>
          <div className="col-7">
            <span style={{ color: '#46AAF7' }}>total to pay</span>
          </div>
          <div className="col-5">
            <NumberFormat
              value={
                activeRecipient?.invoiceTotal && values.changedRecipient !== 'create'
                  ? parseFloat(activeRecipient.invoiceTotal) + parseFloat(amountToPay)
                  : amountToPay
              }
              displayType="text"
              thousandSeparator=","
              decimalSeparator="."
              prefix="$ "
              decimalScale={2}
              onValueChange={({ floatValue }) => {
                setPayValue(floatValue);
              }}
              renderText={(value) => <span className="value">{value}</span>}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

InvoicePaymentDetails.propTypes = {
  amountToPay: PropTypes.string,
  activeRecipient: PropTypes.shape({
    invoiceTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
};

export default InvoicePaymentDetails;
