export const defaultCompanyAddress = {
  companyAddress: '',
  companyAddressLine1: '',
  companyAddressLine2: '',
  companyAddressCity: '',
  companyAddressState: '',
  companyAddressPostalCode: '',
};

export const defaultPersonAddress = {
  personAddress: '',
  personAddressLine1: '',
  personAddressLine2: '',
  personAddressCity: '',
  personAddressState: '',
  personAddressPostalCode: '',
};

export const defaultData = {
  businessProfileMcc: '',
  businessProfileUrl: '',
  businessProfileProductDescription: '',
  companyName: '',
  companyPhone: '',
  companyTaxId: '',
  companyTaxIdProvided: false,
  ...defaultCompanyAddress,
  personDob: '',
  personDobDay: '',
  personDobMonth: '',
  personDobYear: '',
  personEmail: '',
  personFirstName: '',
  personLastName: '',
  personIdNumber: '',
  personIdNumberProvided: false,
  personPhone: '',
  personRelationshipTitle: '',
  documentFront: null,
  documentBack: null,
  ...defaultPersonAddress,
  tosAcceptance: false,
};
