import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Button, Form as FormEl, InputGroup } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import AuthService from '_services/authService';
import LogoVertical from 'assets/images/logo_vertical.svg';
import history from '_helpers/history';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

const ForgotPasswordPage = () => {
  const handleValidationErrors = (error, actions) => {
    const errorsData = error.data;
    if (errorsData) {
      actions.setErrors(errorsData);
      if (errorsData.nonFieldErrors) {
        const errorText = errorsData.nonFieldErrors.join('');
        toast.error(errorText);
      }
    }
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    return AuthService.forgotPassword(values.email)
      .then(() => {
        toast.success('Success! Please, check your email.');
        setTimeout(() => {
          history.push('/login');
        }, 1500);
      })
      .catch((error) => {
        handleValidationErrors(error, actions);
        actions.setSubmitting(false);
      });
  };

  return (
    <div className="d-flex align-items-center login-wrap justify-content-center">
      <div className="form-wrap d-flex align-items-center justify-content-center">
        <div className="form-holder w-100 text-center">
          <div className="logo">
            <img src={LogoVertical} alt="KeyPay" />
          </div>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleSubmit}
            validateOnChange>
            {({ values, touched, handleChange, errors, isSubmitting }) => (
              <Form>
                <InputGroup className="mb-5">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.email && !!errors.email ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="email-input">
                        email
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormEl.Control
                    id="email-input"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    aria-label="email"
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.email}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <Button
                  variant="info rounded-pill min-w"
                  disabled={errors && isSubmitting}
                  type="submit">
                  Reset password
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPasswordPage;
