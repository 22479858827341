import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { showLoadingOverlay, hideLoadingOverlay } from 'store/actions/loadingOverlay';
import organisationService from '_services/organisationService';
import InfoForm from './InfoForm';
import InformationBanner from './InformationBanner';

const Company = () => {
  const dispatch = useDispatch();
  const [countSubmit, setCountSubmit] = useState(0);
  const [infoFormErrors, setInfoFormErrors] = useState({});
  const [companyData, setCompanyData] = useState(null);

  const successMessage = 'Data has been sent for verification. It will take up to 20 min.';

  const clearCompany = () => {
    return organisationService.clearOrganization();
  };

  const updatePaymentDetails = (formData) => {
    dispatch(showLoadingOverlay());

    return organisationService
      .updateOrganisationDetails(formData, true)
      .then((result) => {
        setCompanyData(result);
        toast.success(successMessage);
        dispatch(hideLoadingOverlay());
      })
      .catch((error) => {
        if (error.status === 413) {
          toast.error(`413 : Server error`);
          return;
        }
        const errorObj = error.data;
        setInfoFormErrors(errorObj);
        Object.keys(errorObj).forEach((key) => {
          toast.error(`${key} : ${errorObj[key]}`);
        });
        dispatch(hideLoadingOverlay());
      });
  };

  const handleInfoSubmit = (formData) => {
    const serverData = { ...formData };
    if (formData.companyTaxIdProvided) {
      delete serverData.companyTaxId;
    }
    delete serverData.companyTaxIdProvided;
    if (formData.personIdNumberProvided) {
      delete serverData.personIdNumber;
    }
    delete serverData.personIdNumberProvided;
    updatePaymentDetails(serverData);
  };

  const getPaymentDetails = () => {
    return organisationService.getOrganisationDetails().then((response) => {
      response.errors?.common?.forEach((singleError) => {
        toast.error(singleError);
      });
      setCompanyData(response);
    });
  };

  const handleSubmit = () => {
    setCountSubmit((prevState) => prevState + 1);
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <>
      <div className="content-wrap">
        <InformationBanner />
        <InfoForm
          formErrors={infoFormErrors}
          companyData={companyData}
          handleSubmit={handleInfoSubmit}
          triggerSubmit={countSubmit}
        />
      </div>
      <div className="footer-container">
        <button className="btn btn-info rounded-pill" onClick={handleSubmit} type="button">
          submit
        </button>

        <button
          className="btn btn-info rounded-pill d-none"
          onClick={() => clearCompany()}
          type="button">
          Clear
        </button>
      </div>
      <ToastContainer />
    </>
  );
};

export default Company;
