import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './DropdownCustomToggle.scss';

const DropdownCustomToggle = React.forwardRef((actions, ref) => {
  const { onClick, children, 'aria-expanded': expanded, className } = actions;
  return (
    <a
      className={classNames('customDropdownToggle', className, expanded ? 'show' : '')}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </a>
  );
});

DropdownCustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  className: PropTypes.string,
};

export default DropdownCustomToggle;
