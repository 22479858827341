import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ActionDropdown from 'components/ActionDropdown';
import './ActionButtons.scss';

const ActionButtons = (props) => {
  const {
    handleInvoiceDetails,
    handleCancelInvoice,
    handleRefundInvoice,
    handleArchiveInvoice,
    handleResendInvoice,
    isArchived,
    status,
  } = props;
  const showDrop =
    (status === 'pending' ||
      status === 'paid' ||
      status === 'refunded' ||
      status === 'cancelled') &&
    !isArchived;
  return (
    <ActionDropdown
      className={classNames('actionDropdown', !showDrop ? 'd-none' : '')}
      align="right">
      {status === 'pending' && (
        <>
          <Dropdown.Item className="text-info font-weight-600" onClick={handleInvoiceDetails}>
            View details
          </Dropdown.Item>
          <Dropdown.Item className="text-primary font-weight-600" onClick={handleResendInvoice}>
            RE-SEND
          </Dropdown.Item>
          <Dropdown.Item className="text-danger font-weight-600" onClick={handleCancelInvoice}>
            Cancel PAYMENT
          </Dropdown.Item>
        </>
      )}
      {status === 'paid' && (
        <Dropdown.Item className="text-dark-red font-weight-600" onClick={handleRefundInvoice}>
          REFUND
        </Dropdown.Item>
      )}
      {(status === 'paid' || status === 'refunded' || status === 'cancelled') && !isArchived && (
        <Dropdown.Item className="text-secondary font-weight-600" onClick={handleArchiveInvoice}>
          ARCHIVE
        </Dropdown.Item>
      )}
    </ActionDropdown>
  );
};

ActionButtons.propTypes = {
  handleInvoiceDetails: PropTypes.func.isRequired,
  handleCancelInvoice: PropTypes.func.isRequired,
  handleRefundInvoice: PropTypes.func.isRequired,
  handleArchiveInvoice: PropTypes.func.isRequired,
  handleResendInvoice: PropTypes.func.isRequired,
  isArchived: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default ActionButtons;
