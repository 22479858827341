import { SET_NOTIFICATIONS, CLEAR_NOTIFICATIONS } from 'store/actions/types';

const initialState = [];

const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_NOTIFICATIONS:
      return payload;
    case CLEAR_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
};

export default notifications;
