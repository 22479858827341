import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const NavItem = ({ itemClass, itemLinkClass, to, children, disabled, exact = false }) => {
  const [itemClassNames, setItemClassNames] = useState('');
  const [linkClassNames, setLinkClassNames] = useState('');

  useEffect(() => {
    const classNames = [itemClass];
    const linkClasses = [itemLinkClass];
    if (disabled) {
      classNames.push('disabled');
      linkClasses.push('disabled');
    }
    setLinkClassNames(linkClasses.join(' '));
    setItemClassNames(classNames.join(' '));
  }, [itemClass, disabled, itemLinkClass]);

  return (
    <div className={itemClassNames}>
      <NavLink exact={exact} className={linkClassNames} to={to}>
        {children}
      </NavLink>
    </div>
  );
};

NavItem.propTypes = {
  exact: PropTypes.bool,
  isActive: PropTypes.bool,
  itemClass: PropTypes.string,
  itemLinkClass: PropTypes.string,
  disabled: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default NavItem;
