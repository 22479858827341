import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import RecipientService from '_services/recipientService';
import TheadSortButton from 'components/SortTable';
import Search from 'components/Search';
import CustomForm from 'components/CustomForm';
import TablePagination from 'components/TablePagination';
import RecipientDetailsModal from './RecipientDetailsModal';
import CsvUploadModal from './CsvUploadModal';

const recipientService = new RecipientService();
const sortingFieldsDefault = {
  name: {
    name: 'name',
    order: 'default',
  },
  email: {
    name: 'email',
    order: 'default',
  },
  phone: {
    name: 'phone',
    order: 'default',
  },
};

const RecipientsPage = () => {
  const [recipients, setRecipients] = useState([]);
  const [editedRecipientsId, setEditedRecipientId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectAllRecipients, setAllRecipientsSelected] = useState(false);
  const [hasCheckedRecipients, setHasCheckedRecipients] = useState(false);
  const [sortingFields, setSortingFields] = useState(sortingFieldsDefault);

  const [pagerTotalItems, setPagerTotalItems] = useState(0);
  const [pagerItemsPerPage, setPagerItemsPerPage] = useState(null);
  const [pagerActivePage, setPagerActivePage] = useState(null);

  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [uploadModalShow, setUploadModalShow] = useState(false);

  const getRecipients = () => {
    recipientService
      .getRecipients({ sortingFields, searchQuery, pagerActivePage, pagerItemsPerPage })
      .then(({ results, count }) => {
        setRecipients(results);
        setPagerTotalItems(count);
        setAllRecipientsSelected(false);
        setHasCheckedRecipients(false);
      });
  };

  const handlePagerUpdate = ({ activePage, itemsPerPage }) => {
    setPagerItemsPerPage(itemsPerPage);
    setPagerActivePage(activePage);
  };

  const openRecipientDetailsModal = (id) => {
    setDetailsModalShow(true);
    if (id) {
      setEditedRecipientId(id);
    }
  };

  const onSortChange = (currentSort) => {
    let nextSort;
    if (currentSort === 'down') nextSort = 'up';
    else if (currentSort === 'up') nextSort = 'default';
    else if (currentSort === 'default') nextSort = 'down';
    return nextSort;
  };

  const changeSorting = (sortName) => {
    const field = { ...sortingFields };
    field[sortName].order = onSortChange(sortingFields[sortName].order);
    setSortingFields(field);
  };

  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue);
    if (searchQuery !== searchValue) {
      setPagerActivePage(1);
    }
  };

  const handleChangeCheck = (e) => {
    const recipientsList = recipients.slice();
    recipientsList[e.target.id].checked = e.target.checked;
    setRecipients(recipientsList);
    const checkedRecipients = recipientsList.filter((recipient) => recipient.checked).length;
    const allChecked = checkedRecipients === recipientsList.length;
    setAllRecipientsSelected(allChecked);
    setHasCheckedRecipients(checkedRecipients > 0);
  };

  const handleSelectAllRecipients = (event) => {
    const allChecked = event.target.checked;
    setAllRecipientsSelected(allChecked);
    setHasCheckedRecipients(allChecked);
    const recipientsList = recipients.map((recipient) => {
      recipient.checked = allChecked;
      return recipient;
    });
    setRecipients(recipientsList);
  };

  const deleteRecipients = () => {
    const checkedRecipients = [];
    recipients.forEach((recipient) => {
      if (recipient.checked) {
        checkedRecipients.push(recipient.id);
      }
    });
    recipientService.deleteMultipleRecipient(checkedRecipients).then(() => {
      getRecipients();
    });
  };

  const openUploadCSVModal = () => {
    setUploadModalShow(true);
  };

  const handleDetailsModal = () => {
    setDetailsModalShow(false);
    setEditedRecipientId(null);
    getRecipients();
  };

  const handleCsvModal = () => {
    setUploadModalShow(false);
    getRecipients();
  };

  useEffect(() => {
    if (!pagerItemsPerPage) {
      return;
    }
    getRecipients();
  }, [pagerItemsPerPage, pagerActivePage, searchQuery, JSON.stringify(sortingFields)]);

  return (
    <div className="container-fluid" id="content">
      <div className="filters-panel d-flex justify-content-between mt-3 mb-1">
        <div className="col col-md-4 d-flex align-items-center">
          <div className="py-2 pl-2 d-flex align-items-center">
            <CustomForm
              checked={selectAllRecipients}
              id="selectAll"
              formType="checkbox"
              labelText={' '}
              handleChangeCheck={handleSelectAllRecipients}
            />
            <button
              type="button"
              className={[
                'btn btn-link ml-3 p-0',
                !hasCheckedRecipients && !selectAllRecipients ? 'd-none' : '',
              ].join(' ')}
              style={{
                minWidth: '1px',
                color: '#ff4d4d',
                textDecoration: 'none',
                lineHeight: 1,
                borderRadius: '0.4rem',
              }}
              onClick={deleteRecipients}>
              <span
                className="btn btn-outline-danger btn-icon mr-2"
                style={{ fontSize: '0.876rem', marginTop: '-2px', padding: '0 3px' }}>
                <span className="d-block" aria-hidden="true">
                  &times;
                </span>
              </span>{' '}
              REMOVE SELECTED ITEM(S)
            </button>
          </div>
        </div>
        <div className="col col-md-8 d-flex justify-content-between">
          <div className=" w-50 p-2">
            <Search handleSearch={handleSearch} />
          </div>
          <div className="p-2">
            <button
              type="button"
              className="btn btn-outline-info rounded-pill min-w"
              onClick={openUploadCSVModal}>
              UPLOAD CSV
            </button>
          </div>
          <div className="p-2">
            <button
              type="button"
              className="btn btn-info rounded-pill min-w"
              onClick={() => openRecipientDetailsModal()}>
              ADD RECIPIENT
            </button>
          </div>
        </div>
      </div>

      <Card className="mb-3">
        <div className="invoice--list">
          <table className="table">
            <thead key="thead">
              <tr>
                <th className="pr-0 pl-4" width="50px">
                  {' '}
                </th>
                <th>
                  RECIPIENT{' '}
                  <TheadSortButton
                    sortName={sortingFields.name.name}
                    sortOrder={sortingFields.name.order}
                    changeSortOrder={changeSorting}
                  />
                </th>
                <th>
                  EMAIL{' '}
                  <TheadSortButton
                    sortName={sortingFields.email.name}
                    sortOrder={sortingFields.email.order}
                    changeSortOrder={changeSorting}
                  />
                </th>
                <th>
                  PHONE NUMBER{' '}
                  <TheadSortButton
                    sortName={sortingFields.phone.name}
                    sortOrder={sortingFields.phone.order}
                    changeSortOrder={changeSorting}
                  />
                </th>
                <th>ADDRESS</th>
                <th>Location</th>
                <th>Account Number</th>
              </tr>
            </thead>
            <tbody>
              {recipients.map((recipient, index) => (
                <tr
                  key={recipient.id}
                  onClick={() => openRecipientDetailsModal(recipient.id)}
                  className="cursor-pointer">
                  <td className="pr-0 pl-4" width="50px">
                    <CustomForm
                      id={index.toString()}
                      checked={recipient.checked}
                      formType="checkbox"
                      labelText={' '}
                      handleChangeCheck={handleChangeCheck}
                    />
                  </td>
                  <td>{recipient.name}</td>
                  <td>{recipient.email}</td>
                  <td>
                    <NumberFormat
                      value={recipient.phone}
                      format="(###) ### ####"
                      mask=""
                      displayType="text"
                      renderText={(value) => <span className="value">{value}</span>}
                    />
                  </td>
                  <td>{recipient.address}</td>
                  <td>{recipient.location}</td>
                  <td>{recipient.accountNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <TablePagination
          pagerActivePage={pagerActivePage}
          pagerTotalItems={pagerTotalItems}
          pagerUpdatedCallback={handlePagerUpdate}
          totalText="Total recipients"
          pagerName="recipientsPager"
        />
      </Card>
      {detailsModalShow && (
        <RecipientDetailsModal
          editedRecipient={editedRecipientsId}
          show={detailsModalShow}
          modalSize="md"
          onHandleUpdate={handleDetailsModal}
        />
      )}
      {uploadModalShow && (
        <CsvUploadModal show={uploadModalShow} modalSize="xl" onHandleUpdate={handleCsvModal} />
      )}
    </div>
  );
};

export default RecipientsPage;
