import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import IconComponent from 'components/IconComponent';
import SimpleModal from 'components/SimpleModal';

const ConfirmationRefundModal = (props) => {
  const { refundInvoice, recipientName, refundAmount } = props;
  return (
    <SimpleModal
      modalHeader={
        <>
          <span className="mr-3">
            <IconComponent name="infoCircle" />
          </span>
          REFUND INVOICE
        </>
      }
      modalFooter={
        <button type="button" className="btn btn-danger rounded-pill" onClick={refundInvoice}>
          Refund
        </button>
      }
      {...props}>
      <h5 className="font-weight-normal">
        Are you sure you want to refund this transaction to {recipientName} for{' '}
        <NumberFormat
          value={refundAmount}
          displayType="text"
          thousandSeparator=","
          decimalSeparator="."
          prefix="$ "
          renderText={(value) => <span className="value">{value}</span>}
        />
        {'? '}
        <br />
        This cannot be undone.
      </h5>
    </SimpleModal>
  );
};

ConfirmationRefundModal.propTypes = {
  recipientName: PropTypes.string,
  refundAmount: PropTypes.string,
  refundInvoice: PropTypes.func,
};

ConfirmationRefundModal.defaultProps = {
  recipientName: '',
  refundAmount: '',
  refundInvoice: () => {},
};

export default ConfirmationRefundModal;
