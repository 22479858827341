const GetPlacesPostCodeById = async (placeId) =>
  new Promise((resolve, reject) => {
    if (!placeId) reject(new Error('placeId not provided'));

    try {
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
        {
          placeId,
          fields: ['address_components'],
        },
        (details) => {
          const addressObj = {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
          };
          const { address_components: category } = details;
          // console.log('category=======>', category);
          category?.forEach((component) => {
            const componentType = component.types[0];
            switch (componentType) {
              case 'street_number': {
                addressObj.addressLine1 = `${component.long_name}${addressObj.addressLine1}`;
                break;
              }
              case 'route': {
                addressObj.addressLine1 = `${addressObj.addressLine1} ${component.long_name}`;
                break;
              }
              case 'postal_code': {
                addressObj.postalCode = `${component.long_name}${addressObj.postalCode}`;
                break;
              }

              case 'postal_code_suffix': {
                addressObj.postalCode = `${addressObj.postalCode}-${component.long_name}`;
                break;
              }
              case 'locality':
                addressObj.city = component.long_name;
                break;

              case 'administrative_area_level_1': {
                addressObj.state = component.long_name;
                break;
              }
              case 'administrative_area_level_2': {
                addressObj.addressLine2 = component.long_name;
                break;
              }
              default:
                break;
            }
          });
          return resolve(addressObj);
        },
      );
    } catch (e) {
      reject(new Error(e));
    }
  });

export default GetPlacesPostCodeById;
