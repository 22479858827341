import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import IconComponent from 'components/IconComponent';
import styles from './CopyClipboardData.module.scss';

const cx = classNames.bind(styles);
const CopyClipboardData = ({ text }) => {
  const copyToClipboard = () => {
    // Copies a string to the clipboard. Must be called from within an event handler such as click.
    // May return false if it failed, but this is not always
    // possible. Browser support for Chrome 43+, Firefox 42+, Edge and IE 10+.
    // No Safari support, as of (Nov. 2015). Returns false.
    // IE: The clipboard feature may be disabled by an adminstrator. By default a prompt is
    // shown the first time the clipboard is used (per session).
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      window.clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
    return text;
  };

  return (
    <button
      type="button"
      className={cx('link text-secondary', styles.btnClipboard)}
      onClick={copyToClipboard}
      title={`Copy ${text}`}>
      <IconComponent name="copy" />
    </button>
  );
};

CopyClipboardData.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CopyClipboardData;
