import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';
import './Pdf.scss';

const Pdf = (props) => {
  const { pdf, filename, removeFile, parseFile, isParsed } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1); // setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(pageNumber);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const fileParams = {
    httpHeaders: {
      Authorization: `Token ${JSON.parse(localStorage.getItem('user')).token}`,
    },
    ...pdf,
  };

  return (
    <>
      {!isParsed && (
        <div className="description pb-2">
          <p className="m-0">
            KeyPay can automatically import the recipient's name, address and other contact
            information and the balance due automatically.
          </p>
          <p className="m-0">
            Click or Tap "<strong>Fetch Recipient Info</strong>" to import from the attached PDF.
          </p>
        </div>
      )}
      <div className="row preview">
        <div className="col">
          {filename}
          {!isParsed && (
            <span
              className="btn btn-info btn-sm ml-2"
              role="button"
              tabIndex={0}
              onClick={parseFile}
              onKeyDown={parseFile}>
              Fetch recipient info
            </span>
          )}
          <span
            className="btn btn-danger btn-sm ml-2"
            role="button"
            tabIndex={0}
            onClick={removeFile}
            onKeyDown={removeFile}>
            REMOVE
          </span>
        </div>
        <div className="d-flex justify-content-end col-md-auto">
          <div className="preview-pager">
            <button
              type="button"
              className="btn btn-link"
              disabled={pageNumber <= 1}
              onClick={previousPage}>
              <IconComponent name="prev" alt="prev" />
            </button>
            Page {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            <button
              type="button"
              className="btn btn-link"
              disabled={pageNumber >= numPages}
              onClick={nextPage}>
              <IconComponent name="next" alt="next" />
            </button>
          </div>
        </div>
      </div>
      <div className="pdfPreviewWrapper">
        <div className="pdfPreviewHolder">
          <Document
            file={fileParams}
            renderMode="svg"
            options={{ workerSrc: '/pdf.worker.js' }}
            onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}>
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
      </div>
    </>
  );
};

Pdf.propTypes = {
  pdf: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  filename: PropTypes.string.isRequired,
  removeFile: PropTypes.func.isRequired,
  isParsed: PropTypes.bool,
  parseFile: PropTypes.func,
};

export default Pdf;
