import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { clearMessage } from 'store/actions/message';
import { login } from 'store/actions/auth';
import { Redirect } from 'react-router-dom';
import LoginForm from './LoginForm';
import './LoginPage.scss';

const LoginPage = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const validateForm = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Email is required';
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) && values.email) {
      errors.email = 'Invalid email address';
    } else if (values.email.length >= 255) {
      errors.email = 'Must be 255 characters or less';
    }

    if (!values.password) {
      errors.password = 'Password is required';
    }

    if (user?.userId && !values.optCode) {
      errors.optCode = 'Code is required';
    }

    return errors;
  };

  const handleSubmit = (values) => {
    dispatch(clearMessage());
    const formData = {
      password: values.password,
      username: values.email,
    };
    if (user?.userId) {
      formData.userId = user?.userId;
      formData.optCode = values.optCode;
    }
    return dispatch(login(formData));
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }
  return (
    <div className="d-flex align-items-center login-wrap justify-content-center">
      <Formik
        initialValues={{ email: '', password: '', optCode: '' }}
        validate={validateForm}
        onSubmit={handleSubmit}>
        {(props) => <LoginForm {...props} userId={user?.userId} />}
      </Formik>
    </div>
  );
};

export default LoginPage;
