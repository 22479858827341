import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';
import PreviewTemplate from './PreviewTemplate';

const PreviewModal = (props) => {
  const { show, modalSize, onHideModal, template } = props;
  const modalClasses = `modal-dialog modal-dialog-centered modal-${modalSize}`;

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName="fade modal show d-block"
      className={modalClasses}
      aria-labelledby="contained-modal-title-vcenter"
      onRequestClose={onHideModal}
      isOpen={show}>
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title text-uppercase">preview</h3>
          <button type="button" className="btn btn-cancel btn-link" onClick={onHideModal}>
            <IconComponent name="close" />
          </button>
        </div>
        <div className="modal-body">
          <PreviewTemplate template={template} />
        </div>
      </div>
    </Modal>
  );
};

PreviewModal.propTypes = {
  show: PropTypes.bool,
  modalSize: PropTypes.string,
  onHideModal: PropTypes.func,
  template: PropTypes.object,
};

PreviewModal.defaultProps = {
  show: false,
  modalSize: '',
  onHideModal: () => {},
  template: {},
};

export default PreviewModal;
