import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, NavLink } from 'react-router-dom';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Payouts from './Payouts';
import CompanyDetails from './CompanyDetails';
import PaymentMethods from './PaymentMethods';
import WebPayment from './WepPayment';
import './PaymentDetails.scss';

const renderTooltip = (props) => (
  <Tooltip id="payouts-tooltip" {...props}>
    <div className="p-2">
      In order to continue with this tab you need to fill in all the required fields in the{' '}
      <strong>Company Details</strong> tab
    </div>
  </Tooltip>
);

const PaymentDetails = () => {
  const {
    user: { stripeErrors },
  } = useSelector((state) => state.auth);

  const {
    url,
    params: { key },
  } = useRouteMatch();

  const path = url.replace(`/${key}`, '');

  return (
    <>
      <Nav variant="tabs" defaultActiveKey={`${path}/company`}>
        <div className="nav-item">
          <NavLink className="nav-link" to={`${path}/company`}>
            Company Details
          </NavLink>
        </div>
        <div className="nav-item">
          {stripeErrors?.length > 0 ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 0, hide: 400 }}
              overlay={renderTooltip}>
              {({ ref, ...triggerHandler }) => (
                <div ref={ref} {...triggerHandler}>
                  <NavLink className="nav-link disabled" to={`${path}/payouts`}>
                    Payouts
                  </NavLink>
                </div>
              )}
            </OverlayTrigger>
          ) : (
            <NavLink className="nav-link" to={`${path}/payouts`}>
              Payouts
            </NavLink>
          )}
        </div>
        <div className="nav-item">
          <NavLink className="nav-link" to={`${path}/payment-methods`}>
            Payment Methods
          </NavLink>
        </div>
        <div className="nav-item">
          <NavLink className="nav-link" to={`${path}/web-payment`}>
            Web Payment
          </NavLink>
        </div>
      </Nav>
      <Switch>
        <Route exact path="/settings/payment-details/company" component={CompanyDetails} />
        <Route exact path={`${path}/payouts`} component={Payouts} />
        <Route exact path={`${path}/payment-methods`} component={PaymentMethods} />
        <Route exact path={`${path}/web-payment`} component={WebPayment} />
      </Switch>
    </>
  );
};

export default PaymentDetails;
