import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form as FormEl, Alert } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { Formik, Form } from 'formik';
import NumberFormat from 'react-number-format';
import LocationSearchInput from 'components/LocationSearchInput';
import DatePickerField from 'components/DatePickerField';
import SelectField from 'components/SelectField';
import AutoSubmitForm from './AutoSubmitForm';
import { defaultData } from './FormFieldNames';
import MCC from './MCC.json';
import './InfoForm.scss';

const InfoForm = (props) => {
  const { companyData, triggerSubmit, handleSubmit: sendFormData, formErrors } = props;

  const [mccOptions, setMccOptions] = useState([]);
  const [hasDocumentFront, setHasDocumentFront] = useState(false);
  const [hasDocumentBack, setHasDocumentBack] = useState(false);
  const [documentFrontName, setDocumentFrontName] = useState('No file chosen');
  const [documentBackName, setDocumentBackName] = useState('No file chosen');

  const handleFileChanged = (event, actionChangeFile, actionChangeLabel, label) => {
    if (!event.target.files[0]) {
      return;
    }
    actionChangeLabel(event.target.files[0].name);
    actionChangeFile(label, event.target.files[0]);
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.personEmail) {
      errors.personEmail = 'Email is required';
    } else if (values.personEmail.length >= 255) {
      errors.personEmail = 'Must be 255 characters or less';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.personEmail)) {
      errors.personEmail = 'Invalid email address';
    }

    if (!values.businessProfileMcc) {
      errors.businessProfileMcc = 'Business Category is required';
    }

    if (!values.businessProfileProductDescription && !values.businessProfileUrl) {
      errors.businessProfileUrl = 'Company Web Site or Company Description Site  is required';
      errors.businessProfileProductDescription =
        'Company Description or Company Web Site is required';
    }

    if (!values.companyAddressCity) {
      errors.companyAddressCity = 'City is required';
    }

    if (!values.companyAddressLine1) {
      errors.companyAddressLine1 = 'Address Line 1 is required';
    }

    if (!values.companyAddressPostalCode) {
      errors.companyAddressPostalCode = 'Zip Code is required';
    }

    if (!values.companyAddressState) {
      errors.companyAddressState = 'State is required';
    }

    if (!values.companyName) {
      errors.companyName = 'Company Name is required';
    } else if (values.companyName.length > 22) {
      errors.companyName = '22 characters max';
    }

    if (!values.companyPhone) {
      errors.companyPhone = 'Phone Number is required';
    }

    if (!(values.companyTaxId || values.companyTaxIdProvided)) {
      errors.companyTaxId = 'Tax Id is required';
    }

    if (!values.personAddressCity) {
      errors.personAddressCity = 'City is required';
    }

    if (!values.personAddressLine1) {
      errors.personAddressLine1 = 'Address Line 1 is required';
    }

    if (!values.personAddressPostalCode) {
      errors.personAddressPostalCode = 'Zip Code is required';
    }

    if (!values.personAddressState) {
      errors.personAddressState = 'State is required';
    }

    if (!values.personDob) {
      errors.personDob = 'Date of Birth is required';
    }

    if (!values.personFirstName) {
      errors.personFirstName = 'First Name is required';
    }

    if (!values.personLastName) {
      errors.personLastName = 'Last Name is required';
    }

    if (!(values.personIdNumber || values.personIdNumberProvided)) {
      errors.personIdNumber = 'Tax ID Number is required';
    }

    if (!values.personPhone) {
      errors.personPhone = 'Personal phone number is required';
    }

    if (!values.personRelationshipTitle) {
      errors.personRelationshipTitle = 'Title is required';
    }

    if (!hasDocumentFront) {
      if (!values.documentFront) {
        errors.documentFront = 'ID Document (front side) is required';
      } else if (
        !(
          values.documentFront.type === 'application/pdf' ||
          values.documentFront.type === 'image/png' ||
          values.documentFront.type === 'image/jpeg'
        )
      ) {
        errors.documentFront = 'Please upload .png/.jpeg/.pdf format of the file';
      } else if (values.documentFront.size > 10240000) {
        errors.documentFront = 'Uploaded file must be less then 10Mb';
      }
    }

    if (!hasDocumentBack) {
      if (!values.documentBack) {
        errors.documentBack = 'ID Document (back side) is required';
      } else if (
        !(
          values.documentBack.type === 'application/pdf' ||
          values.documentBack.type === 'image/png' ||
          values.documentBack.type === 'image/jpeg'
        )
      ) {
        errors.documentBack = 'Please upload .png/.jpeg/.pdf format of the file';
      } else if (values.documentBack.size > 10240000) {
        errors.documentBack = 'Uploaded file must be less then 10Mb';
      }
    }

    if (!values.tosAcceptance) {
      errors.tosAcceptance = ' tosAcceptance is required';
    }

    return errors;
  };

  const handleSubmit = (values) => {
    sendFormData(values);
  };

  const createMccValues = (obj) => {
    const options = [];
    Object.keys(obj).forEach((key) => {
      options.push({
        label: `${key}: ${obj[key]}`,
        value: obj[key],
      });
    });
    setMccOptions(options);
  };

  useEffect(() => {
    createMccValues(MCC);
  }, []);

  useEffect(() => {
    if (companyData) {
      setHasDocumentBack(!!companyData.personVerificationDocumentBack);
      setHasDocumentFront(!!companyData.personVerificationDocumentFront);
    }
  }, [companyData]);

  return (
    <Formik validate={validateForm} initialValues={defaultData} onSubmit={handleSubmit}>
      {({ handleChange, values, touched, errors, setFieldValue }) => (
        <Form>
          <div className="fieldset-holder">
            <div className="fieldset-head">
              <h2 className="fieldset-title">About your company</h2>
              <span className="fieldset-subtitle">
                Details about your company name and description
              </span>
            </div>
            <div className="row">
              <div className="col-lg-7 col-xl-5">
                <FormEl.Group className="mb-4" controlId="companyName">
                  <FormEl.Label>Company Name</FormEl.Label>
                  <FormEl.Control
                    className="form-control small-text"
                    name="companyName"
                    placeholder="Your business name"
                    value={values.companyName}
                    onChange={handleChange}
                    aria-label="companyName"
                    isInvalid={touched.companyName && !!errors.companyName}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.companyName}
                  </FormEl.Control.Feedback>
                </FormEl.Group>

                <div className="row">
                  <div className="col-md-6">
                    <SelectField
                      small
                      className="mb-4"
                      label="Business Category"
                      name="businessProfileMcc"
                      value={values.businessProfileMcc}
                      onChange={(value) => setFieldValue('businessProfileMcc', value)}
                      options={mccOptions}
                      placeholder="Select category..."
                      defaultValue="5734"
                      aria-label="businessProfileMcc"
                      error={errors.businessProfileMcc}
                      isInvalid={touched.businessProfileMcc && !!errors.businessProfileMcc}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="companyTaxId">
                      <FormEl.Label>Tax ID</FormEl.Label>
                      <NumberFormat
                        name="companyTaxId"
                        className="small-text"
                        format="##-#######"
                        placeholder="**-*********"
                        mask=""
                        onValueChange={(formatValues) => {
                          if (values.companyTaxIdProvided && values.companyTaxId) {
                            setFieldValue('companyTaxId', '');
                            setFieldValue('companyTaxIdProvided', false);
                            return;
                          }
                          setFieldValue('companyTaxId', formatValues.value);
                        }}
                        value={values.companyTaxIdProvided ? '000000000' : values.companyTaxId}
                        customInput={FormEl.Control}
                        isInvalid={touched.companyTaxId && !!errors.companyTaxId}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.companyTaxId}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="businessProfileUrl">
                      <FormEl.Label>Company Web Site (optional)</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="businessProfileUrl"
                        placeholder="www.yourcompany.com"
                        value={values.businessProfileUrl}
                        onChange={handleChange}
                        aria-label="businessProfileUrl"
                        isInvalid={touched.businessProfileUrl && !!errors.businessProfileUrl}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.businessProfileUrl}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="companyPhone">
                      <FormEl.Label>Phone Number</FormEl.Label>
                      <NumberFormat
                        name="companyPhone"
                        className="small-text"
                        format="(###) ### ####"
                        mask=""
                        onValueChange={(formatValues) => {
                          setFieldValue('companyPhone', formatValues.value);
                        }}
                        value={values.companyPhone}
                        customInput={FormEl.Control}
                        isInvalid={touched.companyPhone && !!errors.companyPhone}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.companyPhone}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
                <FormEl.Group className="mb-4" controlId="businessProfileProductDescription">
                  <FormEl.Label>Company Description (optional)</FormEl.Label>
                  <FormEl.Control
                    style={{ minHeight: '87px' }}
                    as="textarea"
                    className="form-control small-text"
                    name="businessProfileProductDescription"
                    placeholder="Add short description that describes your business"
                    value={values.businessProfileProductDescription}
                    onChange={handleChange}
                    aria-label="companyDescription"
                    isInvalid={
                      touched.businessProfileProductDescription &&
                      !!errors.businessProfileProductDescription
                    }
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.businessProfileProductDescription}
                  </FormEl.Control.Feedback>
                </FormEl.Group>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-xl-5">
              <div className="fieldset-holder">
                <div className="fieldset-head">
                  <h2 className="fieldset-title">Company address</h2>
                  <span className="fieldset-subtitle">Provide your company address</span>
                </div>
                <FormEl.Group className="mb-2">
                  <FormEl.Label htmlFor="companyFullAddress">Search Company Address</FormEl.Label>
                  <LocationSearchInput
                    onChange={handleChange}
                    id="companyFullAddress"
                    name="companyAddress"
                    placeholder="Start typing to find an address..."
                    value={values.companyAddress}
                    dependingFields="company"
                    callBackFunc="initOne"
                  />
                </FormEl.Group>
                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="companyAddressState">
                      <FormEl.Label>State</FormEl.Label>
                      <FormEl.Control
                        className="small-text"
                        name="companyAddressState"
                        value={values.companyAddressState}
                        onChange={handleChange}
                        aria-label="companyAddressState"
                        isInvalid={touched.companyAddressState && !!errors.companyAddressState}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.companyAddressState}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="companyAddressCity">
                      <FormEl.Label>City</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="companyAddressCity"
                        value={values.companyAddressCity}
                        onChange={handleChange}
                        aria-label="companyAddressCity"
                        isInvalid={touched.companyAddressCity && !!errors.companyAddressCity}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.companyAddressCity}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col md-6">
                    <FormEl.Group className="mb-4" controlId="companyAddressLine1">
                      <FormEl.Label>Address line 1</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="companyAddressLine1"
                        placeholder="2972 Westheimer Rd. Santa Ana"
                        value={values.companyAddressLine1}
                        onChange={handleChange}
                        aria-label="companyAddressLine1"
                        isInvalid={touched.companyAddressLine1 && !!errors.companyAddressLine1}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.companyAddressLine1}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                  <div className="col md-6">
                    <FormEl.Group className="mb-4" controlId="companyAddressLine2">
                      <FormEl.Label>Address line 2</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="companyAddressLine2"
                        value={values.companyAddressLine2}
                        onChange={handleChange}
                        aria-label="companyAddressLine2"
                        isInvalid={touched.companyAddressLine2 && !!errors.companyAddressLine2}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.companyAddressLine2}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="companyAddressPostalCode">
                      <FormEl.Label>Zip Code</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="companyAddressPostalCode"
                        value={values.companyAddressPostalCode}
                        placeholder="12345"
                        onChange={handleChange}
                        aria-label="companyAddressPostalCode"
                        isInvalid={
                          touched.companyAddressPostalCode && !!errors.companyAddressPostalCode
                        }
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.companyAddressPostalCode}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
              </div>
              <div className="fieldset-holder">
                <div className="fieldset-head">
                  <h2 className="fieldset-title">Owner details</h2>
                  <span className="fieldset-subtitle">
                    Details about person who recieves payments
                  </span>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personFirstName">
                      <FormEl.Label>First Name</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personFirstName"
                        placeholder="John"
                        value={values.personFirstName}
                        onChange={handleChange}
                        aria-label="personFirstName"
                        isInvalid={touched.personFirstName && !!errors.personFirstName}
                      />
                      {errors && errors.personFirstName && (
                        <FormEl.Control.Feedback type="invalid" tooltip>
                          {errors.personFirstName}
                        </FormEl.Control.Feedback>
                      )}
                    </FormEl.Group>
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personLastName">
                      <FormEl.Label>Last Name</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personLastName"
                        placeholder="Doe"
                        value={values.personLastName}
                        onChange={handleChange}
                        aria-label="personLastName"
                        isInvalid={touched.personLastName && !!errors.personLastName}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personLastName}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4">
                      <FormEl.Label htmlFor="personDob">Date of Birth</FormEl.Label>
                      <DatePickerField
                        className="form-control small-text"
                        id="personDob"
                        name="personDob"
                        placeholder="Select date..."
                        dayField="personDobDay"
                        monthField="personDobMonth"
                        yearField="personDobYear"
                        value={values.personDob}
                        onChange={handleChange}
                        aria-label="personDob"
                        isInvalid={touched.personDob && !!errors.personDob}
                      />
                      {errors && errors.personDob && touched.personDob && (
                        <FormEl.Control.Feedback className="d-block" type="invalid" tooltip>
                          {errors.personDob}
                        </FormEl.Control.Feedback>
                      )}
                    </FormEl.Group>
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personRelationshipTitle">
                      <FormEl.Label>Job title</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personRelationshipTitle"
                        placeholder="CEO"
                        value={values.personRelationshipTitle}
                        onChange={handleChange}
                        aria-label="personRelationshipTitle"
                        isInvalid={
                          touched.personRelationshipTitle && !!errors.personRelationshipTitle
                        }
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personRelationshipTitle}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>

                <FormEl.Group className="mb-2">
                  <FormEl.Label htmlFor="personFullAddress">Home Address</FormEl.Label>
                  <LocationSearchInput
                    name="personAddress"
                    onChange={handleChange}
                    id="personFullAddress"
                    placeholder="Start typing to find an address..."
                    value={values.personAddress}
                    dependingFields="person"
                    callBackFunc="initTwo"
                  />
                </FormEl.Group>
                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personAddressState">
                      <FormEl.Label>State</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personAddressState"
                        placeholder=""
                        value={values.personAddressState}
                        onChange={handleChange}
                        aria-label="personAddressState"
                        isInvalid={touched.personAddressState && !!errors.personAddressState}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personAddressState}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personAddressCity">
                      <FormEl.Label>City</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personAddressCity"
                        placeholder=""
                        value={values.personAddressCity}
                        onChange={handleChange}
                        aria-label="personAddressCity"
                        isInvalid={touched.personAddressCity && !!errors.personAddressCity}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personAddressCity}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col md-6">
                    <FormEl.Group className="mb-4" controlId="personAddressLine1">
                      <FormEl.Label>Address Line 1</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personAddressLine1"
                        placeholder="2972 Westheimer Rd. Santa Ana"
                        value={values.personAddressLine1}
                        onChange={handleChange}
                        aria-label="personAddressLine1"
                        isInvalid={touched.personAddressLine1 && !!errors.personAddressLine1}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personAddressLine1}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personAddressLine2">
                      <FormEl.Label>Address line 2</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personAddressLine2"
                        value={values.personAddressLine2}
                        onChange={handleChange}
                        aria-label="personAddressLine2"
                        isInvalid={touched.personAddressLine2 && !!errors.personAddressLine2}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personAddressLine2}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personAddressPostalCode">
                      <FormEl.Label>Zip Code</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personAddressPostalCode"
                        placeholder="12345"
                        value={values.personAddressPostalCode}
                        onChange={handleChange}
                        aria-label="personAddressPostalCode"
                        isInvalid={
                          touched.personAddressPostalCode && !!errors.personAddressPostalCode
                        }
                      />
                    </FormEl.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personIdNumber">
                      <FormEl.Label>Tax ID Number (TIN)</FormEl.Label>
                      <NumberFormat
                        name="personIdNumber"
                        className="small-text"
                        format="##-#######"
                        placeholder="**-*********"
                        mask=""
                        onValueChange={(formatValues) => {
                          if (values.personIdNumberProvided && values.personIdNumber) {
                            setFieldValue('personIdNumber', '');
                            setFieldValue('personIdNumberProvided', false);
                            return;
                          }
                          setFieldValue('personIdNumber', formatValues.value);
                        }}
                        value={values.personIdNumberProvided ? '000000000' : values.personIdNumber}
                        customInput={FormEl.Control}
                        isInvalid={touched.personIdNumber && !!errors.personIdNumber}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personIdNumber}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <FormEl.Group className="mb-4" controlId="personEmail">
                      <FormEl.Label>Perosnal Email</FormEl.Label>
                      <FormEl.Control
                        className="form-control small-text"
                        name="personEmail"
                        placeholder="john.doe@gmail.com"
                        value={values.personEmail}
                        onChange={handleChange}
                        aria-label="personEmail"
                        isInvalid={touched.personEmail && !!errors.personEmail}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personEmail}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                  <div className="col">
                    <FormEl.Group className="mb-4">
                      <FormEl.Label htmlFor="personPhone">Personal Phone Number</FormEl.Label>
                      <NumberFormat
                        name="personPhone"
                        id="personPhone"
                        className="small-text"
                        format="(###) ### ####"
                        mask=""
                        onValueChange={(formatValues) => {
                          setFieldValue('personPhone', formatValues.value);
                        }}
                        value={values.personPhone}
                        customInput={FormEl.Control}
                        isInvalid={touched.personPhone && !!errors.personPhone}
                      />
                      <FormEl.Control.Feedback type="invalid" tooltip>
                        {errors.personPhone}
                      </FormEl.Control.Feedback>
                    </FormEl.Group>
                  </div>
                </div>
              </div>
              <div className="fieldset-holder" style={{ border: 'none' }}>
                <div className="fieldset-head">
                  <h2 className="fieldset-title">ID Documents</h2>
                  <span className="fieldset-subtitle">
                    <strong>Validation info:</strong> This information will be used to establish the
                    collaboration agreement and for the invoices of the earnings generated (if any).
                    Keypay will not be held responsible for the information entered incorrectly by
                    the user and reserves the right to cancel the agreement with the user if the
                    conditions of the contract are breached.
                  </span>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="documentFront">
                      <FormEl.Label>ID Document (front side)</FormEl.Label>
                      {hasDocumentFront && (
                        <Alert variant="secondary">
                          <p>
                            <small>
                              You have already uploaded that document. Do you want to update it?
                            </small>
                          </p>
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => setHasDocumentFront(!hasDocumentBack)}>
                            Update
                          </button>
                        </Alert>
                      )}
                      {!hasDocumentFront && (
                        <div>
                          <FormEl.Label
                            className={`form-control small-text custom-file ${
                              touched.documentFront && !!errors.documentFront ? 'is-invalid' : ''
                            }`}>
                            <FormEl.File.Input
                              defaultValue={null}
                              name="documentFront"
                              onChange={(event) =>
                                handleFileChanged(
                                  event,
                                  setFieldValue,
                                  setDocumentFrontName,
                                  'documentFront',
                                )
                              }
                              aria-label="documentFront"
                              isInvalid={touched.documentFront && !!errors.documentFront}
                            />
                            <span className="file-name">
                              <span>{documentFrontName}</span>
                            </span>
                            <span className="opener">Select</span>
                          </FormEl.Label>
                          <FormEl.Control.Feedback type="invalid" tooltip>
                            {errors.documentFront}
                          </FormEl.Control.Feedback>
                        </div>
                      )}
                    </FormEl.Group>
                  </div>
                  <div className="col-md-6">
                    <FormEl.Group className="mb-4" controlId="personVerificationDocumentBack">
                      <FormEl.Label>ID Document (back side)</FormEl.Label>
                      {hasDocumentBack && (
                        <Alert variant="secondary">
                          <p>
                            <small>
                              You have already uploaded that document. Do you want to update it?
                            </small>
                          </p>
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => setHasDocumentBack(!hasDocumentBack)}>
                            Update
                          </button>
                        </Alert>
                      )}
                      {!hasDocumentBack && (
                        <div>
                          <FormEl.Label
                            className={`form-control small-text custom-file ${
                              touched.documentBack && !!errors.documentBack ? 'is-invalid' : ''
                            }`}>
                            <FormEl.File.Input
                              defaultValue={null}
                              name="documentBack"
                              onChange={(event) =>
                                handleFileChanged(
                                  event,
                                  setFieldValue,
                                  setDocumentBackName,
                                  'documentBack',
                                )
                              }
                              aria-label="documentBack"
                              isInvalid={touched.documentBack && !!errors.documentBack}
                            />
                            <span className="file-name">
                              <span>{documentBackName}</span>
                            </span>
                            <span className="opener">Select</span>
                          </FormEl.Label>
                          <FormEl.Control.Feedback type="invalid" tooltip>
                            {errors.documentBack}
                          </FormEl.Control.Feedback>
                        </div>
                      )}
                    </FormEl.Group>
                  </div>
                </div>
                <div className="fieldset-head">
                  <span className="fieldset-subtitle mb-2">
                    Make sure that your personal information matches what is written in the document
                    attached. The document information will be used for invoice purposes. Please
                    note, if your ID Document has information on both sides, you must include both
                    sides on the same page.
                  </span>
                  <span className="fieldset-subtitle">
                    <strong style={{ fontWeight: 600 }}>
                      Maximum size of the document: 10MB or less. <br />
                      For identity documents, can be JPG or PNG format.
                      <br />
                      For address or legal entity documents, can be JPG, PNG, or PDF format
                    </strong>
                  </span>
                </div>
              </div>
              <div className="pb-5">
                <div
                  aria-hidden="true"
                  className="custom-control custom-checkbox"
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}>
                  <FormEl.Control
                    value={values.tosAcceptance}
                    checked={values.tosAcceptance}
                    className="custom-control-input"
                    type="checkbox"
                    onChange={handleChange}
                    id="tosAcceptance"
                    name="tosAcceptance"
                    isInvalid={touched.tosAcceptance && !!errors.tosAcceptance}
                  />
                  <label htmlFor="tosAcceptance" className="custom-control-label">
                    I agree with{' '}
                    <span style={{ position: 'relative' }}>
                      <span
                        data-for="policy-tooltip"
                        data-tip="Payment processing services on Keypay are provided by Stripe and are
                              subject to the Stripe Connected Account Agreement, which includes the
                              Stripe Terms of Service (collectively, the “Stripe Services
                              Agreement”). By agreeing to this agreement or continuing to operate on
                              Keypay, you agree to be bound by the Stripe Services Agreement, as the
                              same may be modified by Stripe from time to time. As a condition of
                              Keypay enabling payment processing services through Stripe, you agree
                              to provide Keypay accurate and complete information about you and your
                              business, and you authorize Keypay to share it and transaction
                              information related to your use of the payment processing services
                              provided by Stripe."
                        className="text-decoration-underline text-primary">
                        Terms of Services and Privacy Policy
                      </span>
                      <ReactTooltip id="policy-tooltip" place="top" type="dark" effect="float" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <AutoSubmitForm
            companyData={companyData}
            formErrors={formErrors}
            triggerSubmit={triggerSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};

InfoForm.propTypes = {
  companyData: PropTypes.object,
  triggerSubmit: PropTypes.number,
  formErrors: PropTypes.object,
  handleSubmit: PropTypes.func,
};

InfoForm.defaultProps = {
  companyData: {},
  triggerSubmit: 0,
  formErrors: {},
  handleSubmit: () => {},
};

export default InfoForm;
