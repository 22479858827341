import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/actions/message';
import { useRouteMatch, Link } from 'react-router-dom';
import history from '_helpers/history';
import NotificationService from '_services/notificationService';
import IconComponent from 'components/IconComponent';
import SimpleModal from 'components/SimpleModal';
import TemplateInfoHead from './TemplateInfoHead';
import TemplateInfoBody from './TemplateInfoBody';
import FilterTemplates from './FilterTemplates';
import PreviewModal from '../PreviewModal';
import './NotificationsList.scss';

const notificationService = new NotificationService();

const NotificationsList = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const [activePreviewTemplateActive, setActivePreviewTemplate] = useState(null);
  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deletedTemplate, setDeletedTemplate] = useState(null);

  const [activeFilterType, setActiveFilterType] = useState(null);

  const getTemplates = () => {
    return notificationService
      .getTemplates(activeFilterType)
      .then(({ results }) => {
        setTemplates(results);
      })
      .catch();
  };
  const deleteTemplate = () => {
    return notificationService
      .deleteTemplate(deletedTemplate)
      .then(() => {
        dispatch(setMessage('success', 'Notification was successfully removed'));
        onHideDeleteModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pauseTemplate = (id) => {
    const formData = { isPaused: true };
    return notificationService
      .editTemplate(id, formData)
      .then(() => {
        dispatch(setMessage('success', 'Notification was successfully paused'));
        getTemplates();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const playTemplate = (id) => {
    const formData = { isPaused: false };
    return notificationService
      .editTemplate(id, formData)
      .then(() => {
        dispatch(setMessage('success', 'Notification was successfully played'));
        getTemplates();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const copyTemplate = (id) => {
    return notificationService
      .copyTemplate(id)
      .then(() => {
        dispatch(setMessage('success', 'Notification was successfully copied'));
        getTemplates();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilterChange = (type) => {
    if (activeFilterType !== type) {
      setActiveFilterType(type);
    }
  };

  const previewTemplate = (template) => {
    setActivePreviewTemplate(template);
    setPreviewModalShow(true);
  };

  const handlePreviewModalClose = () => {
    setActivePreviewTemplate(null);
    setPreviewModalShow(false);
  };

  const editTemplate = (id) => {
    history.push(`${path}/${id}`);
  };

  const handleDeleteTemplate = (template) => {
    setDeletedTemplate(template);
    setDeleteModalShow(true);
  };

  const onHideDeleteModal = () => {
    setDeleteModalShow(false);
    setDeletedTemplate(null);
    getTemplates();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getTemplates();
  }, [activeFilterType]);

  return (
    <div className="content-wrap">
      <div className="fieldset-holder mb-4 pb-4">
        <div className="fieldset-head">
          <h2 className="fieldset-title">turn on smart pay</h2>
          <span className="fieldset-subtitle">
            Send different messages at different times to increase engagement and try to convert
            payment.
          </span>
        </div>
        <Link className="btn btn-info" to={`${path}/create`}>
          create NEW notification
        </Link>
      </div>
      {!!templates.length && (
        <>
          <FilterTemplates onFilterChanged={handleFilterChange} enabled={!!templates.length} />
          <div className="notification-templates">
            {templates.map((template) => (
              <div className="row no-gutters template" key={template.id}>
                <div className="col-md template-head">
                  <TemplateInfoHead
                    type={template.notificationType}
                    time={template.howToSend}
                    timeMeasure={template.timeMeasure}
                    delay={template.delay}
                  />
                </div>
                <div className="col-md template-body">
                  <TemplateInfoBody
                    name={template.name}
                    body={template.body}
                    isPaused={template.isPaused}
                    type={template.notificationType}
                    time={template.howToSend}
                    handlePreviewTemplate={() => previewTemplate(template)}
                    handleDeleteTemplate={() => handleDeleteTemplate(template.id)}
                    handlePauseTemplate={() => pauseTemplate(template.id)}
                    handlePlayTemplate={() => playTemplate(template.id)}
                    handleEditTemplate={() => editTemplate(template.id)}
                    handleCopyTemplate={() => copyTemplate(template.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {previewModalShow && (
        <PreviewModal
          template={activePreviewTemplateActive}
          show={previewModalShow}
          modalSize="lg"
          onHideModal={handlePreviewModalClose}
        />
      )}
      {deleteModalShow && deletedTemplate && (
        <SimpleModal
          show={deleteModalShow}
          onHideModal={onHideDeleteModal}
          modalHeader={
            <>
              <span className="mr-3">
                <IconComponent name="infoCircle" />
              </span>
              Remove Notification
            </>
          }
          modalFooter={
            <button type="button" className="btn btn-danger rounded-pill" onClick={deleteTemplate}>
              Remove
            </button>
          }>
          <h5 className="font-weight-normal">
            Are you sure you want to remove this notification? This action cannot be undone.
          </h5>
        </SimpleModal>
      )}
    </div>
  );
};

export default NotificationsList;
