import { HIDE_COMPANY_INFO_BANNER, SHOW_COMPANY_INFO_BANNER } from './types';

export const showInfoBanner = () => (dispatch) => {
  localStorage.setItem('companyInfoBanner', 'show');
  dispatch({
    type: SHOW_COMPANY_INFO_BANNER,
  });
};

export const hideInfoBanner = () => (dispatch) => {
  localStorage.setItem('companyInfoBanner', 'hide');
  dispatch({
    type: HIDE_COMPANY_INFO_BANNER,
  });
};
