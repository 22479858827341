import React from 'react';
import InviteForm from './InviteForm';

const InviteClientPage = () => {
  return (
    <div className="container-fluid pt-3 pb-3" id="content">
      <div className="fieldset-head">
        <h2 className="fieldset-title">Invite new client</h2>
        <span className="fieldset-subtitle">Send invitation for new client using email.</span>
      </div>
      <InviteForm />
    </div>
  );
};

export default InviteClientPage;
