import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form as FormEl, FormControl, InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import { setMessage } from 'store/actions/message';
import history from '_helpers/history';
import NotificationService from '_services/notificationService';
import IconComponent from 'components/IconComponent';
import SwitcherButtons from 'components/SwitcherButtons';
import SelectField from 'components/SelectField';
import TextEditor from 'components/TextEditor';
import WildcardsInputField from 'components/WildcardsInputField';
import stripHtml from '../utils';
import PreviewModal from '../PreviewModal';
import {
  defaultFormValues,
  templateTypeButtons,
  timeMeasureDefaultValues,
  wildcardsHeaderDefaults,
  wildcardsBodyDefaults,
  wildcardsSignatureDefaults,
  wildcardsSubjectDefaults,
  wildcardsBodySmsDefaults,
  howToSendDefaultValues,
} from './defaulValues';
import './SinngleNotification.scss';

const notificationService = new NotificationService();
const SingleNotification = () => {
  const dispatch = useDispatch();
  const { templateId } = useParams();
  const [activeTemplate, setActiveTemplate] = useState(null);
  const [previewModalShow, setPreviewModalShow] = useState(false);

  const {
    values,
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
    handleChange,
    isSubmitting,
    setFieldError,
  } = useFormik({
    initialValues: defaultFormValues,
    validate: (formValues) => {
      const errorsObj = {};
      if (!formValues.name) {
        errorsObj.name = 'Template name is required';
      } else if (!!formValues.name && formValues.name.length >= 255) {
        errorsObj.name = 'Must be 255 characters or less';
      }

      if (formValues.notificationType === 'email') {
        if (!formValues.subject) {
          errorsObj.subject = 'Subject is required';
        } else if (formValues.subject.length >= 255) {
          errorsObj.subject = 'Must be 255 characters or less';
        }

        if (!stripHtml(formValues.header)) {
          errorsObj.header = 'Header is required';
        }

        if (!stripHtml(formValues.body)) {
          errorsObj.body = 'Body Text is required';
        }
      }

      if (formValues.notificationType === 'sms') {
        if (!formValues.smsBody) {
          errorsObj.smsBody = 'Body Text is required';
        }
      }

      return errorsObj;
    },
    onSubmit: (
      {
        notificationType,
        name,
        subject,
        header,
        body,
        smsBody,
        notes,
        signature,
        howToSend,
        timeMeasure,
        delay,
        includeElements,
      },
      actions,
    ) => {
      let formData = {
        notificationType,
        name,
        body: smsBody,
        howToSend,
      };
      if (howToSend === 'after') {
        formData = {
          ...formData,
          timeMeasure,
          delay,
        };
      }
      if (notificationType === 'email') {
        formData = {
          ...formData,
          subject,
          header,
          notes: stripHtml(notes) ? notes : '',
          signature: stripHtml(signature) ? signature : '',
          includeElements,
          body,
        };
      }
      if (activeTemplate) {
        notificationService
          .editTemplate(activeTemplate, formData)
          .then(() => {
            history.push('/settings/notifications');
            dispatch(setMessage('success', 'Custom template was successfully updated'));
          })
          .catch(({ statusText, data }) => {
            Object.keys(data).forEach((key) => {
              setFieldError(key, data[key].join(' '));
            });
            dispatch(setMessage('error', statusText));
            actions.setSubmitting(false);
          });
      } else {
        notificationService
          .createTemplate(formData)
          .then(() => {
            dispatch(setMessage('success', 'Custom template was successfully created'));
            history.push('/settings/notifications');
          })
          .catch(({ statusText, data }) => {
            Object.keys(data).forEach((key) => {
              setFieldError(key, data[key].join(' '));
            });
            dispatch(setMessage('error', statusText));
            actions.setSubmitting(false);
          });
      }
    },
  });

  const handlePreviewModalClose = () => {
    setPreviewModalShow(false);
  };

  const setForm = ({
    name,
    subject,
    header,
    body,
    signature,
    notes,
    notificationType,
    includeElements,
    howToSend,
    timeMeasure,
    delay,
  }) => {
    setFieldValue('name', name || '');
    setFieldValue('subject', subject || '');
    setFieldValue('header', header || '');
    setFieldValue('notes', notes || '');
    setFieldValue('signature', signature || '');
    setFieldValue('notificationType', notificationType || '');
    setFieldValue('includeElements', includeElements || []);
    setFieldValue('howToSend', howToSend || '');
    setFieldValue('timeMeasure', timeMeasure || '');
    setFieldValue('delay', delay || 0);
    if (notificationType === 'email') {
      setFieldValue('body', body || '');
    } else {
      setFieldValue('smsBody', body || '');
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleEditorUpdate = useCallback((name, html) => {
    html && setFieldValue(name, html);
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (templateId) {
      notificationService
        .getTemplatesById(templateId)
        .then((template) => {
          setActiveTemplate(template.id);
          setForm(template);
        })
        .catch(({ statusText }) => {
          dispatch(setMessage('error', statusText));
          history.push('/settings/notifications');
        });
    }
  }, [templateId]);

  return (
    <>
      <div className="content-wrap single-template">
        <form onSubmit={handleSubmit} noValidate>
          <div className="fieldset-holder">
            <div className="fieldset-head d-flex justify-content-between">
              <h2 className="fieldset-title">
                {!!activeTemplate && 'Edit'} {!activeTemplate && 'Create new'} notification
              </h2>
              <button
                className="btn btn-link btn-preview"
                type="button"
                disabled={values.notificationType === 'sms'}
                onClick={() => setPreviewModalShow(true)}>
                <IconComponent name="preview" /> preview
              </button>
            </div>
            {/** notificationType  */}
            <FormEl.Group className="mb-4">
              <SwitcherButtons
                buttons={templateTypeButtons}
                isDisabled={!!activeTemplate}
                active={values.notificationType}
                onChange={(type) => setFieldValue('notificationType', type)}
              />
            </FormEl.Group>
          </div>

          {/** templete name  */}
          <div className="fieldset-holder">
            <FormEl.Group className="mb-4" controlId="name">
              <FormEl.Label className="small-text text-uppercase">
                templete name <sup className="text-danger">*</sup>
              </FormEl.Label>
              <span className="label-subtitle">
                This is the internal name of the template and will not be displayed to the end user.
              </span>
              <FormEl.Control
                className="form-control small-text part-width"
                name="name"
                placeholder="5th day payment notification"
                onChange={handleChange}
                isInvalid={touched.name && !!errors.name}
                {...getFieldProps('name')}
              />
              <FormEl.Control.Feedback type="invalid" tooltip>
                {errors.name}
              </FormEl.Control.Feedback>
            </FormEl.Group>
          </div>

          {/** howToSend  */}
          <div className="fieldset-holder">
            <FormEl.Group className="mb-1">
              <FormEl.Label htmlFor="howToSend" className="small-text text-uppercase">
                how to send <sup className="text-danger">*</sup>
              </FormEl.Label>
              <span className="label-subtitle">
                Please note that the notification will be sent only once for an invoice with a
                pending status
              </span>
              {values.howToSend === 'immediately' ? (
                <>
                  <SelectField
                    className="small-text part-width mb-4"
                    name="howToSend"
                    value={values.howToSend}
                    onChange={(value) => setFieldValue('howToSend', value)}
                    defaultValue="immediately"
                    options={howToSendDefaultValues}
                    isDisabled
                  />
                  {!!errors.howToSend && (
                    <FormEl.Control.Feedback type="invalid" className="d-block" tooltip>
                      {errors.howToSend}
                    </FormEl.Control.Feedback>
                  )}
                </>
              ) : (
                <div className="how-send pb-3">
                  <div className="mr-2 small">Send After:</div>
                  <div className="col-box small-col">
                    <InputGroup className="mb-0">
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className={touched.delay && !!errors.delay ? 'is-invalid' : ''}>
                          <FormEl.Label className="m-0 text-uppercase" htmlFor="delay-input">
                            {values.timeMeasure}
                          </FormEl.Label>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <NumberFormat
                        allowNegative={false}
                        value={values.delay}
                        onValueChange={({ floatValue }) => {
                          setFieldValue('delay', floatValue);
                        }}
                        customInput={FormControl}
                        isInvalid={touched.delay && !!errors.delay}
                        decimalScale={0}
                        isAllowed={({ floatValue }) => floatValue > 0}
                      />
                      {!!errors.delay && (
                        <FormEl.Control.Feedback type="invalid" className="d-block" tooltip>
                          {errors.delay}
                        </FormEl.Control.Feedback>
                      )}
                    </InputGroup>
                  </div>
                  <div className="col-box">
                    <SelectField
                      className="small-text mb-0"
                      name="timeMeasure"
                      value={values.timeMeasure}
                      onChange={(value) => setFieldValue('timeMeasure', value)}
                      options={timeMeasureDefaultValues}
                      defaultValue="days"
                      isDisabled
                    />
                    {!!errors.timeMeasure && (
                      <FormEl.Control.Feedback type="invalid" className="d-block" tooltip>
                        {errors.timeMeasure}
                      </FormEl.Control.Feedback>
                    )}
                  </div>
                </div>
              )}
            </FormEl.Group>
          </div>

          {/** includeElements  */}
          {values.notificationType === 'email' && (
            <div className="fieldset-holder">
              <FormEl.Group
                className="mb-2 d-flex flex-wrap"
                role="group"
                aria-labelledby="checkbox-group">
                <div
                  aria-hidden="true"
                  className="custom-control custom-checkbox mr-5 mb-3"
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}>
                  <FormEl.Control
                    value="invoice_summary"
                    checked={values.includeElements.includes('invoice_summary')}
                    className="custom-control-input"
                    type="checkbox"
                    id="displaySummary"
                    onChange={handleChange}
                    name="includeElements"
                    isInvalid={touched.includeElements && !!errors.includeElements}
                  />
                  <label htmlFor="displaySummary" className="custom-control-label">
                    Display Invoice Summary
                  </label>
                </div>
                <div
                  aria-hidden="true"
                  className="custom-control custom-checkbox mr-5 mb-3"
                  onKeyDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}>
                  <FormEl.Control
                    value="total_amount"
                    checked={values.includeElements.includes('total_amount')}
                    className="custom-control-input"
                    type="checkbox"
                    id="displayTotalAmount"
                    onChange={handleChange}
                    name="includeElements"
                    isInvalid={touched.includeElements && !!errors.includeElements}
                  />
                  <label htmlFor="displayTotalAmount" className="custom-control-label">
                    Display Total Amout Due
                  </label>
                  {!!errors.includeElements && (
                    <FormEl.Control.Feedback type="invalid" className="d-block" tooltip>
                      {errors.includeElements}
                    </FormEl.Control.Feedback>
                  )}
                </div>
              </FormEl.Group>
            </div>
          )}

          <div className="fieldset-holder">
            {values.notificationType === 'email' && (
              <>
                {/** subject  */}
                <FormEl.Group className="mb-4" controlId="subject">
                  <FormEl.Label className="small-text text-uppercase">
                    Email subject <sup className="text-danger">*</sup>
                  </FormEl.Label>
                  <WildcardsInputField
                    className="form-control small-text part-width"
                    name="subject"
                    placeholder={defaultFormValues.subject}
                    isInvalid={touched.subject && !!errors.subject}
                    wildcards={wildcardsSubjectDefaults}
                    value={values.subject}
                    onWildcardChanged={(value) => setFieldValue('subject', value)}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.subject}
                  </FormEl.Control.Feedback>
                </FormEl.Group>

                {/** header  */}
                <FormEl.Group className="mb-4" controlId="header">
                  <FormEl.Label className="small-text text-uppercase">
                    header <sup className="text-danger">*</sup>
                  </FormEl.Label>
                  <TextEditor
                    name="header"
                    content={values.header}
                    onChange={handleEditorUpdate}
                    wildcards={wildcardsHeaderDefaults}
                    isInvalid={!!errors.header}
                  />
                  {errors.header && (
                    <FormEl.Control.Feedback
                      style={{ left: 'auto', right: 0, top: 0 }}
                      className="d-block"
                      type="invalid"
                      tooltip>
                      {errors.header}
                    </FormEl.Control.Feedback>
                  )}
                </FormEl.Group>
              </>
            )}

            {/** body  */}
            <FormEl.Group className="mb-4" controlId="body">
              <FormEl.Label className="small-text text-uppercase">
                body text <sup className="text-danger">*</sup>
              </FormEl.Label>
              {values.notificationType === 'email' && (
                <>
                  <TextEditor
                    name="body"
                    content={values.body}
                    wildcards={wildcardsBodyDefaults}
                    onChange={handleEditorUpdate}
                    isInvalid={!!errors.body}
                  />
                  {errors.body && (
                    <FormEl.Control.Feedback
                      style={{ left: 'auto', right: 0, top: 0 }}
                      className="d-block"
                      type="invalid"
                      tooltip>
                      {errors.body}
                    </FormEl.Control.Feedback>
                  )}
                </>
              )}
              {values.notificationType === 'sms' && (
                <>
                  <WildcardsInputField
                    className="form-control small-text part-width"
                    name="body"
                    as="textarea"
                    placeholder={defaultFormValues.smsBody}
                    isInvalid={touched.smsBody && !!errors.smsBody}
                    wildcards={wildcardsBodySmsDefaults}
                    value={values.smsBody}
                    style={{ minHeight: '87px' }}
                    onWildcardChanged={(value) => setFieldValue('smsBody', value)}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.smsBody}
                  </FormEl.Control.Feedback>
                </>
              )}
            </FormEl.Group>

            {values.notificationType === 'email' && (
              <>
                {/** notes  */}
                <FormEl.Group className="mb-4" controlId="notes">
                  <FormEl.Label className="small-text text-uppercase">notes</FormEl.Label>
                  <TextEditor
                    name="notes"
                    content={values.notes}
                    onChange={handleEditorUpdate}
                    isInvalid={!!errors.notes}
                  />
                  {!!errors.notes && (
                    <FormEl.Control.Feedback className="d-block" type="invalid" tooltip>
                      {errors.notes}
                    </FormEl.Control.Feedback>
                  )}
                </FormEl.Group>

                {/** signature  */}
                <FormEl.Group className="mb-4" controlId="signature">
                  <FormEl.Label className="small-text text-uppercase">signature</FormEl.Label>
                  <TextEditor
                    name="signature"
                    content={values.signature}
                    onChange={handleEditorUpdate}
                    wildcards={wildcardsSignatureDefaults}
                    isInvalid={!!errors.signature}
                  />
                  {!!errors.signature && (
                    <FormEl.Control.Feedback
                      style={{ left: 'auto', right: 0, top: 0 }}
                      className="d-block"
                      type="invalid"
                      tooltip>
                      {errors.signature}
                    </FormEl.Control.Feedback>
                  )}
                </FormEl.Group>
              </>
            )}
          </div>
        </form>
      </div>
      <div className="footer-container single-template-footer">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <button
              className="btn btn-secondary mr-2"
              type="button"
              onClick={() => history.push('/settings/notifications')}>
              <IconComponent name="arrowLeft" /> Back
            </button>
            <button
              className="btn btn-info"
              disabled={isSubmitting}
              type="button"
              onClick={handleSubmit}>
              {!activeTemplate && 'create'}
              {activeTemplate && 'SAVE CHANGES'}
            </button>
          </div>
          <div>
            <button
              className="btn btn-link btn-preview"
              type="button"
              disabled={values.notificationType === 'sms'}
              onClick={() => setPreviewModalShow(true)}>
              <IconComponent name="preview" /> preview
            </button>
          </div>
        </div>
      </div>
      {previewModalShow && (
        <PreviewModal
          template={{
            header: values.header,
            body: values.body,
            notes: values.notes,
            signature: values.signature,
            includeElements: values.includeElements,
          }}
          show={previewModalShow}
          modalSize="lg"
          onHideModal={handlePreviewModalClose}
        />
      )}
    </>
  );
};

export default SingleNotification;
