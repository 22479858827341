import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Form as FormEl, Form, FormControl, InputGroup } from 'react-bootstrap';
import LogoVertical from 'assets/images/logo_vertical.svg';

const LoginForm = (props) => {
  const { values, touched, errors, isSubmitting, handleChange, handleSubmit, userId } = props;
  return (
    <div className="form-wrap d-flex align-items-center justify-content-center">
      <div className="form-holder w-100 text-center">
        <div className="logo">
          <img src={LogoVertical} alt="KeyPay" />
        </div>
        <form noValidate onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text className={touched.email && !!errors.email ? 'is-invalid' : ''}>
                <Form.Label className="m-0" htmlFor="email-input">
                  Email
                </Form.Label>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              id="email-input"
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={touched.email && !!errors.email}
              disabled={userId}
              aria-label="email"
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.email}
            </Form.Control.Feedback>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text
                className={touched.password && !!errors.password ? 'is-invalid' : ''}>
                <Form.Label className="m-0" htmlFor="password-input">
                  Password
                </Form.Label>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              id="password-input"
              name="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              isInvalid={touched.password && !!errors.password}
              disabled={userId}
              aria-label="email"
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.password}
            </Form.Control.Feedback>
          </InputGroup>
          {userId && (
            <FormEl.Group className="mb-4" controlId="optCode">
              <Form.Label style={{ color: '#fff' }}>Two-factor authentication code</Form.Label>
              <Form.Control
                className="form-control small-text"
                name="optCode"
                value={values.optCode}
                onChange={handleChange}
                aria-label="optCode"
                isInvalid={touched.optCode && !!errors.optCode}
              />
              <FormEl.Control.Feedback type="invalid" tooltip>
                {errors.optCode}
              </FormEl.Control.Feedback>
              <div className="pt-2" style={{ fontSize: '0.65rem' }}>
                <p>Enter the code from the two-factor app on your mobile device.</p>
              </div>
            </FormEl.Group>
          )}
          <div className="d-flex justify-content-end size-4">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
          <div className="justify-content-start">
            <Button
              variant="info rounded-pill min-w"
              disabled={isSubmitting && errors}
              onClick={handleSubmit}>
              Sign in
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

export default LoginForm;
