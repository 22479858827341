import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showLoadingOverlay, hideLoadingOverlay } from 'store/actions/loadingOverlay';
import organisationService from '_services/organisationService';
import BillingForm from './BillingForm';
import CardCompletedAlert from './CardCompletedAlert';

const Payouts = () => {
  const dispatch = useDispatch();
  const [countSubmit, setCountSubmit] = useState(0);
  const [billingCompleted, setBillingCompleted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [activeCard, setActiveCard] = useState('');

  const handleBillingEdit = () => setBillingCompleted(false);
  const onBillingSubmit = () => setCountSubmit((prevState) => prevState + 1);

  const checkCardCompleted = (token) => {
    setBillingCompleted(!!token);
    if (token) {
      setActiveCard(token.last4);
    }
  };

  const updatePaymentDetails = (formData) => {
    dispatch(showLoadingOverlay());
    return organisationService
      .updateOrganisationDetails(formData)
      .then(({ token }) => {
        checkCardCompleted(token);
        dispatch(hideLoadingOverlay());
      })
      .catch((error) => {
        dispatch(hideLoadingOverlay());
        console.log(error);
      });
  };

  const getPaymentDetails = () => {
    return organisationService.getOrganisationDetails().then(({ token }) => {
      setLoaded(true);
      checkCardCompleted(token);
    });
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <>
      <div className="content-wrap">
        <div className="fieldset-holder mb-4 pb-4">
          <div className="fieldset-head">
            <h2 className="fieldset-title">Where should we send your payouts?</h2>
            <span className="fieldset-subtitle">
              Connect your bank to link your payout account instantly
            </span>
          </div>
          {loaded && billingCompleted ? (
            <CardCompletedAlert onEdit={handleBillingEdit} cardNumber={activeCard} />
          ) : (
            <BillingForm onChange={updatePaymentDetails} triggerSubmit={countSubmit} />
          )}
        </div>
      </div>
      <div className="footer-container">
        <button
          className="btn btn-info rounded-pill"
          onClick={onBillingSubmit}
          disabled={billingCompleted}
          type="button">
          save
        </button>
      </div>
    </>
  );
};

export default Payouts;
