import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Async from 'react-select/async';
import Icon from 'components/IconComponent';
import { getStyles } from './utils';
import './SelectField.scss';

const SelectField = ({
  icon,
  label,
  value,
  isInvalid,
  options,
  async,
  error,
  onChange,
  className,
  ...props
}) => {
  const stylesCustom = getStyles({ isInvalid, icon, ...props });

  const Component = async ? Async : Select;

  const handleChange = (option) => onChange(option?.value || null);
  const getValue = useCallback(() => {
    return options ? options.find((option) => option.value === value) : '';
  }, [options, value]);

  const wrapperClass = classNames({
    'is-invalid': isInvalid,
    'autocomplete-wrapper': true,
    'form-control': true,
    'p-0': true,
  });

  return (
    <Form.Group className={className}>
      {label && (
        <Form.Label
          className={props.labelClassName}
          htmlFor={`autocomplete-${label.toLowerCase()}`}>
          {label}
        </Form.Label>
      )}
      <div className={wrapperClass}>
        {icon && (
          <div className="autocomplete-icon-wrapper">
            <Icon name={icon} />
          </div>
        )}
        <Component
          components={{
            Input: (inputProps) => (
              <components.Input {...inputProps} id={`autocomplete-${label.toLowerCase()}`} />
            ),
          }}
          options={options}
          cacheOptions
          {...props}
          value={getValue()}
          onChange={handleChange}
          styles={stylesCustom}
        />
      </div>
      {isInvalid && (
        <Form.Control.Feedback type="invalid" tooltip>
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

SelectField.defaultProps = {
  small: false,
  icon: null,
  placeholder: '',
  label: '',
  onChange: () => {},
  options: [],
  async: false,
  className: '',
};

SelectField.propTypes = {
  label: PropTypes.string,
  styles: PropTypes.object,
  isInvalid: PropTypes.bool,
  small: PropTypes.bool,
  async: PropTypes.bool,
  icon: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default SelectField;
