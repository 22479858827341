import axios from 'axios';
import env from 'env';

const API_URL = env.API_URL;

export default class InvoicesService {
  createGetQuery = (params) => {
    const queryObj = {};
    if (params.filters) {
      Object.assign(queryObj, params.filters);
    }

    if (params.searchQuery) {
      queryObj.search = params.searchQuery;
    }

    if (params.sortingFields) {
      const ordering = [];
      Object.entries(params.sortingFields).forEach(([key, value]) => {
        if (value !== 'default') {
          ordering.push(value === 'up' ? `${key}` : `-${key}`);
        }
      });
      if (ordering.length) {
        queryObj.ordering = ordering.join(',');
      }
    }

    if (params.pagerItemsPerPage) {
      queryObj.pageSize = params.pagerItemsPerPage;
    }

    if (params.pagerActivePage) {
      queryObj.page = params.pagerActivePage;
    }

    if (params.filterInvoiceType) {
      queryObj.invoiceType = params.filterInvoiceType;
    }

    if (params.filterInvoiceStatus) {
      queryObj.status = params.filterInvoiceStatus;
    }

    if (params.filterInvoiceTime) {
      const { start, end } = params.filterInvoiceTime;
      queryObj.start = start;
      queryObj.end = end;
    }

    return queryObj;
  };

  getInvoices = (params) => {
    const url = `${API_URL}/api/invoices/`;
    return axios
      .get(url, { params: { ...this.createGetQuery(params) } })
      .then((response) => response.data);
  };

  getInvoicesByURL = (link) => {
    const url = `${API_URL}${link}`;
    return axios.get(url).then((response) => response.data);
  };

  getInvoiceById = (id) => {
    const url = `${API_URL}/api/invoices/${id}/`;
    return axios.get(url).then((response) => response.data);
  };

  deleteInvoice = (customer) => {
    const url = `${API_URL}/api/invoices/${customer.pk}/`;
    return axios.delete(url);
  };

  deleteMultipleInvoices = (invoices) => {
    const url = `${API_URL}/api/invoices/deletes/`;
    return axios.post(url, { ids: invoices }).then((response) => response.data);
  };

  createInvoice = (data) => {
    const url = `${API_URL}/api/invoices/`;
    return axios.post(url, data);
  };

  cancelInvoice = (id) => {
    return axios.post(`${API_URL}/api/invoices/${id}/cancel/`);
  };

  refundInvoice = (id) => {
    return axios.post(`${API_URL}/api/invoices/${id}/refund/`);
  };

  archiveInvoice = (id) => {
    return axios.patch(`${API_URL}/api/invoices/${id}/`, { isArchived: true });
  };

  updateInvoice = (pk, data) => {
    const url = `${API_URL}/api/invoices/${pk}/`;
    return axios.put(url, data);
  };

  resendInvoice = (id) => {
    return axios.post(`${API_URL}/api/invoices/${id}/resend/`);
  };
}
