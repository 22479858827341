import React from 'react';
import PropTypes from 'prop-types';
import './Wildcards.scss';

const Wildcards = ({ onChange, wildcards, title }) => {
  return (
    <div className="wildcards">
      <span className="title">{title}:</span>
      {!!wildcards.length && (
        <ul className="list-unstyled wildcards-list">
          {wildcards.map(({ label, value }) => (
            <li key={value} aria-hidden onClick={() => onChange(value)}>
              {label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Wildcards.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  wildcards: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

Wildcards.defaultProps = {
  onChange: () => {},
  title: 'wildcards',
  wildcards: [],
};

export default Wildcards;
