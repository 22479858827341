import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.scss';

const NotFoundPage = () => {
  return (
    <div className="container-fluid not-found" id="content">
      <div className="holder">
        <div className="not-found-cloud"> </div>
        <div className="not-found-text">Sorry, the page you were looking for doesn’t exist.</div>
        <Link className="btn-back" to="/">
          Go back
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
