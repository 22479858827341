import axios from 'axios';
import env from 'env';

const API_URL = env.API_URL;

export default class NotificationService {
  getTemplates = (type) => {
    const filterParams = {};
    if (type) {
      filterParams.notification_type = type;
    }
    const url = `${API_URL}/api/custom-notification-templates/`;
    return axios.get(url, { params: filterParams }).then((response) => response.data);
  };

  previewTemplatesById = (id) => {
    const url = `${API_URL}/api/custom-notification-templates/${id}/preview/`;
    return axios.get(url).then((response) => response.data);
  };

  previewTemplatesByParams = (params) => {
    const url = `${API_URL}/api/custom-notification-templates/preview/`;
    return axios.get(url, { params }).then((response) => response.data);
  };

  getTemplatesById = (id) => {
    const url = `${API_URL}/api/custom-notification-templates/${id}/`;
    return axios.get(url).then((response) => response.data);
  };

  createTemplate = (formData) => {
    const url = `${API_URL}/api/custom-notification-templates/`;
    return axios.post(url, formData).then((response) => response.data);
  };

  editTemplate = (id, formData) => {
    const url = `${API_URL}/api/custom-notification-templates/${id}/`;
    return axios.patch(url, formData).then((response) => response.data);
  };

  copyTemplate = (id) => {
    const url = `${API_URL}/api/custom-notification-templates/${id}/copy/`;
    return axios.post(url).then((response) => response.data);
  };

  deleteTemplate = (id) => {
    const url = `${API_URL}/api/custom-notification-templates/${id}/`;
    return axios.delete(url).then((response) => response.data);
  };
}
