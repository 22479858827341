import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Formik, Form } from 'formik';
import { Form as FormEl, Alert } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import paymentService from '_services/paymentService';
import AutoSubmitForm from './AutoSubmitForm';

const StripeForm = ({ triggerSubmit, onChange: sendFormData, formErrors }) => {
  const stripe = useStripe();
  const [validationErrors, setValidationErrors] = useState('');

  const validateForm = (values) => {
    setValidationErrors('');
    const errors = {};
    if (!values.accountHolderName) {
      errors.accountHolderName = 'Account Holder Name is required';
    } else if (values.accountHolderName.length >= 255) {
      errors.accountHolderName = 'Must be 255 characters or less';
    }

    if (!values.accountNumber) {
      errors.accountNumber = 'Bank Account Number is required';
    } else if (values.accountNumber.length < 8 || values.accountNumber.length > 12) {
      errors.accountNumber = 'Bank account number could be from 8-12 chars';
    }
    if (!values.routingNumber) {
      errors.routingNumber = 'Bank Roating Number is required';
    } else if (values.routingNumber.length !== 9) {
      errors.routingNumber = 'Must be 9 characters';
    }

    return errors;
  };

  const handleSubmit = async (values, actions) => {
    const { accountNumber, accountHolderName, routingNumber } = values;
    if (!stripe || !accountNumber || !accountHolderName || !routingNumber) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    /* eslint-disable */
    stripe
      .createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        account_number: accountNumber,
        account_holder_name: accountHolderName,
        routing_number: routingNumber,
        account_holder_type: 'company',
      })
      .then((result) => {
        if (result.error) {
          if (result.error.code?.includes('account_number')) {
            actions.setFieldTouched('accountNumber', true);
            actions.setFieldError('accountNumber', result.error.message);
          } else if (result.error.code?.includes('account_holder_name')) {
            actions.setFieldTouched('accountHolderName', true);
            actions.setFieldError('accountHolderName', result.error.message);
          } else if (result.error.code?.includes('routing_number')) {
            actions.setFieldTouched('routingNumber', true);
            actions.setFieldError('routingNumber', result.error.message);
          } else {
            setValidationErrors(result.error.message);
          }
        } else {
          const formData = {
            token: result.token.id,
            bankAccount: result.token.bank_account.id,
          };
          sendFormData(formData);
        }
      });
  };

  return (
    <Formik
      validate={validateForm}
      initialValues={{ accountHolderName: '', accountNumber: '', routingNumber: '' }}
      onSubmit={handleSubmit}>
      {({ handleChange, values, touched, errors }) => (
        <Form>
          <p style={{ fontSize: '0.875rem' }}>
            *For your account security, your bank account information is not stored on our servers.
            You can change where payments are deposited by updating the fields below and clicking
            save.
          </p>
          <div className="row">
            <div className="col-lg-7 col-xl-5">
              <FormEl.Group className="mb-4" controlId="accountHolderName">
                <FormEl.Label>Account holder name</FormEl.Label>
                <FormEl.Control
                  className="small-text"
                  name="accountHolderName"
                  placeholder="e.g. Jenny Rosen"
                  value={values.accountHolderName}
                  onChange={handleChange}
                  aria-label="accountHolderName"
                  isInvalid={triggerSubmit > 0 && !!errors.accountHolderName}
                />
                <FormEl.Control.Feedback type="invalid">
                  {errors.accountHolderName}
                </FormEl.Control.Feedback>
              </FormEl.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-xl-5">
              <FormEl.Group className="mb-4" controlId="accountNumber">
                <FormEl.Label>Bank account number</FormEl.Label>
                <FormEl.Control
                  className="small-text"
                  name="accountNumber"
                  placeholder="e.g. 000123456789"
                  value={values.accountNumber}
                  onChange={handleChange}
                  aria-label="accountNumber"
                  isInvalid={triggerSubmit > 0 && !!errors.accountNumber}
                />
                <FormEl.Control.Feedback type="invalid" tooltip>
                  {errors.accountNumber}
                </FormEl.Control.Feedback>
              </FormEl.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-xl-5">
              <FormEl.Group className="mb-4" controlId="routingNumber">
                <FormEl.Label>Bank routing number</FormEl.Label>
                <FormEl.Control
                  className="small-text"
                  name="routingNumber"
                  placeholder="e.g. 111000025"
                  value={values.routingNumber}
                  onChange={handleChange}
                  aria-label="routingNumber"
                  isInvalid={triggerSubmit > 0 && !!errors.routingNumber}
                />
                <FormEl.Control.Feedback type="invalid" tooltip>
                  {errors.routingNumber}
                </FormEl.Control.Feedback>
              </FormEl.Group>
            </div>
          </div>
          {validationErrors && (
            <Alert variant="danger">
              <h5>Stripe validation error</h5>
              {validationErrors}
            </Alert>
          )}
          <AutoSubmitForm formErrors={formErrors} triggerSubmit={triggerSubmit} />
        </Form>
      )}
    </Formik>
  );
};

StripeForm.propTypes = {
  triggerSubmit: PropTypes.number,
  onChange: PropTypes.func,
  formErrors: PropTypes.object,
};

StripeForm.defaultProps = {
  triggerSubmit: null,
  handleSubmit: () => {},
  formErrors: {},
};

const BillingForm = (props) => {
  const stripePromise = async () => {
    const STRIPE_KEY = await paymentService.getStripeKey();
    return loadStripe(STRIPE_KEY);
  };

  return (
    <Elements stripe={stripePromise()}>
      <StripeForm {...props} />
    </Elements>
  );
};

export default BillingForm;
