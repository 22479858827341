import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

const PaymentLine = (props) => {
  const { keyId, amount, description, remove, replace, index } = props;
  const [lineAmount, setLineAmount] = useState(amount);
  const [lineDescription, setLineDescription] = useState(description);

  const updateLine = () => {
    if (amount !== lineAmount || description !== lineDescription) {
      replace(index, { keyId, amount: lineAmount, description: lineDescription });
    }
  };
  useEffect(() => {
    updateLine();
  }, [lineAmount, lineDescription]);

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center mb-3">
        <div style={{ maxWidth: '150px', paddingRight: '5px' }}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="pr-0" id="basic-addon1">
                $
              </InputGroup.Text>
            </InputGroup.Prepend>
            <NumberFormat
              thousandSeparator=","
              decimalSeparator="."
              decimalScale={2}
              onValueChange={(values) => {
                const { floatValue } = values;
                setLineAmount(floatValue);
              }}
              value={lineAmount}
              customInput={FormControl}
            />
          </InputGroup>
        </div>
        <div className="flex-grow-1">
          <FormControl
            value={lineDescription}
            onChange={(event) => {
              setLineDescription(event.target.value);
            }}
            aria-label="Name"
            aria-describedby="basic-addon2"
            as="textarea"
            rows={1}
            placeholder="Enter your payment details here"
          />
        </div>
        <div className="d-flex justify-content-end">
          {index !== 0 && (
            <Button variant="outline-danger btn-icon ml-3" onClick={() => remove(index)}>
              <span aria-hidden="true">&times;</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

PaymentLine.propTypes = {
  index: PropTypes.number,
  remove: PropTypes.func,
  replace: PropTypes.func,
  description: PropTypes.string,
  keyId: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PaymentLine;
