import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import NumberFormat from 'react-number-format';
import './TablePagination.scss';

const TablePagination = ({
  pagerTotalItems = 0,
  pagerActivePage = 1,
  pagerName,
  pagerUpdatedCallback,
  totalText,
}) => {
  const [activePage, setActivePage] = useState(pagerActivePage);

  const [itemsPerPage, setItemsPerPage] = useState(+window.localStorage.getItem(pagerName) || 25);

  useEffect(() => {
    pagerUpdatedCallback({ activePage, itemsPerPage });
  }, [pagerUpdatedCallback, activePage, itemsPerPage]);

  const handlePagerSize = (event) => {
    const value = event.target.value;
    window.localStorage.setItem(pagerName, value);
    setItemsPerPage(+value);
    setActivePage(1);
  };

  const handleActivePage = (pageNumber) => {
    setActivePage(pageNumber);
  };

  return (
    <div className="pager mb-3">
      <div className="d-flex align-items-center justify-content-between">
        <div className="px-2 total-items">
          <p className="mb-0">
            <span style={{ fontWeight: 500 }}>{totalText}:</span>
            <NumberFormat
              value={pagerTotalItems}
              displayType="text"
              thousandSeparator=","
              renderText={(value) => <span className="pl-2">{value}</span>}
            />{' '}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-center align-self-center">
          <div className="mx-2">
            <Form.Control as="select" value={itemsPerPage} onChange={handlePagerSize} custom>
              <option>25</option>
              <option>50</option>
              <option>75</option>
              <option>100</option>
            </Form.Control>
          </div>
          <div className="mx-2">
            <Pagination
              innerClass="pagination m-0"
              itemClass="page-item"
              linkClass="page-link"
              hideDisabled
              pageRangeDisplayed={5}
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={pagerTotalItems}
              onChange={handleActivePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  pagerTotalItems: PropTypes.number,
  pagerActivePage: PropTypes.number,
  pagerName: PropTypes.string.isRequired,
  pagerUpdatedCallback: PropTypes.func,
  totalText: PropTypes.string,
};

TablePagination.defaultProps = {
  pagerTotalItems: 0,
  pagerActivePage: 1,
  pagerUpdatedCallback: () => {},
};

export default TablePagination;
