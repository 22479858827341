import React from 'react';
import './PaymentStatus.scss';
import IconComponent from 'components/IconComponent';

const PaymentCancelled = () => {
  return (
    <div className="payment-status d-flex justify-content-center flex-column align-items-center text-center">
      <div className="img-box">
        <IconComponent name="cancelled" />
      </div>
      <strong className="title text-secondary">Payment cancelled!</strong>
      <p>
        This payment has beed cancelled. Please, contact{' '}
        <a href="mailto:support@keypay.me">support@keypay.me</a> for any questions
      </p>
    </div>
  );
};

export default PaymentCancelled;
