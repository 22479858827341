import React from 'react';
import { useFormik } from 'formik';
import Modal from 'react-modal';
import { Form as FormEl, FormControl, InputGroup } from 'react-bootstrap';
import IconComponent from 'components/IconComponent';

const EditModal = ({ location, show, onHide, onSubmit }) => {
  const formik = useFormik({
    initialValues: location,
    onSubmit,
    validate: (values) => {
      const errors = {};

      if (!values.title) {
        errors.title = 'This field is required';
      }

      return errors;
    },
  });

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName="fade modal show d-block"
      className="modal-dialog modal-dialog-centered"
      aria-labelledby="contained-modal-title-vcenter"
      onRequestClose={onHide}
      isOpen={show}>
      <div className="modal-content">
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-header">
            <h3 className="modal-title text-uppercase">Location</h3>
            <button type="button" className="btn btn-cancel btn-link" onClick={onHide}>
              <IconComponent name="close" />
            </button>
          </div>
          <div className="modal-body">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={formik.touched.title && !!formik.errors.title ? 'is-invalid' : ''}>
                  <FormEl.Label className="m-0" htmlFor="title">
                    NAME
                  </FormEl.Label>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.title && !!formik.errors.title}
                aria-label="Title"
              />
              <FormEl.Control.Feedback type="invalid" tooltip>
                {formik.errors.title}
              </FormEl.Control.Feedback>
            </InputGroup>
          </div>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-light rounded-pill" onClick={onHide}>
              Close
            </button>
            <button type="submit" className="btn btn-info rounded-pill">
              Save
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default EditModal;
