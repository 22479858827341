import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Form as FormEl } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { showLoadingOverlay, hideLoadingOverlay } from 'store/actions/loadingOverlay';
import Dropzone from 'components/Dropzone';
import organisationService from '_services/organisationService';

const GeneralDetails = () => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      title: '',
      supportEmail: '',
      description: '',
      logo: '',
      logoPreview: null,
    },
    onSubmit: (values) => {
      dispatch(showLoadingOverlay());
      const data = { ...values };

      if (typeof data.logo === 'string') {
        // If logo is a string then nothing was changed
        delete data.logo;
      }

      organisationService
        .updateOrganisationDetails(data, true, true)
        .then((result) => {
          toast.success('The data has been updated successfully!');
          dispatch(hideLoadingOverlay());
          formik.setSubmitting(false);
        })
        .catch((error) => {
          if (error.status === 413) {
            toast.error(`413 : Server error`);
            return;
          }
          const errorObj = error.data;
          Object.keys(errorObj).forEach((key) => {
            toast.error(`${key} : ${errorObj[key]}`);
          });
          formik.setSubmitting(false);
          dispatch(hideLoadingOverlay());
        });
    },
    validate: (values) => {
      const errors = {};

      if (!values.title) {
        errors.title = 'This field is required!';
      }

      if (!values.supportEmail) {
        errors.supportEmail = 'This field is required!';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.supportEmail)) {
        errors.supportEmail = 'Invalid email';
      }

      if (!values.logo) {
        errors.logo = 'This field is required!';
      } else if (values.logo.size > 10240000) {
        errors.logo = 'Uploaded file must be less then 10Mb';
      }

      return errors;
    },
  });

  const getOrganisation = () => {
    return organisationService.getOrganisationDetails().then((response) => {
      response.errors?.common?.forEach((singleError) => {
        toast.error(singleError);
      });
      formik.setValues(
        {
          title: response.title,
          description: response.description,
          supportEmail: response.supportEmail,
          logo: response.logo,
          logoPreview: response.logo ? { name: response.logo, preview: response.logo } : null,
        },
        false,
      );
    });
  };

  useEffect(() => {
    getOrganisation();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="content-wrap">
        <div className="row">
          <div className="col-lg-7 col-xl-5">
            <div className="row pt-3">
              <div className="col-12">
                <FormEl.Group className="mb-4" controlId="logo">
                  <FormEl.Label>
                    Company Logo <sup className="text-danger font-weight-bold">*</sup>
                  </FormEl.Label>
                  <Dropzone
                    accept="image/*"
                    multiple={false}
                    defaultValue={formik.values.logoPreview}
                    isInvalid={!!formik.errors.logo}
                    onChange={(files) => formik.setFieldValue('logo', files[0])}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {formik.errors.logo}
                  </FormEl.Control.Feedback>
                </FormEl.Group>
              </div>
              <div className="col-lg-6">
                <FormEl.Group className="mb-4" controlId="internalCompanyName">
                  <FormEl.Label>
                    Company Name <sup className="text-danger font-weight-bold">*</sup>
                  </FormEl.Label>
                  <FormEl.Control
                    className="form-control small-text"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    aria-label="Title"
                    isInvalid={formik.touched.title && !!formik.errors.title}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {formik.errors.title}
                  </FormEl.Control.Feedback>
                </FormEl.Group>
              </div>
              <div className="col-lg-6">
                <FormEl.Group className="mb-4" controlId="companySupportEmail">
                  <FormEl.Label>
                    Company Support Email <sup className="text-danger font-weight-bold">*</sup>
                  </FormEl.Label>
                  <FormEl.Control
                    className="form-control small-text"
                    name="supportEmail"
                    value={formik.values.supportEmail}
                    onChange={formik.handleChange}
                    aria-label="Support email"
                    isInvalid={formik.touched.supportEmail && !!formik.errors.supportEmail}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {formik.errors.supportEmail}
                  </FormEl.Control.Feedback>
                </FormEl.Group>
              </div>
              <div className="col">
                <FormEl.Group className="mb-4" controlId="companyDescription">
                  <FormEl.Label>Company Description</FormEl.Label>
                  <FormEl.Control
                    style={{ minHeight: '87px' }}
                    as="textarea"
                    className="form-control small-text"
                    name="description"
                    placeholder="Add short description that describes your business"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    aria-label="Description"
                    isInvalid={formik.touched.description && !!formik.errors.description}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {formik.errors.description}
                  </FormEl.Control.Feedback>
                </FormEl.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <button className="btn btn-info rounded-pill" type="submit" disabled={formik.isSubmitting}>
          save changes
        </button>
      </div>
      <ToastContainer />
    </form>
  );
};

export default GeneralDetails;
