import axios from 'axios';
import { LOGOUT } from 'store/actions/types';

const addAuthInterceptors = (token) => {
  axios.interceptors.request.use((config) => {
    config.headers.Authorization = `Token ${token}`;
    return config;
  });
};

const setupInterceptors = (store) => {
  // Add a response interceptor
  const user = store.getState().auth.user;
  if (user && user.token) {
    addAuthInterceptors(user.token);
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch({ type: LOGOUT });
        localStorage.removeItem('user');
        window.location.reload();
      }

      if (error.response && error.response.status === 500) {
        const customError = {};
        customError.data = { toast: '500. Server error' };
        return Promise.reject(customError);
      }
      return Promise.reject(error.response);
    },
  );
};

export default {
  addAuthInterceptors,
  setupInterceptors,
};
