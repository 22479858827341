import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';
import { Formik, Form, FieldArray } from 'formik';
import { Button, Card, Form as FormEl, InputGroup } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { clearInvoiceData } from 'store/actions/parsedInvoice';
import IconComponent from 'components/IconComponent';
import InvoiceService from '_services/invoiceService';
import InvoicePaymentDetails from './InvoicePaymentDetails';
import RecipientAutocompleteField from './RecipientAutocompleteField';
import RecipientChangedAlert from './RecipientChanchedAlert';
import InvoiceFiles from './InvoiceFiles';
import PaymentAmount from './PaymentAmount';

const invoiceService = new InvoiceService();

const defaultValues = {
  sendText: true,
  sendEmail: true,
  message: '',
  name: {
    value: ' ',
    label: '',
  },
  email: {
    value: ' ',
    label: '',
  },
  phone: {
    value: ' ',
    label: '',
  },
  address: {
    value: ' ',
    label: '',
  },
  accountNumber: {
    value: '',
    label: '',
  },
  file: '',
  filename: '',
  linesAmount: '',
  pendingAmount: '',
  toPay: '',
  lines: [
    {
      keyId: uuidv4(),
      amount: '0',
      description: '',
    },
  ],
  changedRecipient: '',
};

const InvoiceDetailsModal = (props) => {
  const { show, modalSize, onHideModal, invoiceId } = props;
  const classes = 'modal-dialog modal-dialog-centered';
  const modalClasses = `${classes} modal-${modalSize}`;

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(show);
  const [activeRecipient, setActiveRecipient] = useState(null);
  const [isDifferentRecipient, setDifferentRecipient] = useState(false);

  const [editedInvoice, setEditedInvoice] = useState(null);

  const padLeadingZeros = (num, size) => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.name?.value.trim()) {
      errors.name = 'Name is required';
    } else if (values.name?.value?.length >= 255) {
      errors.name = 'Must be 255 characters or less';
    }

    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email?.value.trim()) &&
      values.email?.value.trim()
    ) {
      errors.email = 'Invalid email address';
    } else if (values.email?.value.trim() && values.email?.value.length >= 255) {
      errors.email = 'Must be 255 characters or less';
    }

    if (!values.email?.value?.trim() && !values.phone?.value?.trim()) {
      errors.email = 'Email or mobile phone is required';
      errors.phone = 'Email or mobile phone is required';
    }

    if (!values.sendText && !values.sendEmail) {
      errors.sendEmail = 'Please, choose sending options';
      errors.sendText = 'Please, choose sending options';
    }

    if (values.sendEmail && !values.email?.value) {
      errors.email = 'Please fill in for sending invoice by Email';
    }

    if (values.sendText && !values.phone?.value) {
      errors.phone = 'Please fill in for sending invoice by SMS';
    }
    if (isDifferentRecipient && !values.changedRecipient) {
      errors.changedRecipient = 'Please, choose one of the options';
    }

    return errors;
  };

  const checkEqualRecipient = (formRecipient, systemRecipient) => {
    if (!formRecipient || !systemRecipient || !systemRecipient.id) {
      return false;
    }
    let isEqual = true;
    Object.keys(formRecipient).forEach((key) => {
      if (
        formRecipient[key] === systemRecipient[key] ||
        (!formRecipient[key]?.trim() && !systemRecipient[key])
      ) {
        return;
      }
      isEqual = false;
    });
    return isEqual;
  };

  const handleCloseModal = () => {
    setEditedInvoice(null);
    onHideModal();
  };

  const handleChangeRecipient = (recipient) => {
    setActiveRecipient(recipient);
  };

  const handleFileRecipient = (recipient) => {
    if (activeRecipient?.id) {
      return;
    }
    setActiveRecipient(recipient);
  };

  const handleValidationErrors = (error, actions) => {
    const errorsData = error.data;
    if (errorsData && errorsData.recipient) {
      Object.keys(errorsData.recipient).forEach((key) => {
        actions.setFieldError(key, errorsData.recipient[key].join(''));
      });
    }
    if (errorsData.nonFieldErrors) {
      const errorText = errorsData.nonFieldErrors.join('');
      toast.error(errorText);
    }
  };

  const handleSubmit = (values, actions) => {
    if (Number(values.toPay) < 0.5) {
      toast.error('The amount must not be less than $0.5');
      return;
    }
    const {
      name: formName,
      email: formEmail,
      phone: formPhone,
      address: formAddress,
      accountNumber,
      file,
      filename,
      sendEmail,
      sendText,
      lines,
      amountToTay: linesAmount,
      message,
      toPay,
      changedRecipient,
    } = values;
    const formRecipient = {
      name: formName.value?.trim(),
      email: formEmail.value?.trim(),
      phone: formPhone.value?.trim(),
      address: formAddress.value?.trim(),
      accountNumber: accountNumber.value?.trim(),
    };
    const formData = {
      file,
      filename,
      sendEmail,
      sendText,
      lines: lines.map((line) => {
        return {
          amount: line.amount,
          description: line.description,
        };
      }),
      recipient: formRecipient,
      linesAmount,
      toPay,
      message,
    };
    if (activeRecipient?.id && changedRecipient !== 'create') {
      formData.recipient.id = activeRecipient.id;
      formData.pendingAmount = parseFloat(activeRecipient.invoiceTotal);
    }
    invoiceService
      .createInvoice(formData)
      .then(() => {
        handleCloseModal();
      })
      .catch((error) => {
        handleValidationErrors(error, actions);
      });
  };

  const getEditedInvoice = () => {
    invoiceService.getInvoiceById(invoiceId).then((response) => {
      // setCreateRecipient(false);
      const recipientInvoice = {
        ...response.recipient,
        invoiceTotal: response.pendingAmount,
      };
      const invoice = { ...response };
      invoice.lines = invoice.lines.map((line) => {
        line.keyId = uuidv4();
        return line;
      });
      setActiveRecipient(recipientInvoice);
      setEditedInvoice(response);
    });
  };

  useEffect(() => {
    if (show) setShowModal(show);
  }, [show]);

  useEffect(() => {
    if (invoiceId) {
      getEditedInvoice();
    }
  }, [invoiceId]);

  useEffect(() => {
    return () => dispatch(clearInvoiceData());
  }, [dispatch]);

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName="fade modal show d-block"
      className={modalClasses}
      aria-labelledby="contained-modal-title-vcenter"
      onRequestClose={handleCloseModal}
      isOpen={showModal}>
      <div className="modal-content">
        <Formik
          validate={validateForm}
          initialValues={defaultValues}
          onSubmit={handleSubmit}
          validateOnChange>
          {({ handleChange, values, touched, errors }) => (
            <Form noValidate autoComplete="chrome-off">
              <div className="modal-header">
                <h3 className="modal-title text-uppercase">
                  {invoiceId && (
                    <>
                      <span className="mr-3">
                        <IconComponent name="note" />
                      </span>
                      invoice{' '}
                      <span className="font-weight-normal">#{padLeadingZeros(invoiceId, 5)}</span>
                    </>
                  )}
                  {!invoiceId && (
                    <>
                      <span className="mr-3">
                        <IconComponent name="addNote" />
                      </span>
                      Create new invoice
                    </>
                  )}
                </h3>
                <Button variant="link" className="btn-cancel" onClick={handleCloseModal}>
                  <IconComponent name="close" />
                </Button>
              </div>
              <div className="modal-body">
                <div className="row no-gutters" style={{ alignItems: 'stretch' }}>
                  <div className="col-md-5">
                    <Card className="h-100">
                      <Card.Body>
                        <FormEl.Group>
                          <FormEl.Label htmlFor="name" className="text-uppercase">
                            Recipient details:
                          </FormEl.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                className={touched.name && !!errors.name ? 'is-invalid' : ''}>
                                <FormEl.Label className="m-0 text-uppercase" htmlFor="name">
                                  NAME
                                </FormEl.Label>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <RecipientAutocompleteField
                              inputId="name"
                              autoComplete="chrome-off"
                              name="name"
                              placeholder=""
                              additionalFields={['email', 'phone', 'address', 'accountNumber']}
                              value={values.name}
                              onChangeRecipient={handleChangeRecipient}
                              isDisabled={!!invoiceId}
                              isInvalid={touched.name && !!errors.name}
                            />
                            {touched.name && !!errors.name && (
                              <FormEl.Control.Feedback className="d-block" type="invalid" tooltip>
                                {errors.name}
                              </FormEl.Control.Feedback>
                            )}
                          </InputGroup>
                        </FormEl.Group>
                        <FormEl.Group>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                className={touched.email && !!errors.email ? 'is-invalid' : ''}>
                                <FormEl.Label className="m-0 text-uppercase" htmlFor="email">
                                  EMAIL
                                </FormEl.Label>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <RecipientAutocompleteField
                              inputId="email"
                              autoComplete="chrome-off"
                              name="email"
                              placeholder=""
                              additionalFields={['name', 'phone', 'address', 'accountNumber']}
                              value={values.email}
                              onChangeRecipient={handleChangeRecipient}
                              isDisabled={!!invoiceId}
                              isInvalid={touched.email && !!errors.email}
                            />
                            {touched.email && !!errors.email && (
                              <FormEl.Control.Feedback className="d-block" type="invalid" tooltip>
                                {errors.email}
                              </FormEl.Control.Feedback>
                            )}
                          </InputGroup>
                        </FormEl.Group>
                        <FormEl.Group>
                          <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                className={touched.phone && !!errors.phone ? 'is-invalid' : ''}>
                                <FormEl.Label className="m-0 text-uppercase" htmlFor="phone">
                                  mobile phone
                                </FormEl.Label>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <RecipientAutocompleteField
                              inputId="phone"
                              autoComplete="chrome-off"
                              name="phone"
                              placeholder=""
                              additionalFields={['name', 'email', 'address', 'accountNumber']}
                              value={values.phone}
                              onChangeRecipient={handleChangeRecipient}
                              isDisabled={!!invoiceId}
                              isInvalid={touched.phone && !!errors.phone}
                            />
                            {touched.phone && !!errors.phone && (
                              <FormEl.Control.Feedback className="d-block" type="invalid" tooltip>
                                {errors.phone}
                              </FormEl.Control.Feedback>
                            )}
                          </InputGroup>
                        </FormEl.Group>
                        <FormEl.Group>
                          <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                className={touched.address && !!errors.address ? 'is-invalid' : ''}>
                                <FormEl.Label className="m-0 text-uppercase" htmlFor="address">
                                  address
                                </FormEl.Label>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <RecipientAutocompleteField
                              inputId="address"
                              autoComplete="chrome-off"
                              name="address"
                              placeholder=""
                              additionalFields={['name', 'email', 'phone', 'accountNumber']}
                              value={values.address}
                              onChangeRecipient={handleChangeRecipient}
                              isDisabled={!!invoiceId}
                              isInvalid={touched.address && !!errors.address}
                            />
                            {touched.address && !!errors.address && (
                              <FormEl.Control.Feedback className="d-block" type="invalid" tooltip>
                                {errors.address}
                              </FormEl.Control.Feedback>
                            )}
                          </InputGroup>
                        </FormEl.Group>
                        <FormEl.Group>
                          <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                className={
                                  touched.accountNumber && !!errors.accountNumber
                                    ? 'is-invalid'
                                    : ''
                                }>
                                <FormEl.Label
                                  className="m-0 text-uppercase"
                                  htmlFor="accountNumber">
                                  account number
                                </FormEl.Label>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <RecipientAutocompleteField
                              inputId="accountNumber"
                              autoComplete="chrome-off"
                              name="accountNumber"
                              placeholder=""
                              additionalFields={['name', 'email', 'phone', 'address']}
                              value={values.accountNumber}
                              onChangeRecipient={handleChangeRecipient}
                              isDisabled={!!invoiceId}
                              isInvalid={touched.accountNumber && !!errors.accountNumber}
                            />
                            {touched.accountNumber && !!errors.accountNumber && (
                              <FormEl.Control.Feedback className="d-block" type="invalid" tooltip>
                                {errors.accountNumber}
                              </FormEl.Control.Feedback>
                            )}
                          </InputGroup>
                        </FormEl.Group>
                        <RecipientChangedAlert
                          activeRecipient={activeRecipient}
                          invoiceId={invoiceId}
                          onChangeRecipient={(val) => setDifferentRecipient(val)}
                        />
                        <InvoicePaymentDetails
                          amountToPay={values.amountToTay}
                          activeRecipient={activeRecipient}
                          editedInvoice={editedInvoice}
                        />
                        <FormEl.Group>
                          <FormEl.Label className="text-uppercase">Send invoice by:</FormEl.Label>
                          <div className="d-flex">
                            <div
                              aria-hidden="true"
                              className="custom-control custom-checkbox mr-4"
                              onKeyDown={(e) => e.stopPropagation()}
                              onClick={(e) => e.stopPropagation()}>
                              <FormEl.Control
                                value={values.sendEmail}
                                checked={values.sendEmail}
                                className="custom-control-input"
                                type="checkbox"
                                onChange={handleChange}
                                id="sendEmail"
                                name="sendEmail"
                                isInvalid={touched.sendEmail && !!errors.sendEmail}
                              />
                              <label htmlFor="sendEmail" className="custom-control-label">
                                Email
                              </label>
                            </div>
                            <div
                              aria-hidden="true"
                              className="custom-control custom-checkbox mr-4"
                              onKeyDown={(e) => e.stopPropagation()}
                              onClick={(e) => e.stopPropagation()}>
                              <FormEl.Control
                                value={values.sendText}
                                checked={values.sendText}
                                className="custom-control-input"
                                type="checkbox"
                                onChange={handleChange}
                                id="sendText"
                                name="sendText"
                                isInvalid={touched.sendText && !!errors.sendText}
                              />
                              <label htmlFor="sendText" className="custom-control-label">
                                SMS
                              </label>
                            </div>
                          </div>
                          {touched.sendEmail && !!errors.sendEmail && (
                            <FormEl.Control.Feedback
                              className="d-block position-static"
                              type="invalid"
                              tooltip>
                              EMAIL: {errors.sendEmail}
                            </FormEl.Control.Feedback>
                          )}
                          {touched.sendText && !!errors.sendText && (
                            <FormEl.Control.Feedback
                              className="d-block position-static"
                              type="invalid"
                              tooltip>
                              SMS: {errors.sendText}
                            </FormEl.Control.Feedback>
                          )}
                        </FormEl.Group>
                        <FormEl.Group controlId="invoiceMessage">
                          <FormEl.Label className="text-uppercase">
                            Message <span style={{ color: '#939AA3' }}>(optional)</span>
                          </FormEl.Label>
                          <FormEl.Control
                            className="small-text"
                            as="textarea"
                            name="message"
                            rows={2}
                            placeholder="Enter a message that'll be sent to the recipient along with the payment request."
                            value={values.message}
                            onChange={handleChange}
                          />
                        </FormEl.Group>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-7 pl-md-2 d-flex">
                    <Card className="card-tab-content w-100">
                      <Tabs className="modal-tabs">
                        <TabList className="nav card-header-tabs nav-tabs text-uppercase">
                          <Tab className="nav-link mr-0 cursor-pointer" selectedClassName="active">
                            payment amount
                          </Tab>
                          <Tab className="nav-link mr-0 cursor-pointer" selectedClassName="active">
                            Upload Statement
                          </Tab>
                        </TabList>
                        <TabPanel className="tab">
                          <Card.Body>
                            <FieldArray name="lines">
                              {({ remove, replace, push }) => (
                                <PaymentAmount
                                  remove={remove}
                                  replace={replace}
                                  push={push}
                                  lines={values.lines}
                                />
                              )}
                            </FieldArray>
                          </Card.Body>
                        </TabPanel>
                        <TabPanel className="tab">
                          <Card.Body
                            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <InvoiceFiles
                              onSetRecipient={handleFileRecipient}
                              file={values.file}
                              filename={values.filename}
                            />
                          </Card.Body>
                        </TabPanel>
                      </Tabs>
                    </Card>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between styled-footer">
                <Button type="submit" variant="info rounded-pill">
                  {invoiceId && 're-SEND'}
                  {!invoiceId && 'Send'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </Modal>
  );
};

InvoiceDetailsModal.propTypes = {
  show: PropTypes.bool,
  modalSize: PropTypes.string,
  onHideModal: PropTypes.func,
  invoiceId: PropTypes.number,
};

export default InvoiceDetailsModal;
