import React from 'react';
import IconComponent from 'components/IconComponent';
import './PaymentStatus.scss';

const PaymentSuccess = () => {
  return (
    <div className="payment-status d-flex justify-content-center flex-column align-items-center text-center">
      <div className="img-box">
        <IconComponent name="check" />
      </div>
      <strong className="title">Payment success!</strong>
      <p>Thanks! Your payment process has been completed successfully.</p>
    </div>
  );
};

export default PaymentSuccess;
