import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';

const DropdownDateRange = ({ handleOnChange, start, end }) => {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleOnChange(startDate, endDate);
    }
  }, [startDate, endDate, handleOnChange]);

  return (
    <div className="row no-gutters">
      <div className="col pr-2">
        <label htmlFor="startDate">From:</label>
        <DatePicker
          className="form-control"
          id="startDate"
          selected={startDate}
          onChange={handleStartDate}
          startDate={startDate}
          endDate={endDate}
          showYearDropdown
          showMonthDropdown
          selectsStart
        />
      </div>
      <div className="col">
        <label htmlFor="endDate">To:</label>
        <DatePicker
          className="form-control"
          id="endDate"
          selected={endDate}
          onChange={handleEndDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          showYearDropdown
          showMonthDropdown
          selectsEnd
        />
      </div>
    </div>
  );
};

DropdownDateRange.propTypes = {
  handleOnChange: PropTypes.func,
  start: PropTypes.instanceOf(Date),
  end: PropTypes.instanceOf(Date),
};

DropdownDateRange.defaultProps = {
  start: new Date(),
  end: new Date(),
  handleOnChange: () => {},
};

export default DropdownDateRange;
