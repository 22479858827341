import axios from 'axios';
import authHeader from '_helpers/authHeader';
import env from 'env';

const API_URL = `${env.API_URL}/api/`;

const getPublicContent = () => {
  return axios.get(`${API_URL}all`);
};

const getUserBoard = () => {
  return axios.get(`${API_URL}user/`, { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(`${API_URL}mod/`, { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(`${API_URL}admin/`, { headers: authHeader() });
};

const getUserNotifications = (force) => {
  let forceUpdate = '';
  if (force) {
    forceUpdate = '?check=1';
  }
  const url = `${API_URL}notifications/${forceUpdate}`;
  return axios.get(url).then((response) => response.data);
};

export default {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  getUserNotifications,
};
