import React from 'react';
import { Link } from 'react-router-dom';
import LogoVertical from 'assets/images/logo_vertical.svg';
import './NotAuthenticated.scss';

const NotAuthenticated = () => (
  <div className="d-flex align-items-center wrapper justify-content-center">
    <div className="box d-flex align-items-center justify-content-center">
      <div className="box-body w-100 text-center">
        <div className="logo">
          <img src={LogoVertical} alt="KeyPay" />
        </div>
        <p>
          KeyPay enables broad payment support for service businesses and is currently in closed
          beta.
        </p>
        <p>Check back soon for updates.</p>
        <div className="anchor-wrapper">
          <Link to="/login">SIGN IN</Link>
        </div>
      </div>
    </div>
  </div>
);

export default NotAuthenticated;
