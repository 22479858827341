import React from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';

const SimpleModal = (props) => {
  const {
    show,
    modalClass = 'modal-md',
    onHideModal,
    modalHeader,
    children,
    modalFooter,
    cancelText = 'Close',
    cancelClass = 'btn btn-light rounded-pill',
  } = props;
  return (
    <Modal
      ariaHideApp={false}
      overlayClassName="fade modal show d-block"
      className={classNames('modal-dialog modal-dialog-centered', modalClass)}
      aria-labelledby="contained-modal-title-vcenter"
      onRequestClose={onHideModal}
      isOpen={show}>
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title text-uppercase">{modalHeader}</h3>
          <button type="button" className="btn btn-cancel btn-link" onClick={onHideModal}>
            <IconComponent name="close" />
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer justify-content-center">
          <button type="button" className={cancelClass} onClick={onHideModal}>
            {cancelText}
          </button>
          {modalFooter}
        </div>
      </div>
    </Modal>
  );
};

SimpleModal.propTypes = {
  show: PropTypes.bool,
  modalClass: PropTypes.string,
  onHideModal: PropTypes.func,
  children: PropTypes.node,
  modalHeader: PropTypes.node,
  modalFooter: PropTypes.node,
  cancelText: PropTypes.string,
  cancelClass: PropTypes.string,
};

SimpleModal.defaultProps = {
  show: () => {},
  modalClass: 'modal-md',
  onHideModal: () => {},
  children: React.ReactNode,
  modalHeader: React.ReactNode,
  modalFooter: React.ReactNode,
  cancelText: 'Close',
  cancelClass: 'btn btn-light rounded-pill',
};

export default SimpleModal;
