import React from 'react';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';
import SimpleModal from '../../components/SimpleModal';

const ConfirmationCancelModal = (props) => {
  const { recipientName, cancelInvoice } = props;
  return (
    <SimpleModal
      cancelClass="d-none"
      modalHeader={
        <>
          <span className="mr-3">
            <IconComponent name="infoCircle" />
          </span>
          CANCEL INVOICE
        </>
      }
      modalFooter={
        <button type="button" className="btn btn-danger rounded-pill" onClick={cancelInvoice}>
          YES, CANCEL INVOICE
        </button>
      }
      {...props}>
      <h5 className="font-weight-normal">
        Are you sure you want to cancel invoice for <strong>{recipientName}</strong>?
      </h5>
    </SimpleModal>
  );
};

ConfirmationCancelModal.propTypes = {
  recipientName: PropTypes.string,
  cancelInvoice: PropTypes.func,
};

ConfirmationCancelModal.defaultProps = {
  recipientName: '',
  cancelInvoice: () => {},
};

export default ConfirmationCancelModal;
