import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useField, useFormikContext } from 'formik';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import RecipientService from '_services/recipientService';
import './RecipientAutocompleteField.scss';

const recipientService = new RecipientService();

const Input = (props) => {
  const { selectProps } = props;
  const { autoComplete } = selectProps;
  return <components.Input {...props} autoComplete={autoComplete} isHidden={false} />;
};

const Option = (props) => {
  const { data, selectProps } = props;
  const { inputValue, name: inputName } = selectProps;
  const { label: optionDataLabel } = data;

  const highlightSearch = (str) => {
    const search = inputValue.replace(/[+?^$|*()]/g, '');
    if (search && str) {
      const reg = new RegExp(search, 'gi');
      const match = reg.exec(str);
      if (match) {
        const res = str.split(match[0]);
        const b = [];
        for (let i = 0; i < res.length; i++) {
          b.push(<span key={`${inputName}-${inputValue}-${i}`}>{res[i]}</span>);
          if (i !== res.length - 1) {
            b.push(
              <span key={`${inputName}-${inputValue}-${match[0]}-${i}`} className="highlightSearch">
                {match[0]}
              </span>,
            );
          }
        }
        return b;
      }
    }
    return str;
  };
  return (
    <components.Option {...props}>
      <span className="autocomplete-main">{highlightSearch(optionDataLabel)}</span>
      {selectProps.additionalFields.map((item) => (
        <div key={uuidv4()} className="autocomplete-small">
          {data[item]}
        </div>
      ))}
      {!!selectProps.additionalSingleFields?.length &&
        selectProps.additionalSingleFields.map((item) => (
          <div key={uuidv4()} className="autocomplete-small">
            {data[item]}
          </div>
        ))}
    </components.Option>
  );
};

const RecipientAutocompleteField = ({ ...props }) => {
  const {
    value,
    className,
    isInvalid,
    additionalFields,
    additionalSingleFields,
    onChangeRecipient,
  } = props;
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [activeValue, setActiveValue] = useState('');
  const [optionList, setOptionList] = useState([]);

  const stylesCustom = {
    control: (css) => ({
      ...css,
      fontSize: className === 'small-text' ? '0.75rem' : '0.875rem',
      padding: className === 'small-text' ? '2px 4px' : css.padding,
      borderRadius: '0 8px 8px 0',
      borderColor: isInvalid ? '#ff4d4d' : '#e5e9f2',
      boxShadow: 'none',
      borderLeft: 'none',
      '&:hover': {
        borderColor: isInvalid ? '#ff4d4d' : '#e5e9f2',
      },
      ...props.styles,
    }),
    indicatorSeparator: (css) => ({
      ...css,
      width: '0',
    }),
    input: (css) => ({
      ...css,
      visibility: 'visible',
      width: '100%',
      '>div': {
        width: '100%',
      },
      input: {
        width: '100% !important',
      },
    }),
    menu: (css) => ({
      ...css,
      zIndex: 999,
    }),
    container: (css) => ({
      ...css,
      flex: '1 1 auto',
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    valueContainer: (css) => ({
      ...css,
      minWidth: '100%',
    }),
    option: (styles, state) => ({
      ...styles,
      padding: '6px 15px',
      /* eslint-disable */
      backgroundColor: state.isDisabled ? null : state.isFocused ? '#dae7ea' : null,
      '&:active': {
        backgroundColor: '#269b91',
        '.autocomplete-small': {
          color: '#fff',
        },
        '.highlightSearch': {
          color: '#ccc',
        },
      },
    }),
  };

  const handleSelectChange = (option) => {
    setFieldValue(field.name, {
      value: option.value,
      label: option.label,
    });
    setActiveValue(option.value);
    if (!option.__isNew__) {
      if (!!additionalFields?.length) {
        additionalFields.forEach((key) => {
          const activeOption = {};
          activeOption.value = option[key] ? option[key] : '';
          activeOption.label = option[key] ? option[key] : '';
          setFieldValue(key, activeOption);
        });
      }
      if (!!additionalSingleFields?.length) {
        additionalSingleFields.forEach((key) => {
          const singleValue = option[key] || '';
          setFieldValue([key], singleValue);
        });
      }
    }
    onChangeRecipient(option);
  };

  const handleInputChanged = (value, { action }) => {
    switch (action) {
      case 'input-change':
        setActiveValue(value);
        return;
      case 'menu-close':
        setFieldValue(field.name, { value: activeValue, label: activeValue });
        return;
    }
  };

  const loadOptions = ({ key, value }, callback) => {
    recipientService.getRecipients({ filters: { [key]: value } }).then(({ results }) => {
      const newResult = results.map((item) => {
        item.label = item[key];
        item.value = item[key];
        return item;
      });
      setOptionList(newResult);
      callback(newResult);
    });
  };

  useEffect(() => {
    if (value && value.value && value.value !== activeValue) {
      setActiveValue(value.value);
    }
  }, [value]);

  return (
    <AsyncSelect
      {...field}
      {...props}
      name={field.name}
      components={{ Option, Input }}
      styles={stylesCustom}
      options={optionList}
      inputValue={activeValue}
      formatCreateLabel={(i) => i}
      loadOptions={(inputValue, callback) => {
        loadOptions({ key: field.name, value: inputValue }, callback);
      }}
      noOptionsMessage={() => null}
      loadingMessage={() => null}
      onChange={(option) => handleSelectChange(option)}
      onInputChange={handleInputChanged}
      openMenuOnClick={false}
      isSearchable
      cacheOptions
    />
  );
};

export default RecipientAutocompleteField;
