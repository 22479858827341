import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/actions/message';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import InvoiceService from '_services/invoiceService';
import organisationService from '_services/organisationService';
import Search from 'components/Search';
import TablePagination from 'components/TablePagination';
import TheadSortButton from 'components/SortTable';
import MultiSelect from 'components/MultiSelect';
import DateRangeSelect from 'components/DateRangeSelect';
import CopyClipboardData from 'components/CopyClipboardData';
import SelectField from 'components/SelectField';
import IconComponent from 'components/IconComponent';
import { statusFilterOptions, periodFilterOptions } from './InvoiceOptions';
import ErrorSettingsBanner from './ErrorSettingsBanner';
import InvoiceDetailsModal from './InvoiceDetailsModal';
import ConfirmationCancelModal from './ConfirmationCancelModal';
import ConfirmationRefundModal from './ConfirmationRefundModal';
import InvoiceDeliveryStatus from './InvoiceDeliveryStatus';
import ActionButtons from './ActionButtons';
import StatusCell from './StatusCell';
import defaultSortingFields from './DefaultSortingFields';
import './InvoicesPage.scss';

const invoiceService = new InvoiceService();

const InvoicesPage = () => {
  const dispatch = useDispatch();

  const weekAgoDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
  const monthAgoDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
  const yearAgoDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
  const today = moment().format('YYYY-MM-DD');

  const [sortingFields, setSortingFields] = useState(defaultSortingFields);
  const [pagerTotalItems, setPagerTotalItems] = useState(0);
  const [pagerItemsPerPage, setPagerItemsPerPage] = useState(null);
  const [pagerActivePage, setPagerActivePage] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [activeInvoiceId, setActiveInvoiceId] = useState(null);
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalReceived, setTotalReceived] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [filterInvoiceStatus, setFilterInvoiceStatus] = useState('');
  const [filterInvoiceTimeStart, setFilterInvoiceTimeStart] = useState(monthAgoDate);
  const [filterInvoiceTimeEnd, setFilterInvoiceTimeEnd] = useState(today);
  const [settingsVerified, setSettingVerified] = useState(false);
  const [settingsErrors, setSettingErrors] = useState(false);
  const [settingsPending, setSettingPending] = useState(false);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [refundModalShow, setRefundModalShow] = useState(false);
  const [canceledInvoice, setCanceledInvoice] = useState(null);
  const [refundedInvoice, setRefundedInvoice] = useState(null);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState([
    statusFilterOptions[0],
    statusFilterOptions[1],
    statusFilterOptions[2],
  ]);

  const getInvoices = () => {
    const filterInvoiceTime = {
      start: filterInvoiceTimeStart,
      end: filterInvoiceTimeEnd,
    };
    invoiceService
      .getInvoices({
        sortingFields,
        searchQuery,
        pagerActivePage,
        pagerItemsPerPage,
        filterInvoiceStatus,
        filterInvoiceTime,
        filters: { location },
      })
      .then(
        ({
          results,
          count,
          totalReceived: received,
          totalPending: pendingTotal,
          verified,
          errors,
          pending,
        }) => {
          setInvoices(results);
          setPagerTotalItems(count);
          setTotalReceived(received);
          setTotalPending(pendingTotal);
          setSettingVerified(verified);
          setSettingErrors(!!errors.length);
          setSettingPending(!!pending.length);
        },
      );
  };

  const onSortChange = (currentSort) => {
    let nextSort;
    if (currentSort === 'down') nextSort = 'up';
    else if (currentSort === 'up') nextSort = 'default';
    else if (currentSort === 'default' || !currentSort) nextSort = 'down';
    return nextSort;
  };

  const changeSorting = (sortName) => {
    const field = { ...sortingFields };
    field[sortName] = onSortChange(sortingFields[sortName]);
    setSortingFields(field);
  };

  const onHideCancelModal = () => {
    setCancelModalShow(false);
    getInvoices();
    setCanceledInvoice(null);
  };

  const onHideRefundModal = () => {
    setRefundModalShow(false);
    getInvoices();
    setRefundedInvoice(null);
  };

  const openCancelInvoiceModal = (invoice) => {
    setCanceledInvoice(invoice);
    setCancelModalShow(true);
  };

  const openRefundInvoiceModal = (invoice) => {
    setRefundedInvoice(invoice);
    setRefundModalShow(true);
  };

  const cancelInvoice = () => {
    invoiceService
      .cancelInvoice(canceledInvoice.id)
      .then(() => {
        dispatch(setMessage('success', 'Invoice was successfully canceled'));
        onHideCancelModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refundInvoice = () => {
    invoiceService
      .refundInvoice(refundedInvoice.id)
      .then(() => {
        dispatch(setMessage('success', 'Invoice was successfully refunded'));
        onHideRefundModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const archiveInvoice = (id) => {
    invoiceService
      .archiveInvoice(id)
      .then(() => {
        dispatch(setMessage('success', 'Invoice was successfully archived'));
        getInvoices();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resendInvoice = (id) => {
    invoiceService
      .resendInvoice(id)
      .then(() => {
        dispatch(setMessage('success', 'Invoice was successfully resent'));
        getInvoices();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onHideDetailsModal = () => {
    setDetailModalShow(false);
    getInvoices();
    setActiveInvoiceId(null);
  };

  const setInvoiceStatusFilter = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
    const selectedValues = selectedOptions.map(({ value }) => value);
    setFilterInvoiceStatus(selectedValues.join(','));
  };

  const setInvoiceTimeFilter = ({ value, start, end }) => {
    switch (value) {
      case 'custom':
        setFilterInvoiceTimeStart(moment(start).format('YYYY-MM-DD'));
        setFilterInvoiceTimeEnd(moment(end).format('YYYY-MM-DD'));
        break;
      case 'month':
        setFilterInvoiceTimeStart(monthAgoDate);
        setFilterInvoiceTimeEnd(today);
        break;
      case 'week':
        setFilterInvoiceTimeStart(weekAgoDate);
        setFilterInvoiceTimeEnd(today);
        break;
      case 'year':
        setFilterInvoiceTimeStart(yearAgoDate);
        setFilterInvoiceTimeEnd(today);
        break;
      default:
        setFilterInvoiceTimeStart('');
        setFilterInvoiceTimeEnd('');
        break;
    }
  };

  const handlePagerUpdate = ({ activePage, itemsPerPage }) => {
    setPagerItemsPerPage(itemsPerPage);
    setPagerActivePage(activePage);
  };

  const handleSearch = (searchValue) => {
    if (searchQuery !== searchValue) {
      setPagerActivePage(1);
    }
    setSearchQuery(searchValue);
  };

  const openInvoiceNew = () => {
    setDetailModalShow(true);
  };

  const openInvoiceDetails = (invoice) => {
    if (invoice.status !== 'pending') {
      return;
    }
    setActiveInvoiceId(invoice.id);
    setDetailModalShow(true);
  };

  const handleLocationChange = (value) => setLocation(value);

  const getLocations = () => {
    organisationService.getLocations().then(({ results }) => setLocations(results));
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (pagerItemsPerPage) {
      getInvoices();
    }
  }, [
    searchQuery,
    pagerItemsPerPage,
    pagerActivePage,
    filterInvoiceStatus,
    filterInvoiceTimeStart,
    filterInvoiceTimeEnd,
    location,
    JSON.stringify(sortingFields),
  ]);

  useEffect(() => {
    getInvoices();
  }, []);

  const locationOptions = [
    { value: '', label: 'Show all' },
    ...locations.map((o) => ({ value: o.id, label: o.title })),
  ];

  return (
    <div className="container-fluid" id="content">
      {!settingsVerified && invoices && (
        <ErrorSettingsBanner errors={settingsErrors} pending={settingsPending} />
      )}
      {settingsVerified && (
        <>
          <div className="filters d-md-flex justify-content-between flex-wrap align-items-center">
            <div className="card invoice-info justify-content-between flex-row flex-wrap">
              <div className="info-item">
                <span className="title" style={{ fontWeight: 500 }}>
                  total recieved
                </span>
                <NumberFormat
                  value={totalReceived}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  prefix="$ "
                  renderText={(value) => <span className="value">{value}</span>}
                />{' '}
              </div>
              <div className="info-item pending">
                <span className="title">pending</span>
                <NumberFormat
                  value={totalPending}
                  displayType="text"
                  thousandSeparator=","
                  decimalSeparator="."
                  prefix="$ "
                  renderText={(value) => <span className="value">{value}</span>}
                />{' '}
              </div>
            </div>
            <button type="button" className="btn btn-info min-w" onClick={openInvoiceNew}>
              Request Payment
            </button>
          </div>
          <div className="filters-panel mt-1 mb-1">
            <div className="row d-flex justify-content-between">
              <div className="col col-12 col-md-8 d-block d-md-flex align-items-start">
                <div className="py-2 pr-1 pl-2 d-flex flex-wrap justify-content-md-end">
                  <DateRangeSelect
                    styles={{ minWidth: '190px' }}
                    defaultValue={periodFilterOptions[0]}
                    isSearchable={false}
                    title={
                      <IconComponent
                        style={{ marginBottom: '2px' }}
                        className="ml-2"
                        name="calendar"
                      />
                    }
                    options={periodFilterOptions}
                    onChange={setInvoiceTimeFilter}
                  />
                </div>
                <div className="py-2 pr-4 pl-1 d-flex flex-wrap justify-content-md-end">
                  <SelectField
                    small
                    styles={{ minWidth: '170px' }}
                    icon="location"
                    name="location"
                    className="mb-0"
                    placeholder="Location"
                    onChange={handleLocationChange}
                    options={locationOptions}
                  />
                </div>
                <div className="p-2 d-flex align-items-center" style={{ minWidth: '190px' }}>
                  <span style={{ fontSize: '0.75rem' }}>Filtered by:</span>
                  <div className="pl-2" style={{ minWidth: '210px' }}>
                    <MultiSelect
                      options={statusFilterOptions}
                      value={selectedStatus}
                      onChange={setInvoiceStatusFilter}
                      hideSelectedOptions={false}
                      isSearchable={false}
                      selectAllLabel="Show all"
                      placeholder="Select status..."
                      isClearable={false}
                      isMulti
                    />
                  </div>
                </div>
              </div>
              <div className="col col-12 col-md-4 d-block d-md-flex justify-content-between">
                <div className="p-2 flex-fill">
                  <Search handleSearch={handleSearch} />
                </div>
              </div>
            </div>
          </div>

          <Card className="table-wrapper mb-3">
            <div className="table-responsive">
              <table className="table">
                <thead key="thead">
                  <tr>
                    <th>
                      Recipient{' '}
                      <TheadSortButton
                        sortName="name"
                        sortOrder={sortingFields.name}
                        changeSortOrder={changeSorting}
                      />
                    </th>
                    <th>transaction#</th>
                    <th>sent by</th>
                    <th>
                      date created{' '}
                      <TheadSortButton
                        sortName="createdAt"
                        sortOrder={sortingFields.createdAt}
                        changeSortOrder={changeSorting}
                      />
                    </th>
                    <th>
                      date paid{' '}
                      <TheadSortButton
                        sortName="paidAt"
                        sortOrder={sortingFields.paidAt}
                        changeSortOrder={changeSorting}
                      />
                    </th>
                    <th>
                      Amount{' '}
                      <TheadSortButton
                        sortName="toPay"
                        sortOrder={sortingFields.toPay}
                        changeSortOrder={changeSorting}
                      />
                    </th>
                    <th>
                      Status{' '}
                      <TheadSortButton
                        sortName="status"
                        sortOrder={sortingFields.status}
                        changeSortOrder={changeSorting}
                      />
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice) => (
                    <tr key={invoice.id}>
                      <td className="align-middle">
                        <span style={{ fontWeight: 500 }}>
                          {invoice.recipient ? (
                            invoice.recipient.name
                          ) : (
                            <span className="text-danger">(deleted)</span>
                          )}
                        </span>
                        <span style={{ fontSize: '0.75rem' }} className="d-block text-light-gray">
                          {[invoice.recipient?.location, invoice.recipient?.accountNumber]
                            .filter(Boolean)
                            .join(' | ')}
                        </span>
                      </td>
                      <td className="align-middle">
                        {invoice.succeededPayment ? (
                          <div className="d-flex align-items-center">
                            <span
                              style={{ width: '80px' }}
                              className={
                                invoice.status === 'declined'
                                  ? 'text-danger text-truncate mr-2'
                                  : 'text-truncate mr-2'
                              }>
                              {invoice.succeededPayment.id}
                            </span>
                            <CopyClipboardData text={invoice.succeededPayment.id} />
                          </div>
                        ) : (
                          <span className="text-secondary">Unpaid</span>
                        )}
                      </td>
                      <td className="align-middle">
                        {invoice.succeededPayment?.isOutsidePayment ? (
                          invoice.succeededPayment?.isOutsidePayment && (
                            <span
                              style={{ fontSize: '0.75rem' }}
                              className="d-block text-light-gray">
                              Web Payment
                            </span>
                          )
                        ) : (
                          <InvoiceDeliveryStatus
                            sendEmail={invoice.sendEmail}
                            sendSMS={invoice.sendText}
                            emailStatus={invoice.emailDeliveryStatus}
                            smsStatus={invoice.smsDeliveryStatus}
                          />
                        )}
                      </td>
                      <td className="align-middle">
                        {moment(invoice.createdAt).format('MM-DD-YYYY')}{' '}
                      </td>
                      <td className="align-middle">
                        {invoice.succeededPayment
                          ? moment(invoice.paidAt).format('MM-DD-YYYY')
                          : '-'}{' '}
                      </td>
                      <td className="align-middle">
                        <NumberFormat
                          value={invoice.toPay}
                          displayType="text"
                          thousandSeparator=","
                          decimalSeparator="."
                          prefix="$ "
                          renderText={(value) => <span style={{ fontWeight: 500 }}>{value}</span>}
                        />
                      </td>
                      <td className="text-capitalize align-middle">
                        <StatusCell
                          paymentStatus={invoice.status}
                          isArchived={invoice.isArchived}
                          statusOverride={invoice.statusOverride}
                          paymentMethod={invoice.succeededPayment?.paymentMethod}
                        />
                      </td>
                      <td align="right" className="pr-4 align-middle">
                        <ActionButtons
                          handleInvoiceDetails={() => openInvoiceDetails(invoice)}
                          handleCancelInvoice={() => openCancelInvoiceModal(invoice)}
                          handleRefundInvoice={() => openRefundInvoiceModal(invoice)}
                          handleArchiveInvoice={() => archiveInvoice(invoice.id)}
                          handleResendInvoice={() => resendInvoice(invoice.id)}
                          isArchived={invoice.isArchived}
                          status={invoice.status}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <TablePagination
              pagerActivePage={pagerActivePage}
              pagerTotalItems={pagerTotalItems}
              pagerUpdatedCallback={handlePagerUpdate}
              totalText="Total invoices"
              pagerName="invoicesPager"
            />
          </Card>
          {detailModalShow && (
            <InvoiceDetailsModal
              invoiceId={activeInvoiceId}
              show={detailModalShow}
              modalSize="xl"
              onHideModal={onHideDetailsModal}
            />
          )}
          {cancelModalShow && canceledInvoice && (
            <ConfirmationCancelModal
              recipientName={canceledInvoice.recipient.name}
              show={cancelModalShow}
              cancelInvoice={cancelInvoice}
              onHideModal={onHideCancelModal}
            />
          )}
          {refundModalShow && refundedInvoice && (
            <ConfirmationRefundModal
              show={refundModalShow}
              onHideModal={onHideRefundModal}
              refundInvoice={refundInvoice}
              recipientName={refundedInvoice.recipient.name}
              refundAmount={refundedInvoice.toPay}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InvoicesPage;
