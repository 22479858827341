import React from 'react';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';

const TemplateInfoHead = ({ type, time, timeMeasure, delay }) => {
  return (
    <div className="card">
      <div className="card-body text-center">
        <h3 className="text-uppercase">
          {time === 'after' && `${time} ${delay} ${timeMeasure}`}
          {time === 'immediately' && `${time}`}
        </h3>
        <span className="title">send once</span>
        <span className="btn btn-primary btn-sm">
          {type === 'email' && <IconComponent name="message" />}
          {type === 'sms' && <IconComponent name="tablet" />}
          <span className="ml-2">{type}</span>
        </span>
      </div>
    </div>
  );
};

TemplateInfoHead.propTypes = {
  type: PropTypes.string,
  time: PropTypes.string,
  timeMeasure: PropTypes.string,
  delay: PropTypes.number,
};

TemplateInfoHead.defaultProps = {
  type: '',
  time: '',
  timeMeasure: '',
  delay: null,
};

export default TemplateInfoHead;
