import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl } from 'react-bootstrap';
import IconComponent from 'components/IconComponent';
import './Search.scss';

const Search = ({ handleSearch }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchValue);
  };
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div className="d-flex search-form">
        <div className="flex-fill">
          <FormControl
            name="search"
            value={searchValue}
            onChange={handleChange}
            aria-label="search"
            placeholder="Search"
          />
        </div>
        <div className="flex-shrink-1">
          <button type="button" className="btn btn-link default" onClick={handleSubmit}>
            <IconComponent name="search" alt="search" />
          </button>
        </div>
      </div>
    </Form>
  );
};

Search.propTypes = {
  handleSearch: PropTypes.func,
};

export default Search;
