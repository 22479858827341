import history from '_helpers/history';
import AuthService from '_services/authService';
import networkService from '_services/networkService';
import Role from '_helpers/role';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_TFA,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  UPDATE_USER,
} from './types';

export const register = (email, password) => (dispatch) => {
  return AuthService.register(email, password).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        (error.response && error.response.data && error.response.data.detail) ||
        error.message ||
        error.toString();
      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const login = (userData) => (dispatch) => {
  return AuthService.login(userData)
    .then((data) => {
      if (!data.token && data.twoFactorEnabled) {
        dispatch({
          type: LOGIN_TFA,
          payload: { user: data },
        });
        return;
      }
      if (data.isSuperuser) {
        data.role = Role.Admin;
      } else {
        data.role = Role.User;
      }
      localStorage.setItem('user', JSON.stringify(data));
      networkService.addAuthInterceptors(data.token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      history.push('/');
    })
    .catch((error) => {
      const message =
        (error.data && error.data.nonFieldErrors) || error.message || error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        messageType: 'error',
        message,
      });
      throw error;
    });
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const updateUser = () => (dispatch) => {
  AuthService.getAuthInfo().then((data) => {
    const updatedUser = {
      ...JSON.parse(localStorage.getItem('user')),
      ...data,
    };
    localStorage.setItem('user', JSON.stringify(updatedUser));
    dispatch({
      type: UPDATE_USER,
      payload: { user: updatedUser },
    });
  });
};
