import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions/auth';

const CheckUserActivity = () => {
  const dispatch = useDispatch();
  const handleOnIdle = () => {
    dispatch(logout());
  };
  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
    debounce: 2000,
  });
  return null;
};

export default CheckUserActivity;
