import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import UserNav from 'components/UserNav';
import NavItem from 'components/NavItem';
import NotificationsDropdown from 'components/NotificationsDropdown';

const MainNav = () => {
  return (
    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
      <Nav>
        <NavItem itemClass="mr-4 nav-item" itemLinkClass="nav-link" to="/dashboard" disabled>
          Dashboard
        </NavItem>
        <NavItem exact itemClass="mr-4 nav-item" itemLinkClass="nav-link" to="/">
          Payments
        </NavItem>
        <NavItem itemClass="mr-4 nav-item" itemLinkClass="nav-link" to="/recipients">
          Recipients
        </NavItem>
      </Nav>
      <div className="d-md-flex justify-content-between align-items-center flex-wrap">
        <div className="mr-sm-5">
          <NotificationsDropdown />
        </div>
        <UserNav />
      </div>
    </Navbar.Collapse>
  );
};

export default React.memo(MainNav);
