import {
  ADD_INVOICE_DATA,
  CLEAR_INVOICE_DATA,
  SELECT_RECIPIENT,
  SET_PARSED_ERROR,
} from 'store/actions/types';

const initialState = {
  data: null,
  isParsed: false,
  recipientSelected: false,
  parsedError: false,
};

const parsedInvoice = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INVOICE_DATA:
      return {
        ...state,
        data: action.data,
        isParsed: action.isParsed,
      };
    case CLEAR_INVOICE_DATA:
      return initialState;
    case SELECT_RECIPIENT:
      return {
        ...state,
        recipientSelected: true,
      };
    case SET_PARSED_ERROR:
      return {
        ...state,
        isParsed: true,
        parsedError: true,
      };
    default:
      return state;
  }
};

export default parsedInvoice;
