import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Form as FormEl, Button, Card, FormControl, InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import RecipientService from '_services/recipientService';

const recipientService = new RecipientService();

const RecipientDetailsModal = (props) => {
  const { show, onHandleUpdate, editedRecipient, modalSize } = props;
  const defaultValues = {
    address: '',
    phone: '',
    name: '',
    email: '',
    location: '',
    accountNumber: '',
  };

  const classes = 'modal-dialog modal-dialog-centered';
  const modalClasses = `${classes} modal-${modalSize}`;
  const [showModal, setShowModal] = useState(show);
  const [formErrors, setFormErrors] = useState('');

  const handleValidationErrors = (error, setErrors) => {
    const errorsData = error?.data;
    if (errorsData) {
      if (errorsData.nonFieldErrors) {
        const errorText = errorsData.nonFieldErrors.join('');
        setFormErrors(errorText);
        return;
      }
      setErrors(errorsData);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setFormErrors('');
    onHandleUpdate();
  };

  const editRecipient = (formData, { setSubmitting, setErrors }) => {
    return recipientService
      .updateRecipient(editedRecipient, formData)
      .then(() => {
        setSubmitting(false);
        closeModal();
      })
      .catch((error) => {
        handleValidationErrors(error, setErrors);
        setSubmitting(false);
      });
  };

  const createRecipient = (values, { setSubmitting, setErrors }) => {
    return recipientService
      .createRecipient(values)
      .then(() => {
        setSubmitting(false);
        closeModal();
      })
      .catch((error) => {
        handleValidationErrors(error, setErrors);
        setSubmitting(false);
      });
  };

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    setFieldValue,
    handleChange,
    isSubmitting,
  } = useFormik({
    initialValues: defaultValues,
    validate: (formValues) => {
      const errorsObj = {};

      if (!formValues.name) {
        errorsObj.name = 'Name is required';
      } else if (formValues.name.length >= 255) {
        errorsObj.name = 'Must be 255 characters or less';
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) && formValues.email) {
        errorsObj.email = 'Invalid email address';
      } else if (formValues.email.length >= 255) {
        errorsObj.email = 'Must be 255 characters or less';
      }

      if (!formValues.email && !formValues.phone && !formValues.address) {
        errorsObj.email = 'Email/mobile phone/address is required';
        errorsObj.phone = 'Email/mobile phone/address is required';
        errorsObj.address = 'Email/mobile phone/address is required';
      }

      return errorsObj;
    },
    onSubmit: (formValues, actions) => {
      if (editedRecipient) {
        editRecipient(formValues, actions);
        return;
      }
      createRecipient(formValues, actions);
    },
  });

  const getRecipientById = (id) => {
    recipientService.getRecipient(id).then((response) => {
      Object.keys(defaultValues).forEach((key) => {
        if (response[key]) {
          setFieldValue(key, response[key]);
        }
      });
    });
  };

  useEffect(() => {
    if (show) setShowModal(show);
  }, [show]);

  useEffect(() => {
    if (editedRecipient) getRecipientById(editedRecipient);
  }, [editedRecipient]);

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName="fade modal show d-block"
      className={modalClasses}
      aria-labelledby="contained-modal-title-vcenter"
      isOpen={showModal}
      onRequestClose={closeModal}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Recipient</h4>
          <Button variant="link" className="btn-cancel" onClick={closeModal}>
            CANCEL
          </Button>
        </div>
        <form onSubmit={handleSubmit} noValidate>
          <div className="modal-body">
            <Card>
              <Card.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text className={touched.name && !!errors.name ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="name-input">
                        NAME
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="name-input"
                    name="name"
                    onChange={handleChange}
                    isInvalid={touched.name && !!errors.name}
                    aria-label="Name"
                    {...getFieldProps('name')}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.name}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.address && !!errors.address ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="address-input">
                        ADDRESS
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="address-input"
                    name="address"
                    onChange={handleChange}
                    aria-label="Address"
                    isInvalid={touched.address && !!errors.address}
                    {...getFieldProps('address')}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.address}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.phone && !!errors.phone ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="phone-input">
                        MOBILE PHONE
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="phone-input"
                    name="phone"
                    onChange={handleChange}
                    aria-label="phone"
                    isInvalid={touched.phone && !!errors.phone}
                    {...getFieldProps('phone')}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.phone}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.email && !!errors.email ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="email-input">
                        EMAIL
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="email-input"
                    name="email"
                    onChange={handleChange}
                    aria-label="email"
                    isInvalid={touched.email && !!errors.email}
                    {...getFieldProps('email')}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.email}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.location && !!errors.location ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="location-input">
                        LOCATION
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="location-input"
                    name="location"
                    onChange={handleChange}
                    aria-label="location"
                    isInvalid={touched.location && !!errors.location}
                    {...getFieldProps('location')}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.location}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={
                        touched.accountNumber && !!errors.accountNumber ? 'is-invalid' : ''
                      }>
                      <FormEl.Label className="m-0" htmlFor="account-number-input">
                        ACCOUNT NUMBER
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="account-number-input"
                    name="accountNumber"
                    onChange={handleChange}
                    aria-label="account number"
                    isInvalid={touched.accountNumber && !!errors.accountNumber}
                    {...getFieldProps('accountNumber')}
                  />
                  <FormEl.Control.Feedback type="invalid" tooltip>
                    {errors.accountNumber}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <span className="invalid-feedback d-block">{formErrors}</span>
              </Card.Body>
            </Card>
          </div>
          <div className="modal-footer justify-content-start">
            <Button
              variant="outline-info rounded-pill min-w"
              disabled={isSubmitting && errors}
              type="submit">
              {editedRecipient && !isSubmitting && 'update recipient'}
              {!editedRecipient && !isSubmitting && 'create recipient'}
              {isSubmitting && 'Please wait...'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

RecipientDetailsModal.propTypes = {
  modalSize: PropTypes.string,
  onHandleUpdate: PropTypes.func,
  show: PropTypes.bool,
  editedRecipient: PropTypes.number,
};

export default RecipientDetailsModal;
