export const isEmptyObject = (value) => {
  return Object.keys(value).length === 0 && value.constructor === Object;
};

export const formatDate = (date) => {
  const d = new Date(date);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return [year, month, day].join('-');
};

export const formatDateByParts = (date) => {
  const d = new Date(date);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  const year = String(d.getFullYear());
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  return { month, day, year };
};

export const formatInvoiceId = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};
