import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import organisationService from '_services/organisationService';
import StripeForm from 'components/StripeForm';
import PaymentFailed from 'components/PaymentStatus/PaymentFailed';
import PaymentSuccess from 'components/PaymentStatus/PaymentSuccess';
import IconComponent from 'components/IconComponent';
import Steps from 'components/Steps';
import ContactDetailsForm from './ContactDetailsForm';
import './PaymentOutsidePage.scss';

const PaymentOutsidePage = () => {
  const defaultSteps = [
    {
      title: 'Payment Details',
      active: true,
      completed: false,
    },
    {
      title: 'Checkout',
      active: false,
      completed: false,
    },
  ];
  const { key } = useParams();
  const [steps, setSteps] = useState(defaultSteps);
  const [organisation, setOrganisation] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const getOrganisation = (uuid) => {
    organisationService
      .getOrganisationByUuid(uuid)
      .then(({ applePayEnabled, logo, googlePayEnabled, payoutsEnabled, locations, accountId }) => {
        setPaymentEnabled(payoutsEnabled);
        setPaymentFailed(!payoutsEnabled);
        setOrganisation({
          accountId,
          applePayEnabled,
          googlePayEnabled,
          logo,
          locations,
        });
      })
      .catch(() => {
        setPaymentFailed(true);
      });
  };
  const handleDetailsComplete = ({ secret, contactData }) => {
    setClientSecret(secret);
    setContactDetails(contactData);
    const updatedSteps = steps.slice();
    updatedSteps[0].active = false;
    updatedSteps[0].completed = true;
    updatedSteps[1].active = true;
    setSteps(updatedSteps);
  };
  const showToast = (level, message) => {
    toast[level](message);
  };

  useEffect(() => {
    getOrganisation(key);
    // });
  }, []);
  return (
    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100%' }}>
      {paymentFailed && <PaymentFailed />}
      {paymentSuccess && <PaymentSuccess />}
      {paymentEnabled && (
        <div style={{ maxWidth: '518px', width: '100%' }} className=" mx-auto py-3 px-3">
          <div className="card px-md-2" style={{ border: '1px solid #E5E9F2' }}>
            <div className="card-body">
              <div className="row">
                {organisation?.logo && (
                  <div
                    className="col col-md-12 px-md-1 mb-3"
                    style={{
                      width: '150px',
                      height: '40px',
                    }}>
                    <img
                      alt="Logo"
                      src={organisation?.logo}
                      style={{ maxHeight: '40px', maxWidth: '150px' }}
                    />
                  </div>
                )}
              </div>
              <div className="mb-3">
                <Steps steps={steps} />
              </div>
              {!clientSecret && (
                <ContactDetailsForm
                  organizationUuid={key}
                  locations={organisation?.locations || []}
                  onDetailsComplete={handleDetailsComplete}
                />
              )}
              {clientSecret && contactDetails?.amount && (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-3 pb-3 border-bottom">
                    <span className="label-text text-uppercase" style={{ fontSize: '0.65rem' }}>
                      total to pay
                    </span>
                    <NumberFormat
                      readOnly
                      className="static-format"
                      prefix="$"
                      thousandSeparator=","
                      decimalSeparator="."
                      value={contactDetails?.amount}
                      displayType="text"
                      renderText={(value) => (
                        <span style={{ fontSize: '1.25rem', fontWeight: 600 }}>{value}</span>
                      )}
                    />
                  </div>
                  <StripeForm
                    organisation={organisation}
                    paymentId={key}
                    recipient={contactDetails}
                    clientSecret={clientSecret}
                    applePayEnabled={organisation.applePayEnabled}
                    googlePayEnabled={organisation.googlePayEnabled}
                    showToast={showToast}
                    onSuccess={() => {
                      setPaymentSuccess(true);
                      setPaymentEnabled(false);
                    }}
                    price={contactDetails?.amount}
                  />
                  <div style={{ fontSize: '0.625rem' }} className="text-center text-muted">
                    <span className="d-block mb-3" style={{ fontWeight: 600 }}>
                      <span className="mr-2">
                        <IconComponent name="lock" />
                      </span>
                      Payments are secure and encrypted
                    </span>
                    *Your payment is processed by KeyPay and is securely processed using the best
                    encryption available through our partners at Stripe company. Click to view our
                    privacy &nbsp;
                    <span style={{ position: 'relative' }}>
                      <span
                        style={{ cursor: 'pointer' }}
                        data-event="click"
                        data-for="policy-tooltip"
                        data-tip="Payment processing services on Keypay are provided by Stripe and are
                          subject to the Stripe Connected Account Agreement, which includes the
                          Stripe Terms of Service (collectively, the “Stripe Services
                          Agreement”). By agreeing to this agreement or continuing to operate on
                          Keypay, you agree to be bound by the Stripe Services Agreement, as the
                          same may be modified by Stripe from time to time. As a condition of
                          Keypay enabling payment processing services through Stripe, you agree
                          to provide Keypay accurate and complete information about you and your
                          business, and you authorize Keypay to share it and transaction
                          information related to your use of the payment processing services
                          provided by Stripe."
                        className="text-decoration-underline">
                        policy and terms of service.
                      </span>
                      <ReactTooltip id="policy-tooltip" place="top" type="dark" effect="float" />
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentOutsidePage;
