import React, { useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import DropdownDateRange from 'components/DropdownDateRange';
import './DateRangeSelect.scss';

const Option = (props) => {
  const { data, setValue } = props;
  const { timeRange, start, end } = data;
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const onBtnClick = () => {
    setValue({
      ...data,
      start: startDate,
      end: endDate,
    });
  };

  const handleFilterPeriod = (fromDate, toDate) => {
    setStartDate(fromDate);
    setEndDate(toDate);
  };

  return (
    <div>
      {timeRange && (
        <div className="custom-date">
          <h5>Custom</h5>
          <DropdownDateRange start={startDate} end={endDate} handleOnChange={handleFilterPeriod} />
          <button
            className="btn btn-info rounded-pill btn-apply"
            type="button"
            onClick={onBtnClick}
            disabled={!startDate || !endDate}>
            apply
          </button>
        </div>
      )}
      {!timeRange && <components.Option {...props} />}
    </div>
  );
};

const Control = ({ children, ...props }) => {
  const { selectProps } = props;
  const { title } = selectProps;
  const style = { cursor: 'pointer' };

  return (
    <components.Control {...props}>
      <span style={style}>{title}</span>
      {children}
    </components.Control>
  );
};

const DateRangeSelect = (props) => {
  const { onChange, options } = props;
  const [optionsList, setOptionList] = useState(options);
  const handleChange = (event) => {
    onChange(event);
    if (event.timeRange) {
      const newOptions = options.map((option) => {
        if (option.timeRange) {
          option = event;
        }
        return option;
      });
      setOptionList(newOptions);
    }
  };
  const styles = {
    control: (css) => ({
      ...css,
      paddingLeft: '0.25rem',
      fontSize: '0.75rem',
      boxShadow: 'none',
      // borderWidth: '0 0 1px',
      borderRadius: '8px',
      borderColor: '#e5e9f2',
      '&:hover': {
        borderColor: '#e5e9f2',
      },
      ...props.styles,
    }),
    indicatorSeparator: (css) => ({
      ...css,
      width: '0',
    }),
    indicatorsContainer: (css) => ({
      ...css,
      minHeight: '40px',
      color: '#ccc',
    }),
    menuList: (css) => ({
      ...css,
      overflowY: 'visible',
    }),
    option: (css) => ({
      ...css,
      padding: '8px 15px',
    }),
  };

  return (
    <Select
      {...props}
      options={optionsList}
      components={{ Control, Option }}
      onChange={handleChange}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#269B91',
        },
      })}
      styles={styles}
    />
  );
};
DateRangeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  styles: PropTypes.object,
};

export default DateRangeSelect;
