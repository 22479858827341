export const statusFilterOptions = [
  { value: 'paid', label: 'Paid' },
  { value: 'pending', label: 'Pending' },
  { value: 'declined', label: 'Declined' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'archived', label: 'Archived' },
  { value: 'refunded', label: 'Refunded' },
];

export const periodFilterOptions = [
  { value: 'month', label: 'Last 30 days' },
  { value: 'week', label: 'Last week' },
  { value: 'year', label: 'Last year' },
  { value: 'custom', label: 'Custom', timeRange: true },
];

export const defaultPaymentMethods = {
  applePay: 'apple pay',
  googlePay: 'google pay',
  mastercard: 'mastercard',
  visa: 'visa',
  americanExpress: 'amex',
};
