import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap';
import organisationService from '_services/organisationService';
import CopyClipboardData from 'components/CopyClipboardData';
import './WebPayment.scss';

const WebPayment = () => {
  const [organisationUuid, setOrganisationUuid] = useState('');
  const location = window.location.origin;

  const getOrganisationDetails = () => {
    organisationService.getOrganisationDetails().then((response) => {
      setOrganisationUuid(response.uuid);
    });
  };

  useEffect(() => {
    getOrganisationDetails();
  }, []);

  return (
    <div className="content-wrap">
      <div style={{ maxWidth: '390px' }}>
        <div className="fieldset-holder border-0">
          <div className="fieldset-head m-0">
            <h2 className="fieldset-title">Web Payment</h2>
            <span className="fieldset-subtitle">
              You can include a link to a branded payment form on your website, in your marketing
              campaigns or on social media.{' '}
              <Link to={`/payment-form/${organisationUuid}`}>
                Click here to view the payment form.
              </Link>
            </span>
          </div>
          <FormGroup className="mb-4">
            <InputGroup className="copy-group">
              <InputGroup.Prepend>
                <InputGroup.Text>URL</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl value={`${location}/payment-form/${organisationUuid}`} readOnly />
              <span className="btn-copy">
                <CopyClipboardData text={`${location}/payment-form/${organisationUuid}`} />
              </span>
            </InputGroup>
          </FormGroup>
          <div className="fieldset-head m-0">
            <span className="fieldset-subtitle">
              You can include a link to a branded payment form on your website, in your marketing
              campaigns or on social media. Click here to view the payment form.
            </span>
          </div>
          <FormGroup className="iframe-copy copy-group position-relative">
            <FormControl
              as="textarea"
              value={`<iframe width="560" height="583" src="${location}/payment-form/${organisationUuid}" title="KeyPay payments" frameborder="0" scrolling="yes" style="width: 100%; border: none;"></iframe>`}
              readOnly
            />
            <span className="btn-copy">
              <CopyClipboardData
                text={`<iframe width="560" height="583" src="${location}/payment-form/${organisationUuid}" title="KeyPay payments" frameborder="0" scrolling="yes" style="width: 100%; border: none;"></iframe>`}
              />
            </span>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default WebPayment;
