import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Button, Card, Alert } from 'react-bootstrap';
import { Formik, Form, FieldArray } from 'formik';
import csv from 'jquery-csv';
import { v4 as uuidv4 } from 'uuid';
import RecipientService from '_services/recipientService';
import IconComponent from 'components/IconComponent';
import CsvExample from 'assets/files/recipients.csv';
import CSVLine from './CsvRecipientLine';

const recipientService = new RecipientService();

const CsvUploadModal = (props) => {
  const { show, onHandleUpdate, modalSize } = props;

  const classes = 'modal-dialog modal-dialog-centered';
  const modalClasses = `${classes} modal-${modalSize}`;

  const [lines, setLines] = useState([]);
  const [formErrors, setFormErrors] = useState('');
  const [fileError, setFileError] = useState(false);

  const defaultCsvFields = [
    'city',
    'email',
    'first name',
    'last name',
    'phone number',
    'state',
    'street',
    'zip code',
    'location',
    'account number',
  ];

  const validateForm = (values) => {
    const errors = {};

    if (!values.lines) {
      setFormErrors('Recipient is required');
    }

    values.lines.forEach((line, index) => {
      const lineErrors = {};
      if (!line.firstName) {
        lineErrors.firstName = 'First Name is required';
      } else if (line.firstName.length >= 255) {
        lineErrors.firstName = 'Must be 255 characters or less';
      }

      if (!line.lastName) {
        lineErrors.lastName = 'Last Name is required';
      } else if (line.lastName.length >= 255) {
        lineErrors.lastName = 'Must be 255 characters or less';
      }

      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(line.email) && line.email) {
        lineErrors.email = 'Invalid email address';
      }

      if (Object.keys(lineErrors).length > 0) {
        errors[index] = lineErrors;
      }
    });

    return errors;
  };

  const handleValidationErrors = (error, actions) => {
    const errorsData = error.data;
    if (errorsData) {
      actions.setErrors({ ...errorsData });
    }
  };

  const createRecipients = (values, actions) => {
    console.log(values);
    return recipientService
      .batchCreateRecipient(values.lines)
      .then(() => {
        onHandleUpdate();
        actions.setSubmitting(false);
      })
      .catch((error) => {
        handleValidationErrors(error, actions);
        actions.setSubmitting(false);
      });
  };

  const showFile = async (event) => {
    event.preventDefault();
    const reader = new FileReader();
    setFileError(false);
    reader.onload = async (e) => {
      const text = e.target.result;
      if (!text) {
        return;
      }
      try {
        const csvLines = csv.toObjects(text);
        const correctData = defaultCsvFields.filter((field) => {
          return Object.prototype.hasOwnProperty.call(csvLines[0], field);
        });
        if (defaultCsvFields.length === correctData.length) {
          const newLines = csvLines.map((line) => {
            const newLine = {};
            newLine.keyId = uuidv4();
            Object.keys(line).forEach((key) => {
              switch (key) {
                case 'city':
                  newLine.city = line[key];
                  break;
                case 'email':
                  newLine.email = line[key];
                  break;
                case 'first name':
                  newLine.firstName = line[key];
                  break;
                case 'last name':
                  newLine.lastName = line[key];
                  break;
                case 'phone number':
                  newLine.phone = line[key];
                  break;
                case 'state':
                  newLine.state = line[key];
                  break;
                case 'street':
                  newLine.street = line[key];
                  break;
                case 'zip code':
                  newLine.zipCode = line[key];
                  break;
                case 'location':
                  newLine.location = line[key];
                  break;
                case 'account number':
                  newLine.accountNumber = line[key];
                  break;
                default:
                  break;
              }
            });
            return newLine;
          });
          setLines(newLines);
        } else {
          setFileError(true);
        }
      } catch (err) {
        setFileError(true);
      }
    };
    reader.readAsText(event.target.files[0]);
  };

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName="fade modal show d-block"
      className={modalClasses}
      aria-labelledby="contained-modal-title-vcenter"
      isOpen={show}
      onRequestClose={() => onHandleUpdate()}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">New Recipients</h4>
          <Button variant="link" className="btn-cancel" onClick={() => onHandleUpdate()}>
            CANCEL
          </Button>
        </div>
        <div className="modal-body">
          <Card>
            <div className="p-3">
              <a href={CsvExample}>Download CSV file example</a>
            </div>
            {lines.length > 0 && (
              <Formik initialValues={{ lines }} onSubmit={createRecipients} validate={validateForm}>
                {({ values, errors, handleSubmit, isSubmitting }) => (
                  <Form>
                    <Card.Body className="pt-0">
                      <FieldArray name="lines">
                        {({ remove, replace }) => (
                          <div style={{ overflowX: 'auto' }}>
                            {values.lines.length > 0 &&
                              values.lines.map((line, index) => (
                                <CSVLine
                                  key={line.keyId}
                                  recipient={line}
                                  index={index}
                                  remove={remove}
                                  replace={replace}
                                  errors={errors}
                                />
                              ))}
                          </div>
                        )}
                      </FieldArray>
                      <div className="tp-1 pr-3">
                        <span className="invalid-feedback d-block mt-0">{formErrors}</span>
                      </div>
                    </Card.Body>
                    <Card.Footer className="d-flex align-items-center">
                      <div className="pr-3">
                        <button
                          type="button"
                          className="btn btn-info rounded-pill min-w"
                          disabled={isSubmitting}
                          onClick={handleSubmit}>
                          ADD RECIPIENTS
                        </button>
                      </div>
                      <div className="pt-1 pr-3">
                        <span style={{ fontWeight: 600 }}>TOTAL UPLOADED:</span> {lines.length}{' '}
                        recipients
                      </div>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            )}
            {!lines.length && (
              <Card.Body className="position-relative">
                <label
                  htmlFor="inputFile"
                  className="d-flex align-items-center justify-content-center min-vh-50 text-center m-0">
                  <input
                    id="inputFile"
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      width: '100%',
                      opacity: 0,
                    }}
                    type="file"
                    onChange={(e) => showFile(e)}
                  />
                  <span>
                    <strong
                      className="mb-2 text-center"
                      style={{
                        fontWeight: '500',
                        display: 'block',
                        margin: '0 0 20px',
                      }}>
                      Upload csv file
                    </strong>
                    <IconComponent name="csv" alt="Csv" />
                    <p style={{ fontWeight: '500', color: '#939AA3', margin: '20px 0 0' }}>
                      Drag and drop or{' '}
                      <span style={{ color: '#269B91', cursor: 'pointer' }}>click to upload</span>
                    </p>
                  </span>
                </label>
              </Card.Body>
            )}
            {fileError && (
              <div>
                <Alert variant="warning mx-3">This file is not correct!</Alert>
              </div>
            )}
          </Card>
        </div>
      </div>
    </Modal>
  );
};
CsvUploadModal.propTypes = {
  modalSize: PropTypes.string,
  onHandleUpdate: PropTypes.func,
};

export default CsvUploadModal;
