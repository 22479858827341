import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const RecipientsTable = ({
  fileRecipient,
  parsedRecipients,
  setTableRecipient,
  recipientSelected,
}) => {
  if (
    !(
      fileRecipient.name ||
      fileRecipient.email ||
      fileRecipient.phone ||
      fileRecipient.address ||
      !!parsedRecipients.length
    )
  ) {
    return null;
  }
  return (
    <div className="table-responsive mb-3" style={{ maxHeight: '500px' }}>
      <table className="table table-bordered recipients-table">
        <thead>
          <tr>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Email</strong>
            </td>
            <td>
              <strong>Phone #</strong>
            </td>
            <td>
              <strong>Address</strong>
            </td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          {(fileRecipient.name || fileRecipient.email || fileRecipient.phone) && (
            <>
              <tr>
                <td colSpan="4">
                  <strong className="text-uppercase">FETCHED DATA FROM THE PDF FILE:</strong>
                </td>
              </tr>
              <tr>
                <td>{fileRecipient.name}</td>
                <td>{fileRecipient.email}</td>
                <td>{fileRecipient.phone}</td>
                <td>{fileRecipient.address}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-info default"
                    disabled={recipientSelected}
                    onClick={() => setTableRecipient(fileRecipient)}>
                    select
                  </button>
                </td>
              </tr>
            </>
          )}
          {!!parsedRecipients.length && (
            <>
              <tr>
                <td colSpan="4">
                  <strong className="text-uppercase">Exsiting recipient:</strong>
                </td>
              </tr>
              {parsedRecipients.map((parsedRecipient) => (
                <tr key={uuidv4()}>
                  <td>{parsedRecipient.name}</td>
                  <td>{parsedRecipient.email}</td>
                  <td>{parsedRecipient.phone}</td>
                  <td>{parsedRecipient.address}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-info default"
                      disabled={recipientSelected}
                      onClick={() => setTableRecipient(parsedRecipient)}>
                      select
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecipientsTable;
