import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, path, exact = false, requiredRoles }) => {
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const userHasRequiredRole = user && requiredRoles.includes(user.role);

  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        isLoggedIn && userHasRequiredRole ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

AuthRoute.propTypes = {
  requiredRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.elementType,
};

export default AuthRoute;
