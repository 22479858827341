import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import DropdownCustomToggle from 'components/DropdownCustomToggle';
import IconComponent from 'components/IconComponent';
import NavItem from 'components/NavItem';
import { getUserNotifications } from 'store/actions/notifications';
import './NotificationsDropdown.scss';

const NotificationsDropdown = () => {
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserNotifications());
  }, [dispatch]);

  return (
    <div className="notifications">
      {!notifications?.length && <IconComponent name="notificationsEmpty" />}
      {notifications && !!notifications.length && (
        <Dropdown>
          <Dropdown.Toggle as={DropdownCustomToggle}>
            <IconComponent name="notifications" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="right">
            <div className="heading">Notifications</div>
            <Dropdown.Divider />
            <div className="notification-list">
              {notifications.map((notification) => (
                <div className="notification-item" key={notification.id}>
                  <div className="icon-box my-1">
                    <IconComponent name="infoCircle" />
                  </div>
                  <div className="text-box">
                    <div className="title">{notification.title}</div>
                    <div className="subtitle">{notification.text}</div>
                  </div>
                  {notification.url && (
                    <div className="btn-holder my-1 ml-lg-1">
                      <Dropdown.Item as="div" style={{ padding: 0 }}>
                        <NavItem itemLinkClass="btn btn-info default" to={notification.url}>
                          fix now
                        </NavItem>
                      </Dropdown.Item>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default NotificationsDropdown;
