import { SHOW_LOADING_OVERLAY, HIDE_LOADING_OVERLAY } from './types';

export const showLoadingOverlay = (message) => ({
  type: SHOW_LOADING_OVERLAY,
  message,
});

export const hideLoadingOverlay = () => ({
  type: HIDE_LOADING_OVERLAY,
});
