import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';
import Wildcards from '../Wildcards';

const TextEditor = React.memo(({ name, onChange, content, wildcards, isInvalid }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorContent, setEditorContent] = useState(null);

  const onEditorStateChange = (state) => {
    setEditorState(state);
  };

  const updateContent = (state) => {
    const html = draftToHtml(state);
    setEditorContent(html);
    onChange(name, html);
  };

  const insertCharacter = (textToInsert) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    let newContent = Modifier.replaceText(currentContent, currentSelection, textToInsert);

    const textToInsertSelection = currentSelection.set(
      'focusOffset',
      currentSelection.getFocusOffset() + textToInsert.length,
    );

    const inlineStyles = editorState.getCurrentInlineStyle();
    inlineStyles.forEach((inLineStyle) => {
      newContent = Modifier.applyInlineStyle(newContent, textToInsertSelection, inLineStyle);
    });

    let newState = EditorState.push(editorState, newContent, 'insert-characters');
    newState = EditorState.forceSelection(
      newState,
      textToInsertSelection.set(
        'anchorOffset',
        textToInsertSelection.getAnchorOffset() + textToInsert.length,
      ),
    );

    setEditorState(newState);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (editorContent !== content) {
      const contentBlock = htmlToDraft(content);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const state = EditorState.createWithContent(contentState);
      setEditorState(state);
    }
  }, [content]);

  return (
    <>
      <Editor
        editorState={editorState}
        wrapperClassName={classNames('card text-wysiwyg-editor mb-3', isInvalid ? 'has-error' : '')}
        toolbarClassName="card-header editor-toolbar"
        editorClassName="card-body editor-body"
        onEditorStateChange={onEditorStateChange}
        onContentStateChange={updateContent}
      />
      {!!wildcards && !!wildcards.length && (
        <Wildcards wildcards={wildcards} onChange={(wildcard) => insertCharacter(wildcard)} />
      )}
    </>
  );
});

TextEditor.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  content: PropTypes.string,
  wildcards: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  isInvalid: PropTypes.bool,
};

TextEditor.defaultProps = {
  name: '',
  onChange: () => {},
  content: '',
  wildcards: [],
  isInvalid: false,
};

export default TextEditor;
