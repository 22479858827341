export const defaultFormValues = {
  name: '',
  subject: 'Invoice from [COMPANY_NAME]',
  header: '<p>Invoice from [COMPANY_NAME]<p>',
  body:
    '<p>Dear [RECIPIENT_NAME],</p><p>You have received a new invoice for services provided by [COMPANY_NAME]</p>',
  smsBody:
    'Dear [RECIPIENT_NAME], You have received a new invoice for services provided by [COMPANY_NAME]. [PAY_LINK]',
  notes: '<p>Just a reminder that this statement balance is due.</p>',
  notificationType: 'email',
  signature: '<p>Best Regards,</p><p>[COMPANY_NAME]</p>',
  howToSend: 'after',
  includeElements: ['invoice_summary', 'total_amount'],
  timeMeasure: 'days',
  delay: 5,
};

export const templateTypeButtons = [
  {
    value: 'email',
    label: 'email',
    icon: 'message',
  },
  {
    value: 'sms',
    label: 'sms',
    icon: 'message',
  },
];

export const howToSendDefaultValues = [
  {
    value: 'after',
    label: 'After',
  },
  {
    value: 'immediately',
    label: 'Immediately',
  },
];

export const timeMeasureDefaultValues = [
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'hours',
    label: 'Hours',
  },
];

export const wildcardsSubjectDefaults = [
  {
    label: '[INVOICE_NUMBER]',
    value: '[INVOICE_NUMBER]',
  },
  {
    label: '[COMPANY_NAME]',
    value: '[COMPANY_NAME]',
  },
];

export const wildcardsHeaderDefaults = [
  {
    label: '[RECIPIENT_NAME]',
    value: '[RECIPIENT_NAME]',
  },
  {
    label: '[AMOUNT_DUE]',
    value: '[AMOUNT_DUE]',
  },
  {
    label: '[COMPANY_NAME]',
    value: '[COMPANY_NAME]',
  },
];

export const wildcardsBodyDefaults = [
  {
    label: '[RECIPIENT_NAME]',
    value: '[RECIPIENT_NAME]',
  },
  {
    label: '[AMOUNT_DUE]',
    value: '[AMOUNT_DUE]',
  },
  {
    label: '[COMPANY_NAME]',
    value: '[COMPANY_NAME]',
  },
  {
    label: '[PAY_LINK]',
    value: '[PAY_LINK]',
  },
];

export const wildcardsBodySmsDefaults = [
  {
    label: '[RECIPIENT_NAME]',
    value: '[RECIPIENT_NAME]',
  },
  {
    label: '[AMOUNT_DUE]',
    value: '[AMOUNT_DUE]',
  },
  {
    label: '[COMPANY_NAME]',
    value: '[COMPANY_NAME]',
  },
  {
    label: '[PAY_LINK]',
    value: '[PAY_LINK]',
  },
];

export const wildcardsSignatureDefaults = [
  {
    label: '[COMPANY_NAME]',
    value: '[COMPANY_NAME]',
  },
  {
    label: '[COMPANY_LOGO]',
    value: '[COMPANY_LOGO]',
  },
];
