import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form as FormEl, InputGroup } from 'react-bootstrap';
import { Form, FieldArray, Field } from 'formik';
import IconComponent from 'components/IconComponent';

const GeneralSettingsForm = ({
  handleChange,
  values,
  touched,
  errors,
  isSubmitting,
  organisation,
  setFieldValue,
}) => {
  useEffect(() => {
    setFieldValue('receiveEmails', organisation.receiveEmails);

    if (organisation?.paymentEmails?.length > 0) {
      setFieldValue('emails', organisation.paymentEmails);
    }
  }, [organisation, setFieldValue]);

  return (
    <Form>
      <div className="content-wrap">
        <div className="fieldset-holder">
          <div className="fieldset-head">
            <h2 className="fieldset-title">General settings</h2>
            <span className="fieldset-subtitle">Managing the internal settings of the service</span>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="custom-control custom-checkbox mb-2"
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}>
          <FormEl.Control
            value={values.receiveEmails}
            checked={values.receiveEmails}
            className="custom-control-input"
            type="checkbox"
            onChange={handleChange}
            id="receiveEmails"
            name="receiveEmails"
          />
          <label htmlFor="receiveEmails" className="custom-control-label">
            Recieve emails about paid transactions
          </label>
        </div>
        <span className="fieldset-subtitle">
          Receive emails about successful payment.
          <br />
          Add one or more emails which will get email notifictions
        </span>

        <FieldArray
          name="emails"
          render={(arrayHelpers) => (
            <div className="d-flex flex-column mt-4 emails-list-container">
              {values.emails.map((email, index) => {
                const isFirstItem = index === 0;
                const isLastItem = index === values.emails.length - 1;

                return (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <div key={index} className="d-inline-flex align-items-center mb-4">
                    <Field name={`emails.${index}`}>
                      {({ field }) => (
                        <>
                          <InputGroup style={{ maxWidth: '325px' }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text
                                className={
                                  touched.emails && touched.emails[index] && !!errors[field.name]
                                    ? 'is-invalid'
                                    : ''
                                }>
                                <FormEl.Label className="m-0" htmlFor={field.name}>
                                  EMAIL
                                </FormEl.Label>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormEl.Control
                              id={field.name}
                              type="email"
                              style={{ borderRight: 0, borderRadius: 0 }}
                              isInvalid={
                                touched.emails && touched.emails[index] && !!errors[field.name]
                              }
                              {...field}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text
                                className={
                                  touched.emails && touched.emails[index] && !!errors[field.name]
                                    ? 'is-invalid'
                                    : ''
                                }>
                                {(!isFirstItem || values.emails.length > 1) && (
                                  <IconComponent
                                    name="remove"
                                    className="remove-email cursor-pointer"
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        </>
                      )}
                    </Field>
                    {isLastItem && values.emails.length <= 3 && (
                      <IconComponent
                        name="plus"
                        className="cursor-pointer ml-2"
                        onClick={() => arrayHelpers.push('')}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        />
      </div>
      <div className="footer-container">
        <button className="btn btn-info rounded-pill" type="submit" disabled={isSubmitting}>
          save changes
        </button>
      </div>
    </Form>
  );
};

GeneralSettingsForm.propTypes = {
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  organisation: PropTypes.object,
};

export default GeneralSettingsForm;
