import React from 'react';
import './Spinner.scss';

const Spinner = () => (
  <span>
    <span className="dot dot-1" />
    <span className="dot dot-2" />
    <span className="dot dot-3" />
  </span>
);

export default Spinner;
