import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

const CSVLine = (props) => {
  const defaultValues = {
    city: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    state: '',
    street: '',
    zipCode: '',
    location: '',
    accountNumber: '',
  };
  const { recipient, index, errors, remove, replace } = props;
  const [line, setLine] = useState(defaultValues);
  const {
    city,
    email,
    firstName,
    lastName,
    phone,
    state,
    street,
    zipCode,
    location,
    accountNumber,
  } = line;

  const handleChange = (key, value) => {
    const recipientLine = { ...line };
    recipientLine[key] = value;
    setLine(recipientLine);
  };

  const updateChanges = () => {
    if (!recipient) {
      return;
    }

    if (
      city !== recipient.city ||
      email !== recipient.email ||
      firstName !== recipient.firstName ||
      lastName !== recipient.lastName ||
      phone !== recipient.phone ||
      state !== recipient.state ||
      street !== recipient.street ||
      zipCode !== recipient.zipCode ||
      location !== recipient.location ||
      accountNumber !== recipient.accountNumber
    ) {
      replace(index, line);
    }
  };

  useEffect(() => {
    if (recipient && JSON.stringify(recipient) !== JSON.stringify(line)) {
      setLine(recipient);
    }
  }, [recipient]);

  return (
    <div style={{ overflow: 'hidden', minWidth: '1024px' }}>
      <div
        className="card mb-2"
        style={
          errors && errors[index] && errors[index].nonFieldErrors ? { borderColor: '#f00' } : {}
        }>
        <div className="card-body position-relative">
          <div className="row">
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`firstName-${index}`}>
                  First Name
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`firstName-${index}`}
                  name="firstName"
                  value={firstName}
                  isInvalid={errors && errors[index] && errors[index].firstName}
                  onChange={(event) => handleChange('firstName', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].firstName && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].firstName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`lastName-${index}`}>
                  Last Name
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`lastName-${index}`}
                  name="lastName"
                  value={lastName}
                  isInvalid={errors && errors[index] && errors[index].lastName}
                  onChange={(event) => handleChange('lastName', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].lastName && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].lastName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1 mb-3">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`email-${index}`}>
                  Email
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`email-${index}`}
                  name="email"
                  value={email}
                  isInvalid={errors && errors[index] && errors[index].email}
                  onChange={(event) => handleChange('email', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].email && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1 mb-3">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`phone-${index}`}>
                  Phone Number
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`phone-${index}`}
                  name="phone"
                  value={phone}
                  isInvalid={errors && errors[index] && errors[index].phone}
                  onChange={(event) => handleChange('phone', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].phone && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].phone}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`city-${index}`}>
                  City
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`city-${index}`}
                  name="city"
                  value={city}
                  isInvalid={errors && errors[index] && errors[index].city}
                  onChange={(event) => handleChange('city', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].city && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].city}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`state-${index}`}>
                  State
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`state-${index}`}
                  name="state"
                  value={state}
                  isInvalid={errors && errors[index] && errors[index].state}
                  onChange={(event) => handleChange('state', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].state && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].state}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`street-${index}`}>
                  Street
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`street-${index}`}
                  name="street"
                  value={street}
                  isInvalid={errors && errors[index] && errors[index].street}
                  onChange={(event) => handleChange('street', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].street && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].street}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`zipCode-${index}`}>
                  Zip Code
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`zipCode-${index}`}
                  name="zipCode"
                  value={zipCode}
                  isInvalid={errors && errors[index] && errors[index].zipCode}
                  onChange={(event) => handleChange('zipCode', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].zipCode && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].zipCode}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`location-${index}`}>
                  Location
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`location-${index}`}
                  name="location"
                  value={location}
                  isInvalid={errors && errors[index] && errors[index].location}
                  onChange={(event) => handleChange('location', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].location && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].location}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
            <div className="col px-md-1">
              <Form.Group className="m-0">
                <Form.Label className="m-0 small-text" htmlFor={`accountNumber-${index}`}>
                  Account number
                </Form.Label>
                <Form.Control
                  className="small-text"
                  type="text"
                  id={`accountNumber-${index}`}
                  name="accountNumber"
                  value={accountNumber}
                  isInvalid={errors && errors[index] && errors[index].accountNumber}
                  onChange={(event) => handleChange('accountNumber', event.target.value)}
                  onBlur={updateChanges}
                />
                {errors && errors[index] && errors[index].accountNumber && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors[index].accountNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <Button variant="outline-danger btn-icon" onClick={() => remove(index)}>
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>
          {errors && errors[index] && errors[index].nonFieldErrors && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {errors[index].nonFieldErrors.join(' ')}
            </Form.Control.Feedback>
          )}
        </div>
      </div>
    </div>
  );
};

CSVLine.propTypes = {
  errors: PropTypes.shape({
    city: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
    zipCode: PropTypes.string,
    nonFieldErrors: PropTypes.string,
  }),
};

export default CSVLine;
