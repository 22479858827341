import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { showLoadingOverlay, hideLoadingOverlay } from 'store/actions/loadingOverlay';
import Icon from 'components/IconComponent';
import organisationService from '_services/organisationService';

const defaultPaymentMethods = [
  {
    type: 'applePayEnabled',
    icon: <Icon name="applePay" />,
    enabled: false,
  },
  {
    type: 'googlePayEnabled',
    icon: <Icon name="googlePay" />,
    enabled: false,
  },
];

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const [paymentMethods, setPaymentMethods] = useState(defaultPaymentMethods);

  const updatePaymentMethods = (applePay, googlePay) => {
    const updatedPaymentMethods = paymentMethods.map((method) => {
      if (method.type === 'applePayEnabled') {
        method.enabled = applePay;
      }
      if (method.type === 'googlePayEnabled') {
        method.enabled = googlePay;
      }
      return method;
    });
    setPaymentMethods(updatedPaymentMethods);
  };

  const updatePaymentDetails = (data) => {
    dispatch(showLoadingOverlay());
    return organisationService
      .updateOrganisationDetails(data, false, true)
      .then(({ applePayEnabled, googlePayEnabled }) => {
        updatePaymentMethods(applePayEnabled, googlePayEnabled);
        dispatch(hideLoadingOverlay());
      })
      .catch((error) => {
        dispatch(hideLoadingOverlay());
        console.log(error);
      });
  };

  const getPaymentDetails = () => {
    return organisationService
      .getOrganisationDetails()
      .then(({ applePayEnabled, googlePayEnabled }) => {
        updatePaymentMethods(applePayEnabled, googlePayEnabled);
      });
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <div className="content-wrap">
      <div className="fieldset-holder border-0">
        <div className="fieldset-head">
          <h2 className="fieldset-title">Connect payment methods</h2>
          <span className="fieldset-subtitle">
            Connect your payment services. This payment options will be displayed in your invoives
            that you send via email or sms.
          </span>
        </div>
        <div className="row">
          {paymentMethods.map(({ enabled, type, icon }) => (
            <div className="col col-md-auto px-md-1 mb-3" key={type}>
              <div className="card payment-type-card">
                <div className="card-body d-flex justify-content-center align-items-center">
                  <div className={enabled ? '' : 'disabled'}>{icon}</div>
                </div>
                <div className="card-footer d-flex justify-content-end py-1">
                  <button
                    type="button"
                    className={enabled ? 'link text-danger' : 'link'}
                    onClick={() => updatePaymentDetails({ [type]: !enabled })}>
                    {enabled ? 'Disable' : 'Enable'}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

PaymentMethods.defaultProps = {
  paymentMethods: [],
};

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.array,
};

export default PaymentMethods;
