import axios from 'axios';
import env from 'env';

const API_URL = env.API_URL;

export default class FileService {
  parseFile = (file) => {
    const url = `${API_URL}/api/parse/${file}/`;
    return axios
      .get(url)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${API_URL}/api/upload/${encodeURIComponent(file.name)}/`;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios
      .post(url, formData, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };

  getFileUrl = (name) => {
    return { url: `${API_URL}/api/upload/${name}/` };
  };
}
