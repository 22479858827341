import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import * as QrCode from 'qrcode.react';
import { Formik, Form } from 'formik';
import { Button, Form as FormEl, InputGroup } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import AuthService from '_services/authService';
import './Account.scss';

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Please Enter your password'),
  password: Yup.string()
    .required('Please Enter your password')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: Yup.string()
    .required('Please confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const Account = () => {
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [configUrl, setConfigUrl] = useState(null);
  const [secret, setSecret] = useState(null);

  const handleValidationErrors = (error, actions) => {
    const errorsData = error.data;
    if (errorsData) {
      actions.setErrors(errorsData);
      if (errorsData.nonFieldErrors) {
        const errorText = errorsData.nonFieldErrors.join('');
        toast.error(errorText);
      }
    }
  };

  const changeTwoFactor = () => {
    return AuthService.changeTwoFactor({ enable: !twoFactorEnabled })
      .then(({ twoFactorEnabled: isEnabled, secret: code, configUrl: imageUrl }) => {
        setTwoFactorEnabled(isEnabled);
        setSecret(code);
        setConfigUrl(imageUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkTwoFactor = () => {
    return AuthService.checkTwoFactor()
      .then(({ twoFactorEnabled: isEnabled, secret: code, configUrl: imageUrl }) => {
        setTwoFactorEnabled(isEnabled);
        setSecret(code);
        setConfigUrl(imageUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    return AuthService.changePassword(values)
      .then(() => {
        toast.success('Password was successfully changed');
        actions.resetForm();
      })
      .catch((error) => {
        handleValidationErrors(error, actions);
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    checkTwoFactor();
  }, []);

  return (
    <>
      <div className="content-wrap">
        <div className="fieldset-holder pb-4">
          <div className="fieldset-head">
            <h2 className="fieldset-title">Account password</h2>
          </div>
          <Formik
            initialValues={{ oldPassword: '', password: '', confirmPassword: '' }}
            validationSchema={ChangePasswordSchema}
            onSubmit={handleSubmit}
            validateOnChange>
            {({ values, touched, handleChange, errors, isSubmitting }) => (
              <Form>
                <InputGroup className="mb-2" style={{ maxWidth: '325px' }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.oldPassword && !!errors.oldPassword ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="oldPassword-input">
                        OLD PASSWORD
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormEl.Control
                    id="oldPassword-input"
                    name="oldPassword"
                    type="password"
                    value={values.oldPassword}
                    onChange={handleChange}
                    isInvalid={touched.oldPassword && !!errors.oldPassword}
                    aria-label="oldPassword"
                  />
                  <FormEl.Control.Feedback type="invalid">
                    {errors.oldPassword}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-2" style={{ maxWidth: '325px' }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={touched.password && !!errors.password ? 'is-invalid' : ''}>
                      <FormEl.Label className="m-0" htmlFor="password-input">
                        PASSWORD
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormEl.Control
                    id="password-input"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={touched.password && !!errors.password}
                    aria-label="email"
                  />
                  <FormEl.Control.Feedback type="invalid">
                    {errors.password}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <InputGroup className="mb-2" style={{ maxWidth: '325px' }}>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      className={
                        touched.confirmPassword && !!errors.confirmPassword ? 'is-invalid' : ''
                      }>
                      <FormEl.Label className="m-0" htmlFor="password-input">
                        CONFIRM PASSWORD
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormEl.Control
                    id="confirmPassword-input"
                    name="confirmPassword"
                    type="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                    aria-label="email"
                  />
                  <FormEl.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </FormEl.Control.Feedback>
                </InputGroup>
                <Button
                  variant="info"
                  className="text-transform-none"
                  style={{ minWidth: '265px' }}
                  disabled={errors && isSubmitting}
                  type="submit">
                  Change password
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="fieldset-head">
          <h2 className="fieldset-title">Two-factor Authentication (2FA)</h2>
          <span className="fieldset-subtitle mb-3">
            Increase your account's security by enabling Two-Factor Authentication (2FA)
          </span>
          {!twoFactorEnabled && (
            <span className="fieldset-subtitle">
              You've already enabled two-factor authentication using one time password
              authenticators. In order to register a different device, you must first disable
              two-factor authentication.
            </span>
          )}
          {twoFactorEnabled && (
            <>
              <span className="fieldset-subtitle mb-3">SET UP TWO-FACTOR AUTHENTICATION</span>
              <span className="fieldset-subtitle mb-4">
                In order to go inline with a security guidelines you need to scan this QR Code with
                your Google Authentication App or enter the secret code below:
              </span>
            </>
          )}
        </div>
        {twoFactorEnabled && (
          <>
            <div className="qrcode mb-4">
              <div className="qrcode-holder">
                {configUrl && <QrCode value={configUrl} size={76} id="qrCode" />}
              </div>
            </div>
            <span className="fieldset-subtitle">Secret code:</span>
            <strong className="d-block mb-4">{secret}</strong>
            <Button
              variant="danger"
              className="text-transform-none"
              style={{ minWidth: '265px' }}
              onClick={changeTwoFactor}>
              Disable two-factor authentication
            </Button>
          </>
        )}
        {!twoFactorEnabled && (
          <Button
            variant="info"
            className="text-transform-none"
            style={{ minWidth: '265px' }}
            onClick={changeTwoFactor}>
            Enable two-factor authentication
          </Button>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Account;
