import React from 'react';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';
import { defaultPaymentMethods } from './InvoiceOptions';

const StatusCell = ({ paymentStatus, isArchived, statusOverride, paymentMethod }) => {
  const setStatusColor = (status) => {
    switch (status) {
      case 'paid':
        return 'text-primary';
      case 'pending':
        return 'text-warning';
      case 'declined':
        return 'text-danger';
      case 'refunded':
        return 'text-dark-red';
      default:
        return 'text-secondary';
    }
  };
  return (
    <>
      <span
        style={{ fontWeight: 500, fontSize: '0.875rem' }}
        className={setStatusColor(paymentStatus)}>
        {statusOverride && <span className="text-teal">{statusOverride}</span>}
        <span className={statusOverride ? 'd-none' : 'pr-2'}>{paymentStatus}</span>
        {paymentStatus === 'paid' && (
          <span>
            {paymentMethod === defaultPaymentMethods.applePay && (
              <IconComponent name="applePayBadge" />
            )}
            {paymentMethod === defaultPaymentMethods.googlePay && (
              <IconComponent name="googlePayBadge" />
            )}
            {paymentMethod === defaultPaymentMethods.visa && <IconComponent name="visaBadge" />}
            {paymentMethod === defaultPaymentMethods.mastercard && (
              <IconComponent name="mastercardBadge" />
            )}
            {paymentMethod === defaultPaymentMethods.americanExpress && (
              <IconComponent name="amexBadge" />
            )}
          </span>
        )}
      </span>
      {isArchived && (
        <span style={{ fontSize: '0.75rem' }} className="d-block text-uppercase text-light-gray">
          archived
        </span>
      )}
    </>
  );
};

StatusCell.propTypes = {
  paymentStatus: PropTypes.string,
  isArchived: PropTypes.bool,
  statusOverride: PropTypes.string,
  paymentMethod: PropTypes.string,
};

StatusCell.defaultProps = {
  paymentStatus: '',
  isArchived: false,
  statusOverride: '',
  paymentMethod: '',
};

export default StatusCell;
