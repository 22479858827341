import React from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Company from './Company';
import PaymentDetails from './PaymentDetails';
import TeamManager from './TeamManager';
import Account from './Account';
import GeneralSettings from './GeneralSettings';
import AsideNav from './AsideNav';
import Notifications from './Notifications';
import NotFoundPage from '../NotFound';

const SettingsPage = () => {
  const { path } = useRouteMatch();
  return (
    <div className="two-columns">
      <aside className="aside-column">
        <AsideNav />
      </aside>
      <div className="content-column">
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/company`} />
          <Redirect exact from={`${path}/payment-details`} to={`${path}/payment-details/company`} />
          <Route exact path={`${path}/company`} component={Company} />
          <Route exact path={`${path}/account`} component={Account} />
          <Route exact path={`${path}/payment-details/:key`} component={PaymentDetails} />
          <Route exact path={`${path}/team-manager`} component={TeamManager} />
          <Route exact path={`${path}/general-settings`} component={GeneralSettings} />
          <Route path={`${path}/notifications`} strict={false} component={Notifications} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
};

export default SettingsPage;
