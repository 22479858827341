import axios from 'axios';
import env from 'env';

const API_URL = `${env.API_URL}/api/auth/`;

const logout = () => {
  localStorage.removeItem('user');
  return axios.post(`${API_URL}signout/`).then(() => window.location.reload());
};

const register = (email, password) => {
  return axios
    .post(`${API_URL}signup/`, {
      email,
      password,
    })
    .then((response) => {
      console.log('register response', response);
    });
};

const login = (formData) => {
  return axios
    .post(`${API_URL}signin/`, formData)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const checkTwoFactor = () => {
  return axios
    .get(`${API_URL}two-factor/`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

const changeTwoFactor = (formData) => {
  return axios
    .post(`${API_URL}two-factor/`, formData)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

const setPassword = (user, password) => {
  return axios
    .post(`${API_URL}set-password/${user}/`, {
      password,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const changePassword = (data) => {
  return axios
    .post(`${API_URL}change-password/`, data)
    .then(({ data: responseData }) => {
      return responseData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const forgotPassword = (email) => {
  return axios
    .post(`${API_URL}forgot-password/`, {
      email,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getAdminCode = () => {
  return axios
    .post(`${API_URL}get_device_code/`, {
      username: 'admin@gmail.com',
      password: 'secret',
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const getAuthInfo = () => {
  return axios
    .get(`${API_URL}info/`)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export default {
  register,
  login,
  checkTwoFactor,
  changeTwoFactor,
  logout,
  setPassword,
  forgotPassword,
  getAdminCode,
  changePassword,
  getAuthInfo,
};
