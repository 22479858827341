import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { defaultData } from './FormFieldNames';

const AutoSubmitForm = (props) => {
  const { triggerSubmit, companyData, formErrors } = props;
  const { submitForm, setFieldError, setValues, values } = useFormikContext();
  useEffect(() => {
    if (triggerSubmit) {
      submitForm();
    }
  }, [triggerSubmit, submitForm]);

  useEffect(() => {
    if (companyData) {
      const formData = { ...defaultData };
      Object.keys(formData).forEach((key) => {
        if (companyData[key]) {
          formData[key] = companyData[key];
        }
      });

      // Terms of Services and Privacy Policy
      formData.tosAcceptance = !!companyData.tosAcceptanceDate;

      setValues(formData, true);

      if (companyData.errors) {
        Object.keys(companyData.errors).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(defaultData, key) && values[key]) {
            setFieldError(key, companyData.errors[key].join(' '));
          }
        });
      }
    }
  }, [companyData, setValues]);

  useEffect(() => {
    if (formErrors) {
      Object.keys(formErrors).forEach((key) => {
        setFieldError(key, formErrors[key].join(' '));
      });
    }
  }, [formErrors, setFieldError]);

  return null;
};
export default AutoSubmitForm;
