export const getPadding = (small, withIcon) => {
  if (withIcon) {
    return { padding: '2px 0 2px 36px' };
  }

  if (small) {
    return { padding: '2px 4px' };
  }

  return {};
};

export const getOptionStyles = ({ isFocused, isSelected }) => {
  if (isSelected) {
    return { backgroundColor: '#269b91', color: '#fff' };
  }

  if (isFocused) {
    return { backgroundColor: '#dae7ea', color: '#000' };
  }

  return {};
};

export const getStyles = ({ small, isInvalid, icon, ...props }) => ({
  control: (css) => ({
    ...css,
    fontSize: small ? '0.75rem' : '0.875rem',
    borderRadius: '8px',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      borderColor: isInvalid ? '#ff4d4d' : '#e5e9f2',
    },
    ...getPadding(small, !!icon),
    ...props.styles,
  }),
  indicatorSeparator: (css) => ({
    ...css,
    width: '0',
  }),
  menu: (css) => ({
    ...css,
    borderRadius: '8px',
    marginTop: '1px',
    padding: '8px 0',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
    zIndex: 999,
  }),
  option: (styles, state) => ({
    ...styles,
    padding: '10px 15px',
    fontSize: small ? '0.75rem' : '0.875rem',
    '&:active': {
      backgroundColor: '#269b91',
      color: '#fff',
    },
    ...getOptionStyles(state),
  }),
});
