import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const logEvent = (name) => (event) => {
  console.log(`[${name}]`, event);
};

export const Result = ({ children }) => <div className="result">{children}</div>;
Result.propTypes = {
  children: PropTypes.node,
};

export const ErrorResult = ({ children }) => <div className="error">{children}</div>;
ErrorResult.propTypes = {
  children: PropTypes.node,
};

// Demo hook to dynamically change font size based on window size.
export const useDynamicFontSize = () => {
  const [fontSize, setFontSize] = useState(window.innerWidth < 450 ? '14px' : '18px');

  useEffect(() => {
    const onResize = () => {
      setFontSize(window.innerWidth < 450 ? '14px' : '18px');
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return fontSize;
};
