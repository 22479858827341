import React from 'react';
import { Link } from 'react-router-dom';
import ImgSettingsBanner from 'assets/images/img_settings_banner.svg';
import IconComponent from 'components/IconComponent';
import './ErrorSettingsBanner.scss';

const ErrorSettingsBanner = () => {
  return (
    <>
      <div className="card settings-banner">
        <div className="card-body">
          <div className="row">
            <div className="col-md-3">
              <div className="img-holder">
                <img src={ImgSettingsBanner} alt="company settings uncompleted" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="text-holder">
                <h2 className="title">Welcome on board</h2>
                <strong className="subtitle text-uppercase">
                  In order to start working with keypay, please fill in the following data:
                </strong>
                <ul className="list-unstyled">
                  <li>
                    <span className="text-uppercase">COMPANY and OWNER DETAILS</span>
                    <div className="pl-3 font-weight-normal">
                      We need to register your business for the correct operation of transferring
                      funds from your clients to your accounts.
                    </div>
                  </li>
                  <li>
                    <span className="text-uppercase">payment details</span>
                    <div className="pl-3 font-weight-normal">
                      You need to indicate where and how you want to receive payments
                    </div>
                  </li>
                </ul>
                <Link className="btn btn-secondary default" to="/settings/payment-details/company">
                  fill in details
                  <IconComponent className="ml-3" name="arrowRight" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorSettingsBanner;
