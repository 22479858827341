import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import {
  PaymentRequestButtonElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Form } from 'react-bootstrap';
import paymentService from '_services/paymentService';
import { showLoadingOverlay, hideLoadingOverlay } from 'store/actions/loadingOverlay';
import { ErrorResult } from 'pages/Payment/util';
import './StripeForm.scss';

const elementOptions = {
  style: {
    invalid: {
      color: '#ff4d4d',
    },
  },
};

const CheckoutForm = (props) => {
  const {
    showToast,
    clientSecret,
    price,
    onSuccess,
    recipient,
    applePayEnabled,
    googlePayEnabled,
  } = props;
  const dispatch = useDispatch();
  const elements = useElements();
  const stripe = useStripe();
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [allowAdditionalPayment, setAllowAdditionalPayment] = useState(false);

  const handlePayment = useCallback(
    async (data) => {
      const result = await stripe.confirmCardPayment(clientSecret, {
        ...data,
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        console.log(result.error.message);
        setErrorMessage(result.error.message);
        showToast('error', 'Payment Error');
      } else if (result.paymentIntent.status === 'succeeded') {
        // The payment has been processed!
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        setDisabled(true);
        onSuccess();
        // history.push('/payment-success');
      } else {
        setErrorMessage('result.paymentIntent.status');
      }
    },
    [stripe, clientSecret, onSuccess, showToast],
  );

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    dispatch(showLoadingOverlay());

    const data = {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    };

    await handlePayment(data);

    dispatch(hideLoadingOverlay());
  };

  const handleChange = (event) => {
    const formErrors = validationErrors;
    const errors = {};
    const type = event.elementType;
    if (!event.empty) {
      errors[type] = event.error && event.error.message ? event.error.message : '';
    }
    setValidationErrors({ ...formErrors, ...errors });
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: recipient?.name,
          // price in currency subunit (in cents for usd), so multiply by 100 to get dollars,
          amount: Math.floor(price * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          // Allow applePay/googlePay according to organization settings
          setAllowAdditionalPayment(
            (result.applePay && applePayEnabled) || (googlePayEnabled && result.googlePay),
          );
          setPaymentRequest(pr);
        }

        pr.on('paymentmethod', async (ev) => {
          // Confirm the PaymentIntent without handling potential next actions (yet).
          const data = {
            paymentMethod: ev.paymentMethod.id,
          };

          await handlePayment(data);
        });
      });
    }
  }, [stripe, applePayEnabled, googlePayEnabled, handlePayment, price, recipient?.name]);

  return (
    <Form
      className="payment-form"
      onSubmit={(e) => {
        e.preventDefault();
      }}>
      <Form.Group controlId="cardNumber">
        <Form.Label>Card Number</Form.Label>
        <CardNumberElement
          id="cardNumber"
          className="form-control"
          onChange={handleChange}
          options={elementOptions}
        />
        {validationErrors && validationErrors.cardNumber && (
          <Form.Control.Feedback className="d-block" type="invalid" tooltip>
            {validationErrors.cardNumber}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <div className="row no-gutters mb-2">
        <Form.Group className="col-md-6" controlId="expiry">
          <Form.Label>Card Expiration</Form.Label>
          <CardExpiryElement
            id="expiry"
            className="form-control"
            onChange={handleChange}
            options={elementOptions}
          />
          {validationErrors && validationErrors.cardExpiry && (
            <Form.Control.Feedback className="d-block" type="invalid" tooltip>
              {validationErrors.cardExpiry}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="col-md-6 pl-1" controlId="cvc">
          <Form.Label>CVC</Form.Label>
          <CardCvcElement
            id="cvc"
            className="form-control"
            onChange={handleChange}
            options={elementOptions}
          />
          {validationErrors && validationErrors.cardCvc && (
            <Form.Control.Feedback className="d-block" type="invalid" tooltip>
              {validationErrors.cardCvc}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </div>
      {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
      <button
        className="btn btn-info mb-3 btn-block"
        type="submit"
        disabled={!stripe || disabled}
        onClick={handleSubmit}>
        Pay
        <NumberFormat
          readOnly
          className="static-format w-100"
          prefix="$"
          thousandSeparator=","
          decimalSeparator="."
          value={price}
          displayType="text"
          renderText={(value) => <span className="pl-2">{value}</span>}
        />
      </button>
      {paymentRequest && allowAdditionalPayment && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
    </Form>
  );
};

CheckoutForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  applePayEnabled: PropTypes.bool,
  googlePayEnabled: PropTypes.bool,
  showToast: PropTypes.func,
  onSuccess: PropTypes.func,
  recipient: PropTypes.shape({
    name: PropTypes.string,
  }),
};

let stripePromise;
const getStripe = async (organisation) => {
  if (!stripePromise) {
    const STRIPE_KEY = await paymentService.getStripeKey();
    stripePromise = loadStripe(STRIPE_KEY, { stripeAccount: organisation.accountId });
  }
  return stripePromise;
};

const StripeForm = ({ organisation, ...props }) => {
  return (
    <div className="mb-5">
      <Elements stripe={getStripe(organisation)}>
        <CheckoutForm {...props} />
      </Elements>
    </div>
  );
};

export default StripeForm;
