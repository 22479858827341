import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/actions/message';
import PropTypes from 'prop-types';
import NotificationService from '_services/notificationService';
import stripHtml from '../utils';
import './PreviewTemplate.scss';

const notificationService = new NotificationService();

const PreviewTemplate = ({ template }) => {
  const dispatch = useDispatch();
  const { header, body, notes, signature, id, includeElements } = template;
  const [iframeBody, setIframeBody] = useState(null);

  useEffect(() => {
    if (id) {
      notificationService
        .previewTemplatesById(id)
        .then((response) => {
          setIframeBody(response);
        })
        .catch(({ statusText }) => {
          dispatch(setMessage('error', statusText));
        });
    } else {
      const previewHeader = stripHtml(header) ? header : undefined;
      const previewBody = stripHtml(body) ? body : undefined;
      const previewNotes = stripHtml(notes) ? notes : undefined;
      const previewSignature = stripHtml(signature) ? signature : undefined;
      notificationService
        .previewTemplatesByParams({
          header: previewHeader,
          body: previewBody,
          notes: previewNotes,
          signature: previewSignature,
          includeElements: includeElements.length ? includeElements.join(',') : undefined,
        })
        .then((response) => {
          setIframeBody(response);
        })
        .catch(({ statusText }) => {
          dispatch(setMessage('error', statusText));
        });
    }
  }, [header, body, notes, signature, includeElements, id, dispatch]);

  return (
    <iframe
      className="preview-notification-iframe"
      width="100%"
      height="583"
      srcDoc={iframeBody}
      title="Preview"
      frameBorder="0"
      scrolling="yes"
      style={{ width: '100%', border: 'none' }}
    />
  );
};

PreviewTemplate.propTypes = {
  template: PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.string,
    notes: PropTypes.string,
    signature: PropTypes.string,
    id: PropTypes.number,
    includeElements: PropTypes.arrayOf(PropTypes.string),
  }),
};

PreviewTemplate.defaultProps = {
  template: {},
};

export default PreviewTemplate;
