import React from 'react';
import IconComponent from 'components/IconComponent';
import PropTypes from 'prop-types';

const TemplateActions = (props) => {
  const {
    isPaused,
    type,
    time,
    handleDelete,
    handlePreview,
    handlePause,
    handleEdit,
    handlePlay,
    handleCopy,
  } = props;
  return (
    <div className="template-actions">
      {type !== 'sms' && (
        <button type="button" className="btn btn-icon" title="preview" onClick={handlePreview}>
          <IconComponent name="preview" />
        </button>
      )}
      {time === 'after' && (
        <button type="button" className="btn btn-icon" title="copy" onClick={handleCopy}>
          <IconComponent name="copy" />
        </button>
      )}
      <button type="button" className="btn btn-icon" title="edit" onClick={handleEdit}>
        <IconComponent name="edit" />
      </button>
      {isPaused ? (
        <button type="button" className="btn btn-icon" title="pause" onClick={handlePlay}>
          <IconComponent name="play" />
        </button>
      ) : (
        <button type="button" className="btn btn-icon" title="pause" onClick={handlePause}>
          <IconComponent name="pause" />
        </button>
      )}
      {time === 'after' && (
        <button type="button" className="btn btn-icon" title="delete" onClick={handleDelete}>
          <IconComponent name="trash" />
        </button>
      )}
    </div>
  );
};

TemplateActions.propTypes = {
  isPaused: PropTypes.bool,
  type: PropTypes.string,
  time: PropTypes.string,
  handleDelete: PropTypes.func,
  handlePreview: PropTypes.func,
  handlePause: PropTypes.func,
  handlePlay: PropTypes.func,
  handleEdit: PropTypes.func,
  handleCopy: PropTypes.func,
};

TemplateActions.defaultProps = {
  isPaused: false,
  type: '',
  time: '',
  handleDelete: () => {},
  handlePreview: () => {},
  handlePause: () => {},
  handlePlay: () => {},
  handleEdit: () => {},
  handleCopy: () => {},
};

export default TemplateActions;
