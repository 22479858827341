import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import StripeForm from 'components/StripeForm';
import PaymentSuccess from 'components/PaymentStatus/PaymentSuccess';
import PaymentCancelled from 'components/PaymentStatus/PaymentCancelled';
import PaymentFailed from 'components/PaymentStatus/PaymentFailed';
import paymentService from '_services/paymentService';
import 'react-toastify/dist/ReactToastify.css';

const PaymentPage = () => {
  const { key } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentEnabled, setPaymentEnabled] = useState(false);
  const [paymentCancelled, setPaymentCancelled] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);

  const setPaymentSatus = (status) => {
    switch (status) {
      case 'paid':
        setPaymentSuccess(true);
        break;
      case 'cancelled':
        setPaymentCancelled(true);
        break;
      default:
        setPaymentFailed(true);
    }
  };

  const getInvoiceInfo = () => {
    paymentService
      .getPaymentInfo(key)
      .then(
        ({
          invoice: serverInvoice,
          organisation: serverOrganisation,
          clientSecret: secret,
          errorMsg: serverError,
        }) => {
          const { recipient: serverRecipient } = serverInvoice;
          const hasRecipient = !!serverRecipient;
          if (!hasRecipient) {
            setPaymentFailed(true);
            return;
          }
          setInvoice(serverInvoice);
          setOrganisation(serverOrganisation);
          setRecipient(serverRecipient);
          setClientSecret(secret);
          setErrorMsg(serverError);
          setPaymentEnabled(true);
        },
      )
      .catch(({ data }) => {
        setPaymentSatus(data.status);
      });
  };

  const showToast = (level, message) => {
    toast[level](message);
    getInvoiceInfo();
  };

  useEffect(() => {
    getInvoiceInfo();
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '100%' }}>
      {paymentFailed && <PaymentFailed />}
      {paymentSuccess && <PaymentSuccess />}
      {paymentCancelled && <PaymentCancelled />}
      {paymentEnabled && (
        <div style={{ maxWidth: '340px' }} className=" mx-auto py-3 px-3">
          <div>
            {organisation?.logo && (
              <div
                style={{
                  width: '150px',
                  height: '40px',
                }}>
                <img
                  alt="Logo"
                  src={organisation?.logo}
                  style={{ maxHeight: '40px', maxWidth: '150px' }}
                />
              </div>
            )}
            <p style={{ fontSize: '0.875rem', fontWeight: 500 }}>{organisation?.title}</p>
          </div>
          <h6 className="card-subtitle">Invoice for</h6>
          <h5
            className="card-title"
            style={{ fontSize: '0.875rem', fontWeight: 500, marginBottom: '15px' }}>
            {recipient?.name}
          </h5>
          <div className="mb-4">
            <p className="card-subtitle">Total to pay</p>
            <div style={{ fontSize: '1.5rem', fontWeight: '500', color: '#269B91' }}>
              <NumberFormat
                readOnly
                className="static-format w-100"
                prefix="$"
                thousandSeparator=","
                decimalSeparator="."
                value={invoice.toPay}
                displayType="text"
                renderText={(value) => <p>{value}</p>}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-body" style={{ padding: '20px 12px' }}>
              {clientSecret && (
                <StripeForm
                  organisation={organisation}
                  paymentId={key}
                  recipient={recipient}
                  clientSecret={clientSecret}
                  applePayEnabled={organisation.applePayEnabled}
                  googlePayEnabled={organisation.googlePayEnabled}
                  showToast={showToast}
                  onSuccess={() => {
                    setPaymentSuccess(true);
                    setPaymentEnabled(false);
                  }}
                  price={Number(invoice.toPay)}
                />
              )}
              {errorMsg && (
                <div className="text-center text-muted">
                  <p className="m-0">
                    We unable to receive payments. Error:
                    <br />
                    {errorMsg}
                  </p>
                </div>
              )}
              <div style={{ fontSize: '0.625rem' }} className="text-center text-muted">
                *Your payment is processed by KeyPay and is securely processed using the best
                encryption available through our partners at Stripe company. Click to view our
                &nbsp;
                <span style={{ position: 'relative' }}>
                  <span
                    style={{ cursor: 'pointer' }}
                    data-event="click"
                    data-for="policy-tooltip"
                    data-tip="Payment processing services on Keypay are provided by Stripe and are
                          subject to the Stripe Connected Account Agreement, which includes the
                          Stripe Terms of Service (collectively, the “Stripe Services
                          Agreement”). By agreeing to this agreement or continuing to operate on
                          Keypay, you agree to be bound by the Stripe Services Agreement, as the
                          same may be modified by Stripe from time to time. As a condition of
                          Keypay enabling payment processing services through Stripe, you agree
                          to provide Keypay accurate and complete information about you and your
                          business, and you authorize Keypay to share it and transaction
                          information related to your use of the payment processing services
                          provided by Stripe."
                    className="text-decoration-underline text-primary">
                    Terms of Services and Privacy Policy
                  </span>
                  <ReactTooltip id="policy-tooltip" place="top" type="dark" effect="float" />
                </span>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default PaymentPage;
