import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const AutoSubmitForm = (props) => {
  const { triggerSubmit, formErrors } = props;
  const { submitForm, setFieldError, isValid, resetForm } = useFormikContext();
  useEffect(() => {
    if (triggerSubmit && isValid) {
      submitForm();
    }
  }, [triggerSubmit, submitForm, isValid]);

  useEffect(() => {
    if (formErrors) {
      Object.keys(formErrors).forEach((key) => {
        setFieldError(key, formErrors[key][0]);
      });
    }
  }, [formErrors, setFieldError]);

  useEffect(() => {
    resetForm();
  }, []);

  return null;
};

export default AutoSubmitForm;
