import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import PlacesAutocomplete from 'react-places-autocomplete';
import GetPlacesPostCodeById from 'components/GetPlacesPostCodeById';
import './LocationSearchInput.scss';

const LocationSearchInput = ({ ...props }) => {
  const { callBackFunc, id, placeholder, dependingFields } = props;
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const searchOptions = {
    componentRestrictions: { country: 'us' },
    types: ['address'],
  };

  const handleChange = (address) => {
    setFieldValue(field.name, address);
  };

  const handleSelect = async (address, placeId) => {
    setFieldValue(field.name, address);
    const newAddress = await GetPlacesPostCodeById(placeId);

    if (newAddress.addressLine1) {
      setFieldValue(`${dependingFields}AddressLine1`, newAddress.addressLine1, true);
    }
    if (newAddress.city) {
      setFieldValue(`${dependingFields}AddressCity`, newAddress.city, true);
    }
    if (newAddress.state) {
      setFieldValue(`${dependingFields}AddressState`, newAddress.state, true);
    }
    if (newAddress.postalCode) {
      setFieldValue(`${dependingFields}AddressPostalCode`, newAddress.postalCode, true);
    }
  };

  return (
    <PlacesAutocomplete
      {...field}
      {...props}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      googleCallbackName={callBackFunc}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder,
              className: 'form-control location-search-input',
              id,
            })}
          />
          <div className="dropdown-menu autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? 'dropdown-item suggestion-item--active'
                : 'dropdown-item suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  key={suggestion.description.trim()}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

LocationSearchInput.propTypes = {
  callBackFunc: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  dependingFields: PropTypes.string,
};

LocationSearchInput.defaultProps = {
  id: '',
  placeholder: '',
  dependingFields: '',
  callBackFunc: '',
};

export default LocationSearchInput;
