import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortAmountUpAlt, faSortAmountDownAlt } from '@fortawesome/free-solid-svg-icons';
import './SortTable.scss';

const TheadSortButton = (props) => {
  const { sortName, sortOrder, changeSortOrder } = props;
  let icon;
  if (sortOrder === 'down') {
    icon = <FontAwesomeIcon icon={faSortAmountDownAlt} />;
  } else if (sortOrder === 'up') {
    icon = <FontAwesomeIcon icon={faSortAmountUpAlt} />;
  } else {
    icon = <FontAwesomeIcon icon={faSort} />;
  }
  return (
    <button type="button" className="sortButtom" onClick={() => changeSortOrder(sortName)}>
      {icon}
    </button>
  );
};

TheadSortButton.propTypes = {
  sortName: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  changeSortOrder: PropTypes.func,
};

export default TheadSortButton;
