import React from 'react';
import PropTypes from 'prop-types';
import IconComponent from 'components/IconComponent';
import './InvoiceDeliveryStatus.scss';

const InvoiceDeliveryStatus = ({ sendEmail, sendSMS, emailStatus, smsStatus }) => {
  const defaultStatus = {
    pending: 'pending',
    delivered: 'delivered',
    failed: 'failed',
    notSelected: '',
  };

  const setDeliveredStatus = (status) => {
    switch (status) {
      case defaultStatus.pending:
        return defaultStatus.pending;
      case defaultStatus.delivered:
        return defaultStatus.delivered;
      case defaultStatus.failed:
        return defaultStatus.failed;
      default:
        return defaultStatus.notSelected;
    }
  };

  return (
    <div className="delivery-status d-flex">
      {sendEmail && !!emailStatus && (
        <div
          className={`box ${setDeliveredStatus(emailStatus)}`}
          title={setDeliveredStatus(emailStatus)}>
          <IconComponent name="message" />
          <span className="error">!</span>
        </div>
      )}
      {sendSMS && !!smsStatus && (
        <div
          className={`box ${setDeliveredStatus(smsStatus)}`}
          title={setDeliveredStatus(smsStatus)}>
          <IconComponent name="tablet" />
          <span className="error">!</span>
        </div>
      )}
    </div>
  );
};

InvoiceDeliveryStatus.propTypes = {
  sendEmail: PropTypes.bool,
  sendSMS: PropTypes.bool,
  emailStatus: PropTypes.string,
  smsStatus: PropTypes.string,
};

export default InvoiceDeliveryStatus;
