import { SET_MESSAGE, CLEAR_MESSAGE } from 'store/actions/types';

const initialState = {
  messageType: '',
  message: null,
};

export default function message(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        messageType: action.messageType,
        message: action.message,
      };

    case CLEAR_MESSAGE:
      return initialState;

    default:
      return state;
  }
}
