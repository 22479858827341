import React from 'react';
import Modal from 'react-modal';
import IconComponent from 'components/IconComponent';

const ConfirmRemoveModal = ({ show, onHide, onSubmit }) => {
  return (
    <Modal
      ariaHideApp={false}
      overlayClassName="fade modal show d-block"
      className="modal-dialog modal-dialog-centered"
      aria-labelledby="contained-modal-title-vcenter"
      onRequestClose={onHide}
      isOpen={show}>
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="modal-title text-uppercase">
            <span className="mr-3">
              <IconComponent name="infoCircle" />
            </span>
            Remove the location
          </h3>
          <button type="button" className="btn btn-cancel btn-link" onClick={onHide}>
            <IconComponent name="close" />
          </button>
        </div>
        <div className="modal-body">
          <h5 className="font-weight-normal">Are you sure you want to remove the location?</h5>
        </div>
        <div className="modal-footer justify-content-center">
          <button type="button" className="btn btn-light rounded-pill" onClick={onHide}>
            Close
          </button>
          <button type="button" className="btn btn-danger rounded-pill" onClick={onSubmit}>
            Remove
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmRemoveModal;
