import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FileService from '_services/fileService';
import IconComponent from 'components/IconComponent';

const fileService = new FileService();

const InvoiceFileUploader = ({ uploadFile }) => {
  const [uploadError, setUploadError] = useState(false);
  const handleUploadFile = (event) => {
    const loadedFile = event.target.files[0];
    if (!loadedFile) {
      setUploadError(false);
      return;
    }
    if (loadedFile?.type !== 'application/pdf') {
      setUploadError(true);
      return;
    }

    fileService
      .uploadFile(event.target.files[0])
      .then(({ data }) => {
        uploadFile(data);
        setUploadError(false);
      })
      .catch(() => {
        setUploadError(true);
      });
  };

  return (
    <div className="h-100 w-100 d-flex justify-content-center">
      <label
        htmlFor="inputFile"
        className="d-flex align-items-center justify-content-center text-center m-0 position-relative w-100 cursor-pointer">
        <input
          id="inputFile"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            opacity: 0,
          }}
          type="file"
          onChange={handleUploadFile}
        />
        <>
          {!uploadError && (
            <div>
              <strong
                className="mb-2 text-center"
                style={{
                  fontWeight: '500',
                  display: 'block',
                  margin: '0 0 20px',
                }}>
                Upload Invoice
              </strong>
              <IconComponent name="pdf" alt="Pdf" />
              <p style={{ fontWeight: '500', color: '#939AA3', margin: '20px 0 0' }}>
                Drag and drop or{' '}
                <span style={{ color: '#269B91', cursor: 'pointer' }}>click to upload</span>
              </p>
            </div>
          )}
          {uploadError && (
            <div>
              <p className="text-danger">
                File upload failed. <br />
                We do not support other file formats except PDF
              </p>
              <span className="btn btn-info min-w">Upload PDF</span>
            </div>
          )}
        </>
      </label>
    </div>
  );
};

InvoiceFileUploader.propTypes = {
  uploadFile: PropTypes.func,
};

export default InvoiceFileUploader;
