import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const CardCompletedAlert = ({ cardNumber, onEdit }) => {
  return (
    <Card variant="info" style={{ maxWidth: '350px', borderColor: '#E5E9F2' }}>
      <Card.Body>
        <strong className="d-block mb-2">Bank account</strong>
        <p style={{ color: '#46AAF7', margin: 0 }}>**** **** {cardNumber}</p>
      </Card.Body>
      <hr style={{ margin: 0 }} />
      <div className="d-flex justify-content-end" style={{ padding: '7px 20px' }}>
        <button type="button" className="link text-capitalize" onClick={onEdit}>
          edit
        </button>
      </div>
    </Card>
  );
};

CardCompletedAlert.defaultProps = {
  cardNumber: '',
  onEdit: () => {},
};

CardCompletedAlert.propTypes = {
  cardNumber: PropTypes.string,
  onEdit: PropTypes.func,
};

export default CardCompletedAlert;
