import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { hideInfoBanner } from 'store/actions/companyInfoBanner';

const InformationBanner = () => {
  const dispatch = useDispatch();
  const show = useSelector(({ companyInfoBanner }) => companyInfoBanner);
  const hideBanner = () => {
    dispatch(hideInfoBanner());
  };

  return (
    <>
      {show && (
        <Alert
          variant="warning"
          onClose={hideBanner}
          dismissible
          style={{
            backgroundColor: '#F79E1B',
            color: '#fff',
            fontSize: '0.875rem',
            marginBottom: '25px',
          }}>
          <p className="m-0">
            The information below will be used to establish the collaboration agreement and for the
            invoices of the earnings generated (if any).
            <br /> Keypay will not be held responsible for the information entered incorrectly by
            the user and reserves the right to cancel the agreement with the user if the conditions
            of the contract are breached.
          </p>
        </Alert>
      )}
    </>
  );
};

export default InformationBanner;
