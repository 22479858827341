import React from 'react';
import PropTypes from 'prop-types';
import FileService from '_services/fileService';
import Pdf from 'components/Pdf';

const fileService = new FileService();

const InvoiceFilePreview = (props) => {
  const { file } = props;
  const url = fileService.getFileUrl(file);

  return (
    <>
      <Pdf pdf={url} {...props} />
    </>
  );
};

function PreviewPropsAreEqual(prevProps, nextProps) {
  return prevProps.file === nextProps.file && prevProps.isParsed === nextProps.isParsed;
}

InvoiceFilePreview.propTypes = {
  file: PropTypes.string,
  filename: PropTypes.string,
  removeFile: PropTypes.func.isRequired,
};

export default React.memo(InvoiceFilePreview, PreviewPropsAreEqual);
