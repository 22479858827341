import React, { useState } from 'react';
import { Form as FormEl, Alert } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import organisationService from '_services/organisationService';

const InviteForm = () => {
  const [formErrors, setFormErrors] = useState([]);
  const validateForm = (values) => {
    const errors = {};
    if (!values.clientEmail) {
      errors.clientEmail = 'Email is required';
    } else if (values.clientEmail.length >= 255) {
      errors.clientEmail = 'Must be 255 characters or less';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.clientEmail)) {
      errors.clientEmail = 'Invalid email address';
    }
    return errors;
  };

  const handleSubmit = (values, actions) => {
    setFormErrors([]);
    organisationService
      .sendClintInvite(values)
      .then(() => {
        toast.success('Client was successfully invited!');
      })
      .catch((error) => {
        if (error.data) {
          const errorObj = error.data;
          const commonErrors = [];
          Object.keys(errorObj).forEach((key) => {
            switch (key) {
              case 'toast':
                toast.error(errorObj[key]);
                break;
              case 'clientEmail':
                actions.setFieldError('clientEmail', errorObj[key]);
                break;
              default:
                commonErrors.push(errorObj[key]);
                break;
            }
          });
          if (commonErrors.length) {
            setFormErrors(commonErrors);
          }
        }
      });
  };

  return (
    <Formik validate={validateForm} initialValues={{ clientEmail: '' }} onSubmit={handleSubmit}>
      {({ handleChange, values, touched, errors }) => (
        <Form>
          <FormEl.Group className="mb-4" controlId="clientEmail">
            <FormEl.Label>Client email</FormEl.Label>
            <FormEl.Control
              className="form-control small-text"
              name="clientEmail"
              placeholder="client_email@gmail.com"
              value={values.clientEmail}
              onChange={handleChange}
              aria-label="clientEmail"
              isInvalid={touched.clientEmail && !!errors.clientEmail}
            />
            <FormEl.Control.Feedback type="invalid" tooltip>
              {errors.clientEmail}
            </FormEl.Control.Feedback>
          </FormEl.Group>
          {formErrors &&
            formErrors.map((singleError) => (
              <Alert key={singleError.trim()} variant="danger">
                {singleError}
              </Alert>
            ))}
          <button className="btn btn-info rounded-pill min-w" type="submit">
            Send
          </button>
          <ToastContainer />
        </Form>
      )}
    </Formik>
  );
};

export default InviteForm;
