import React, { useState, useEffect } from 'react';
import { Alert, Form as FormEl } from 'react-bootstrap';
import { useFormikContext } from 'formik';

const RecipientChangedAlert = (props) => {
  const { activeRecipient, invoiceId, onChangeRecipient } = props;
  const { values, errors, setFieldValue } = useFormikContext();
  const { name, email, phone, address } = values;
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (e) => {
    switch (e.target.value) {
      case 'edit':
        setFieldValue('changedRecipient', 'edit', true);
        break;
      case 'create':
        setFieldValue('changedRecipient', 'create', true);
        break;
      default:
        setFieldValue('changedRecipient', '');
        break;
    }
  };

  useEffect(() => {
    if (!activeRecipient || !activeRecipient.id || invoiceId) {
      return;
    }
    const {
      name: activeRecipientName,
      email: activeRecipientEmail,
      phone: activeRecipientPhone,
      address: activeRecipientAddress,
    } = activeRecipient;

    if (
      (activeRecipientName && activeRecipientName !== name?.value.trim()) ||
      (activeRecipientEmail && activeRecipientEmail !== email?.value.trim()) ||
      (activeRecipientPhone && activeRecipientPhone !== phone?.value.trim()) ||
      (activeRecipientAddress && activeRecipientAddress !== address?.value.trim())
    ) {
      setShowAlert(true);
      onChangeRecipient(true);
      return;
    }
    onChangeRecipient(false);
    setShowAlert(false);
  }, [name, email, phone, JSON.stringify(activeRecipient)]);

  return (
    <>
      {showAlert && (
        <Alert variant="warning" style={{ fontSize: '0.75rem' }}>
          <strong style={{ fontWeight: 600 }} className="d-block mb-2 text-uppercase">
            You’re currently editing:
          </strong>
          <p className="m-0">{activeRecipient.name}</p>
          {activeRecipient?.email && <p className="m-0">{activeRecipient.email}</p>}
          {activeRecipient?.phone && <p className="m-0">{activeRecipient.phone}</p>}
          {activeRecipient?.address && <p className="m-0">{activeRecipient.address}</p>}
          <div
            aria-hidden="true"
            className="custom-control custom-radio mt-4 mb-2"
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}>
            <FormEl.Control
              value="edit"
              // checked={values.changeRecipientRadio}
              className="custom-control-input"
              type="radio"
              onChange={handleChange}
              id="edit-recipient-radio"
              name="changedRecipient"
              isInvalid={!!errors.changedRecipient}
            />
            <label
              htmlFor="edit-recipient-radio"
              className="custom-control-label"
              style={{ lineHeight: '1.5rem' }}>
              Apply changes to a current recipient
            </label>
          </div>
          <div
            aria-hidden="true"
            className="custom-control custom-radio mb-2"
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}>
            <FormEl.Control
              value="create"
              // checked={values.changeRecipientRadio}
              className="custom-control-input"
              type="radio"
              onChange={handleChange}
              id="create-recipient-radio"
              name="changedRecipient"
              isInvalid={!!errors.changedRecipient}
            />
            <label
              htmlFor="create-recipient-radio"
              className="custom-control-label"
              style={{ lineHeight: '1.5rem' }}>
              Create new recipient
            </label>
          </div>
          {!!errors.changedRecipient && (
            <FormEl.Control.Feedback className="d-block" type="invalid">
              {errors.changedRecipient}
            </FormEl.Control.Feedback>
          )}
        </Alert>
      )}
    </>
  );
};
export default RecipientChangedAlert;
