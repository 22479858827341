import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import SelectField from 'components/SelectField';
import paymentService from '_services/paymentService';
import IconComponent from 'components/IconComponent';

const ContactDetailsForm = ({ organizationUuid, onDetailsComplete, locations }) => {
  const defaultValues = {
    amount: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
  };
  const { handleSubmit, getFieldProps, touched, errors, setFieldValue, handleChange } = useFormik({
    initialValues: defaultValues,
    validate: (formValues) => {
      const errorsObj = {};

      if (!formValues.amount) {
        errorsObj.amount = 'Amount to pay is required';
      }

      if (!formValues.firstName) {
        errorsObj.firstName = 'First Name is required';
      } else if (formValues.firstName.length >= 255) {
        errorsObj.firstName = 'Must be 255 characters or less';
      }

      if (!formValues.lastName) {
        errorsObj.lastName = 'Last Name is required';
      } else if (formValues.lastName.length >= 255) {
        errorsObj.lastName = 'Must be 255 characters or less';
      }

      if (!formValues.email) {
        errorsObj.email = 'Email is required';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errorsObj.email = 'Invalid email address';
      } else if (formValues.email.length >= 255) {
        errorsObj.email = 'Must be 255 characters or less';
      }

      return errorsObj;
    },
    onSubmit: ({ amount, firstName, lastName, phone, email, location, accountNumber }) => {
      const formData = {
        amount,
        phone,
        email,
        name: `${firstName} ${lastName}`,
        location,
        accountNumber,
      };
      paymentService
        .setOutsidePayment(organizationUuid, formData)
        .then(({ clientSecret: secret }) => {
          onDetailsComplete({ secret, contactData: formData });
        });
    },
  });

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col col-md-6 px-md-1">
          <Form.Group className="mb-4">
            <Form.Label className="m-0 text-uppercase small-text" htmlFor="amount-input">
              amount to pay <sup className="text-danger">*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="amount-input"
                  className={touched.amount && !!errors.amount ? 'is-invalid pr-0' : 'pr-0'}>
                  $
                </InputGroup.Text>
              </InputGroup.Prepend>
              <NumberFormat
                {...getFieldProps('amount')}
                className="small-text"
                allowNegative={false}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                id="amount-input"
                isInvalid={touched.amount && !!errors.amount}
                onChange={(e) => e.preventDefault()}
                onValueChange={(formatValues) => {
                  setFieldValue('amount', formatValues.floatValue);
                }}
                customInput={FormControl}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {errors.amount}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col px-md-1 py-3 small border-top">
          <p>Please enter your contact information.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 px-md-1">
          <Form.Group>
            <Form.Label className="m-0 text-uppercase small-text" htmlFor="firstName-input">
              first name<sup className="text-danger">*</sup>
            </Form.Label>
            <FormControl
              className="small-text"
              id="firstName-input"
              name="firstName"
              onChange={handleChange}
              isInvalid={touched.firstName && !!errors.firstName}
              placeholder="Mike"
              aria-label="firstName"
              {...getFieldProps('firstName')}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-md-6 px-md-1">
          <Form.Group>
            <Form.Label className="m-0 text-uppercase small-text" htmlFor="lastName-input">
              last name<sup className="text-danger">*</sup>
            </Form.Label>
            <FormControl
              className="small-text"
              id="lastName-input"
              name="lastName"
              onChange={handleChange}
              isInvalid={touched.lastName && !!errors.lastName}
              placeholder="Herman"
              aria-label="lastName"
              {...getFieldProps('lastName')}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 px-md-1">
          <Form.Group>
            <Form.Label className="m-0 text-uppercase small-text" htmlFor="email-input">
              email<sup className="text-danger">*</sup>
            </Form.Label>
            <FormControl
              className="small-text"
              id="email-input"
              name="email"
              onChange={handleChange}
              isInvalid={touched.email && !!errors.email}
              placeholder="email@gmail.com"
              aria-label="email"
              {...getFieldProps('email')}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-md-6 px-md-1">
          <Form.Group>
            <Form.Label className="m-0 text-uppercase small-text" htmlFor="phone-input">
              mobile number
            </Form.Label>
            <NumberFormat
              {...getFieldProps('phone')}
              id="phone-input"
              name="phone"
              className="small-text"
              format="(###) ### ####"
              mask=""
              placeholder="425 123 4567"
              onChange={(e) => e.preventDefault()}
              onValueChange={(formatValues) => {
                setFieldValue('phone', formatValues.value);
              }}
              customInput={FormControl}
              isInvalid={touched.phone && !!errors.phone}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-md-6 px-md-1">
          <Form.Group>
            <Form.Label className="m-0 text-uppercase small-text" htmlFor="account-number-input">
              account number
            </Form.Label>
            <FormControl
              className="small-text"
              id="account-number-input"
              name="email"
              onChange={handleChange}
              isInvalid={touched.accountNumber && !!errors.accountNumber}
              aria-label="account number"
              {...getFieldProps('accountNumber')}
            />
            <Form.Control.Feedback type="invalid" tooltip>
              {errors.accountNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </div> */}
        <div className="col-md-6 px-md-1">
          <SelectField
            small
            labelClassName="m-0 text-uppercase small-text"
            icon="location"
            label="Location"
            name="location"
            onChange={(value) => setFieldValue('location', value)}
            options={locations.map((location) => ({
              value: location.id,
              label: location.title,
            }))}
          />
        </div>
      </div>
      <div className="row d-md-flex align-items-center justify-content-md-between pt-4">
        <div className="col col-md-auto">
          <span className="d-flex align-items-center mb-3 text-muted">
            <span className="mr-2">
              <IconComponent name="lock" />
            </span>
            <span style={{ fontWeight: 600, fontSize: '0.625rem', lineHeight: '15px' }}>
              Payments are secure and encrypted
            </span>
          </span>
        </div>
        <div className="col col-md-auto text-right">
          <button type="submit" className="btn btn-info mb-3 default">
            next{' '}
            <span className="icon-holder">
              <IconComponent name="arrowRight" />
            </span>
          </button>
        </div>
      </div>
    </form>
  );
};

ContactDetailsForm.defaultProps = {
  locations: [],
  organizationUuid: '',
  onDetailsComplete: () => {},
};

ContactDetailsForm.propTypes = {
  locations: PropTypes.array,
  organizationUuid: PropTypes.string,
  onDetailsComplete: PropTypes.func,
};

export default ContactDetailsForm;
