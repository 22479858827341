import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { formatDate, formatDateByParts } from '_helpers/common';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerField.scss';

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const {
    value,
    name,
    dayField,
    monthField,
    yearField,
    isInvalid,
    className: templateClassName,
    id,
  } = props;
  const [selected, setSelected] = useState(null);
  const handleCustomChange = (date) => {
    console.log('moment value', moment(date).format('YYYY-MM-DD'));
    console.log('formatDate', formatDate(date));
    setFieldValue(name, formatDate(date), true);
    const dateObj = formatDateByParts(date);
    if (dayField) {
      setFieldValue(dayField, dateObj.day);
    }
    if (monthField) {
      setFieldValue(monthField, dateObj.month);
    }
    if (yearField) {
      setFieldValue(yearField, dateObj.year);
    }
  };

  useEffect(() => {
    if (value) {
      const utc = moment(new Date(value), 'YYYY-MM-DD').utc().toDate();
      setSelected(utc);
    }
  }, [value]);

  return (
    <DatePicker
      id={id}
      dateFormat="MM/dd/yyyy"
      className={`${templateClassName} ${isInvalid ? 'is-invalid' : ''}`}
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
      // maxDate={moment().valueOf()}
      selected={selected}
      onChange={handleCustomChange}
    />
  );
};

DatePickerField.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  dayField: PropTypes.string,
  monthField: PropTypes.string,
  yearField: PropTypes.string,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
};

DatePickerField.defaultProps = {
  value: '',
  name: '',
  dayField: '',
  monthField: '',
  yearField: '',
  isInvalid: false,
  className: '',
  id: '',
};

export default DatePickerField;
