import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { getParsedInvoice, selectRecipient, clearInvoiceData } from 'store/actions/parsedInvoice';
import { showLoadingOverlay, hideLoadingOverlay } from 'store/actions/loadingOverlay';
import InvoiceFileUploader from './InvoiceFileUploader';
import InvoiceFilePreview from './InvoiceFilePreview';
import RecipientsTable from './RecipientsTable';
import './InvoiceFiles.scss';

const InvoiceFiles = ({ file, filename, onSetRecipient }) => {
  const dispatch = useDispatch();
  const { data, isParsed, recipientSelected, parsedError } = useSelector(
    ({ parsedInvoice }) => parsedInvoice,
  );

  const { setFieldValue, values } = useFormikContext();

  const handleUploadFile = (fileData) => {
    setFieldValue('file', fileData.file);
    setFieldValue('filename', fileData.filename);
  };

  const handleRemoveFile = () => {
    setFieldValue('file', '');
    setFieldValue('filename', '');
    dispatch(clearInvoiceData());
  };

  const handleParseFile = () => {
    dispatch(showLoadingOverlay('Please wait. Reading from the PDF file...'));
    dispatch(getParsedInvoice(file));
  };

  const setRecipientPending = () => {
    const { lines } = values;
    let newLines = lines.slice();
    const newLine = {
      keyId: uuidv4(),
      amount: data.data.toPay,
      description: '',
    };
    if (newLines.length === 1 && newLines[0].amount === '0') {
      newLines = [];
    }
    newLines.push(newLine);
    setFieldValue('lines', newLines);
  };

  const setFormRecipient = (recipient) => {
    const fieldNames = ['name', 'email', 'phone', 'address', 'accountNumber'];

    fieldNames.forEach((fieldName) => {
      const field = values[fieldName];

      if (!field?.value.trim() && recipient[fieldName]) {
        setFieldValue(
          fieldName,
          { value: recipient[fieldName], label: recipient[fieldName] },
          true,
        );
      }
    });
  };

  const setTableRecipient = (recipient) => {
    setFormRecipient(recipient);
    setRecipientPending();
    onSetRecipient(recipient);
    dispatch(selectRecipient());
  };

  // hide overlay once file is parsed and data is received
  useEffect(() => {
    if (isParsed) {
      dispatch(hideLoadingOverlay());
    }
  }, [dispatch, isParsed]);

  // if no recipients - set data from parsed file
  useEffect(() => {
    if (isParsed && !recipientSelected && data !== null) {
      const { recipients, data: parsedRecipientData } = data;

      if (!recipients.length) {
        setTableRecipient(parsedRecipientData);
      }

      if (recipients.length === 1 && recipients[0].completeMatch) {
        setTableRecipient(recipients[0]);
      }
    }
  }, [isParsed, recipientSelected, data]);

  const completeMatchFound = data?.recipients?.length === 1 && data.recipients[0].completeMatch;

  return (
    <>
      {!data && !file && (
        <div className="h-100">
          <InvoiceFileUploader uploadFile={handleUploadFile} />
        </div>
      )}
      {file && (
        <>
          {!completeMatchFound && data?.recipients?.length > 0 && (
            <RecipientsTable
              setTableRecipient={setTableRecipient}
              fileRecipient={data?.data}
              parsedRecipients={data?.recipients}
              recipientSelected={recipientSelected}
            />
          )}
          {parsedError && (
            <div className="text-danger">
              <p>We were unable to retrieve recipient information from this inovoice.</p>
              <p>
                Please, contact{' '}
                <a className="text-danger" href="mailto:support@kedlin.com">
                  support@kedlin.com
                </a>
              </p>
            </div>
          )}
          <InvoiceFilePreview
            file={file}
            filename={filename}
            isParsed={isParsed}
            removeFile={handleRemoveFile}
            parseFile={handleParseFile}
          />
        </>
      )}
    </>
  );
};
export default InvoiceFiles;
