import { HIDE_COMPANY_INFO_BANNER, SHOW_COMPANY_INFO_BANNER } from 'store/actions/types';

const showBanner = localStorage.getItem('companyInfoBanner');
const initialState = showBanner === 'show' || showBanner === null;

const companyInfoBanner = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_COMPANY_INFO_BANNER:
      return initialState;
    case HIDE_COMPANY_INFO_BANNER:
      return false;
    default:
      return state;
  }
};

export default companyInfoBanner;
