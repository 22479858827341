import React from 'react';
import cardImage from 'assets/images/card.png';
import './PaymentStatus.scss';

const PaymentFailed = () => {
  return (
    <div className="payment-status d-flex justify-content-center flex-column align-items-center text-center">
      <div className="img-box">
        <img src={cardImage} height={200} />
      </div>
      <strong className="title" style={{ color: '#000' }}>
        Online bill pay is currently down
      </strong>
      <p>To make payment, please call our offices. Thank you for your patience.</p>
    </div>
  );
};

export default PaymentFailed;
