import UserService from '_services/userService';
import { SET_NOTIFICATIONS, CLEAR_NOTIFICATIONS } from './types';

const createNotificationUrl = (notification) => {
  switch (notification.type) {
    case 'organization':
      notification.url = '/settings/company';
      break;
    case 'payment':
      notification.url = '/settings/payment-details';
      break;
    case 'password':
      notification.url = '/settings/account';
      break;
    default:
      break;
  }
};

export const getUserNotifications = (force) => (dispatch) =>
  UserService.getUserNotifications(force)
    .then(({ results }) => {
      dispatch({
        type: SET_NOTIFICATIONS,
        payload: results.map((notification) => {
          createNotificationUrl(notification);
          return notification;
        }),
      });
    })
    .catch((error) => {
      console.error(error);
    });

export const clearUserNotifications = () => (dispatch) => {
  dispatch({
    type: CLEAR_NOTIFICATIONS,
  });
};
