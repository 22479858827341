import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import { showLoadingOverlay, hideLoadingOverlay } from 'store/actions/loadingOverlay';
import organisationService from '_services/organisationService';
import { ToastContainer, toast } from 'react-toastify';
import SwitcherButtons from 'components/SwitcherButtons';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import './CompanyFee.scss';

const PERCENTAGE = 'percentage';
const FIXED = 'fixed';
export const templateTypeButtons = [
  {
    value: PERCENTAGE,
    label: PERCENTAGE,
  },
  {
    value: FIXED,
    label: FIXED,
  },
];

const CompanyFeePage = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      fee: 0,
      feeType: PERCENTAGE,
    },
    validate: (values) => {
      const errors = {};

      if (!values.fee) {
        errors.fee = 'Company fee is required';
      }

      return errors;
    },
    onSubmit: (formValues) => {
      dispatch(showLoadingOverlay());
      return organisationService
        .updateOrganisationDetails(formValues)
        .then(() => {
          dispatch(hideLoadingOverlay());
          toast.success('Updated successfully!');
        })
        .catch((error) => {
          dispatch(hideLoadingOverlay());
          toast.error('Something went wrong!');
          console.log(error);
        });
    },
  });

  useEffect(() => {
    organisationService.getOrganisationDetails().then(({ fee, feeType }) => {
      formik.setFieldValue('fee', fee);
      formik.setFieldValue('feeType', feeType);
    });
  }, []);

  return (
    <div className="container-fluid pt-3 pb-3" id="content">
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <div className="fieldset-head">
            <h2 className="fieldset-title">
              {!!currentUser.organization && <span>{currentUser.organization} fee</span>}
              {!currentUser.organization && 'Company fee'}
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="row">
              <div className="col mb-2">
                <span className="small">TYPE FEE</span>
              </div>
            </div>
            <div className="mb-4">
              <SwitcherButtons
                buttons={templateTypeButtons}
                active={formik.values.feeType}
                onChange={(type) => formik.setFieldValue('feeType', type)}
              />
            </div>
            <span className="fieldset-subtitle mb-1">
              Enter the amount you want to receive from each transaction
            </span>
            <InputGroup className="mb-4">
              <InputGroup.Prepend>
                <InputGroup.Text
                  className={formik.touched.fee && !!formik.errors.fee ? 'is-invalid' : ''}>
                  <Form.Label className="m-0" htmlFor="name-input">
                    FEE, {formik.values.feeType === PERCENTAGE ? '%' : '$'}
                  </Form.Label>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <NumberFormat
                decimalScale={formik.values.feeType === PERCENTAGE ? 2 : 0}
                allowNegative={false}
                onValueChange={(values) => {
                  const { floatValue } = values;
                  formik.setFieldValue('fee', floatValue);
                }}
                customInput={FormControl}
                isInvalid={formik.touched.fee && !!formik.errors.fee}
                {...formik.getFieldProps('fee')}
              />
              <Form.Control.Feedback type="invalid" tooltip>
                {formik.errors.fee}
              </Form.Control.Feedback>
            </InputGroup>

            <button className="btn btn-info min-w" type="submit" disabled={formik.isSubmitting}>
              save changes
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CompanyFeePage;
