import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form as FormEl, InputGroup, Card } from 'react-bootstrap';
import TablePagination from 'components/TablePagination';
import Icon from 'components/IconComponent';
import organisationService from '_services/organisationService';
import ConfirmRemoveModal from './ConfirmRemoveModal';
import EditModal from './EditModal';
import './Locations.scss';

const Locations = () => {
  const [selectedLocation, selectLocation] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [locations, setLocations] = useState({ results: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setItemsPerPage] = useState(1);

  const formik = useFormik({
    initialValues: { title: '' },
    onSubmit: (location) => {
      organisationService
        .createLocation(location)
        .then(() => {
          toast.success('Location has been successfully added!');
          fetchLocations();
          formik.resetForm();
        })
        .catch((e) => {
          toast.error(e?.data[0]);
        });
    },
  });

  const handleRemoveClick = (location) => () => {
    selectLocation(location);
    setShowRemoveModal(true);
  };

  const handleEditClick = (location) => () => {
    selectLocation(location);
    setShowEditModal(true);
  };

  const handleEditLocation = (location) => {
    organisationService
      .updateLocation(location.id, { title: location.title })
      .then(() => {
        toast.success('Location has been successfully updated!');
        setShowEditModal(false);
        selectLocation(null);
      })
      .catch(() => toast.error('Something went wrong!'));
  };

  const handleRemoveLocation = () => {
    if (selectedLocation) {
      organisationService
        .deleteLocation(selectedLocation.id)
        .then(() => {
          toast.success('Location has been successfully removed!');
          setShowRemoveModal(false);
          selectLocation(null);
        })
        .catch(() => toast.error('Something went wrong!'));
    }
  };

  const handlePagerUpdate = ({ activePage, itemsPerPage }) => {
    setCurrentPage(activePage);
    setItemsPerPage(itemsPerPage);
  };

  const fetchLocations = useCallback(() => {
    organisationService.getLocations(currentPage, perPage).then((data) => setLocations(data));
  }, [currentPage, perPage]);

  useEffect(() => {
    // Refetch when selectedLocation changes to null
    if (selectedLocation === null) {
      fetchLocations();
    }
  }, [selectedLocation, fetchLocations]);

  return (
    <div className="content-wrap">
      <div className="row">
        <div className="col-lg-7 col-xl-6">
          <form onSubmit={formik.handleSubmit}>
            <div className="row mb-3">
              <div className="col" style={{ flexGrow: 3 }}>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FormEl.Label className="m-0" htmlFor="location">
                        LOCATION NAME
                      </FormEl.Label>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormEl.Control
                    id="location"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    aria-label="title"
                  />
                </InputGroup>
              </div>
              <div className="col d-flex justify-content-end">
                <Button
                  variant="info"
                  className="d-flex align-items-center mb-3"
                  disabled={!formik.values.title}
                  type="submit">
                  <Icon name="plusInCircle" className="mr-2 add-location-icon" /> ADD LOCATION
                </Button>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-12">
              <Card className="mb-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="70%">Location</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {locations.results.map((location) => (
                        <tr key={location.id}>
                          <td>{location.title}</td>
                          <td>
                            <span
                              role="button"
                              tabIndex="-1"
                              onKeyDown={() => {}}
                              className="text-info font-weight-500 cursor-pointer mr-4"
                              onClick={handleEditClick(location)}>
                              Edit
                            </span>
                            <span
                              role="button"
                              tabIndex="-1"
                              onKeyDown={() => {}}
                              className="text-danger font-weight-500 cursor-pointer"
                              onClick={handleRemoveClick(location)}>
                              Remove
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <TablePagination
                  pagerActivePage={currentPage}
                  pagerTotalItems={locations.count}
                  pagerUpdatedCallback={handlePagerUpdate}
                  totalText="Total locations"
                  pagerName="locationsPager"
                />
              </Card>
            </div>
          </div>
        </div>
      </div>
      <ConfirmRemoveModal
        show={showRemoveModal}
        onHide={() => setShowRemoveModal(false)}
        onSubmit={handleRemoveLocation}
      />
      {showEditModal && (
        <EditModal
          show={showEditModal}
          location={selectedLocation}
          onHide={() => setShowEditModal(false)}
          onSubmit={handleEditLocation}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Locations;
